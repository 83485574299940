import { images } from '@/asset';
import { config } from '@/config';
import { PATH } from '@/constants';
import customersList from '@/constants/customerHidePayment';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import bannerSlice, { bannerSelector } from '@/redux/slice/banner.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleShowModalSearch, showModalSearchSelector } from '@/redux/slice/home.slice';
import { ILayout } from '@/types/components';
import { Banner, Button, ButtonGroup, Collapsible, Link, Page } from '@shopify/polaris';
import { ChartVerticalFilledIcon, PlanFilledIcon, SettingsIcon, ShieldCheckMarkIcon, WrenchIcon } from '@shopify/polaris-icons';
import { memo, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';
import ModalSearch from '../ModalSearch';
import RegularText from '../RegularText';
import { LayoutStyled } from './styled';
import { TFunction } from 'i18next';

const btnGroup = [
  {
    content: 'Home',
    icon: ShieldCheckMarkIcon,
    path: PATH.DEFAULT,
  },
  {
    content: 'Visitor Analytics',
    icon: ChartVerticalFilledIcon,
    path: PATH.ANALYTICS,
  },
  {
    content: 'Blocker & Re-director ',
    icon: WrenchIcon,
    path: PATH.BLOCKLIST,
  },
  {
    content: 'Fraud orders analytics',
    icon: WrenchIcon,
    path: PATH.FRAUD_ORDER,
  },
  {
    content: 'Block checkout',
    icon: WrenchIcon,
    path: PATH.BLOCKED_CHECKOUT,
  },

  customersList.includes(config.shop)
    ? {
      content: 'Checkout rules',
      icon: WrenchIcon,
      path: PATH.CHECKOUT_RULES,
    }
    : undefined,

  {
    content: 'Settings',
    icon: SettingsIcon,
    path: PATH.SETTINGS,
  },
  // {
  //   content: 'Feedback',
  //   icon: SettingsIcon,
  //   path: PATH.FEEDBACK,
  // },
  {
    content: 'Pricing',
    icon: PlanFilledIcon,
    path: PATH.PRICING_PLAN,
  },
  {
    content: 'Development',
    icon: PlanFilledIcon,
    path: PATH.CONTACT_TO_USE_DEVELOPMENT_STORE,
    hide: true,
  },
];

enum CrsBannerApps {
  CHECKOUT_RULE = 'blockify-checkout-rules',
  CONSENTIK = 'eu-cookies-notification',
  TRUSTIFY = 'trustify',
}

const crsBannerApps = (t: TFunction) => ({
  [CrsBannerApps.CHECKOUT_RULE]: {
    logo: images.checkoutRulesLogo,
    descriptions: t('common:hide_payment_methods_blockify'),
    utmLink: 'https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=blockify&utm_medium=dashboardtop',
    impressAction: 'impress_checkouttop',
    clickAction: 'click_checkouttop',
  },
  [CrsBannerApps.CONSENTIK]: {
    logo: images.consentikLogo,
    descriptions: t('common:customer_data_consent_requirements'),
    utmLink: 'https://apps.shopify.com/eu-cookies-notification?utm_campaign=crs&utm_source=blockify&utm_medium=dashboardtop',
    impressAction: 'impress_cookiestop',
    clickAction: 'click_cookiestop',
  },
  [CrsBannerApps.TRUSTIFY]: {
    logo: images.trustifyLogo,
    descriptions: t('common:product_page_reviews_conversion'),
    utmLink: 'https://apps.shopify.com/trustify?utm_campaign=crs&utm_source=blockify&utm_medium=dashboardtop',
    impressAction: 'impress_trustifytop',
    clickAction: 'click_trustifytop',
  },
});

const Layout = ({ children, layoutProps, isVisibleHeader = true, ...props }: ILayout.IProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userPlanFree } = userPlans();
  const { t } = useTranslation('dashboard');
  const showModalSearch = useSelector(showModalSearchSelector);

  const dataSettings = useSelector(dataSettingsSelector);
  const isDevPlan = dataSettings && dataSettings?.settings?.isShowDevelopmentPlanPage;
  const bannerReferCheckoutRules = useSelector(bannerSelector).referCheckoutRulesBanner;

  const [crsBannerApp, setCrsBannerApp] = useState('');
  const bannerContent = crsBannerApp && crsBannerApps(t)[crsBannerApp as CrsBannerApps];
  const [crsBannerSessionId, setCrsBannerSessionId] = useState('');
  const crsBanner = apiCaller.useFetchCrsBannerToShowQuery({
    sessionId: crsBannerSessionId,
    currentBanner: crsBannerApp,
  });

  const [trackAction] = apiCaller.useTrackActionMutation();
  const handleClickCrsBannerApp = () => {
    if (bannerContent) {
      window.open(bannerContent?.utmLink, '_blank');
      trackAction(bannerContent?.clickAction);
    }
  };

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (userPlanFree && isDevPlan) {
      navigate(PATH.CONTACT_TO_USE_DEVELOPMENT_STORE);
    }
  }, [isDevPlan, userPlanFree, navigate]);

  useLayoutEffect(() => {
    if (location.pathname === PATH.CONTACT_TO_USE_DEVELOPMENT_STORE.pathname && !isDevPlan) {
      navigate(PATH.DEFAULT);
    }
  }, [location.pathname, navigate, isDevPlan]);
  useEffect(() => {
    if (dataSettings && dataSettings.settings) {
      if (userPlanFree && isDevPlan) {
        navigate(PATH.CONTACT_TO_USE_DEVELOPMENT_STORE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSettings)]);

  const [showBannerReferApp, setShowBannerReferApp] = useState(false);
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (bannerReferCheckoutRules && location.pathname === PATH.DEFAULT.pathname) {
        setShowBannerReferApp(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [bannerReferCheckoutRules, location.pathname]);

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.ctrlKey && event.shiftKey && event.key === 'F') {
      dispatch(handleShowModalSearch(true));
    }
  });

  useEffect(() => {
    const getBanner = () => {
      let sessionID = sessionStorage.getItem(`crs_banner:${config.shop}`);
      if (!sessionID && crsBanner?.data?.sessionId) {
        sessionID = crsBanner.data.sessionId;
        sessionStorage.setItem(`crs_banner:${config.shop}`, sessionID);
      }
      if (sessionID) setCrsBannerSessionId(sessionID);

      if (crsBanner?.data?.banner) setCrsBannerApp(crsBanner.data.banner as CrsBannerApps);
    };
    getBanner();
  }, [crsBanner?.data?.banner, crsBanner?.data?.sessionId]);

  return (
    <LayoutStyled isFullWidth={props.isFullWidth}>
      {isVisibleHeader && config.embedded !== '1' ? (
        <div className="nav-bar">
          <ButtonGroup fullWidth gap="extraTight">
            {btnGroup
              .filter((item) => item !== undefined)
              .filter((item) => item?.hide !== true)
              .filter((item) => item?.path !== PATH.HIDE_PAYMENT)
              .map((item, index) => {
                return (
                  <Button
                    key={index}
                    icon={item?.icon}
                    variant={
                      location.pathname === item?.path.pathname || (index === 0 && location.pathname === '/')
                        ? 'primary'
                        : undefined
                    }
                    onClick={() => {
                      navigate({
                        ...item?.path,
                      });
                    }}
                  >
                    {item?.content}
                  </Button>
                );
              })}
          </ButtonGroup>
        </div>
      ) : null}

      {bannerContent ? (
        <Collapsible
          id="banner-check-refer-app"
          open={showBannerReferApp}
          transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
        >
          <div className="banner-refer-app">
            <Banner
              onDismiss={() => {
                dispatch(
                  bannerSlice.actions.handleVisibleBanner({
                    key: 'referCheckoutRulesBanner',
                    value: false,
                  }),
                );
                setShowBannerReferApp(false);
              }}
              tone="info"
              title=""
              hideIcon
            >
              <div className="banner-wrapper-content" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="d-flex banner-refer-app-content">
                  <img className="banner-refer-app-logo" src={bannerContent.logo} alt="banner-refer-app"></img>
                  <RegularText>{bannerContent.descriptions}</RegularText>
                </div>
                <div className="btn-set-up" style={{ marginRight: '0.8em', marginLeft: '0.5em', minWidth: '10%' }}>
                  <Button target="_blank" onClick={handleClickCrsBannerApp}>
                    {t('setup_now')}
                  </Button>
                </div>
              </div>
            </Banner>
          </div>
        </Collapsible>
      ) : null}

      <Page {...layoutProps}>
        <div>{children}</div>
        <RegularText>
          <div className="layout-footer">
            {t('common:need_help_view')}&nbsp;
            <Link removeUnderline external url="https://blockifyapp.com/knowledge-base/blockify-fraud-country-blocker/">
              {t('common:documentation_guide')}
            </Link>
          </div>
        </RegularText>
      </Page>

      {showModalSearch ? <ModalSearch /> : null}
    </LayoutStyled>
  );
};
export default memo(Layout);

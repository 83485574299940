import options, { perPageOptions } from '@/constants/options';
import blockListSlice, { blackListTableSelector } from '@/redux/slice/blockList.slice';
import { ChoiceList, ChoiceListProps } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const BlackListFilter = () => {
  const { t } = useTranslation(['common', 'block_redirect']);
  const dispatch = useDispatch();
  const blackListTable = useSelector(blackListTableSelector) || {};
  const filters = [
    {
      key: 'type',
      label: t('common:type'),
      filter: (
        <ChoiceList
          title={t('common:type')}
          titleHidden
          choices={[{ label: t('common:all'), value: '' }, ...options.blockTypeOptions(t)]}
          selected={[blackListTable.type || '']}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                type: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: t('common:criteria'),
      filter: (
        <ChoiceList
          title={t('common:criteria')}
          titleHidden
          choices={[{ label: t('common:all'), value: '' }, ...(options.criteriaFilters(t) as ChoiceListProps['choices'])]}
          selected={[blackListTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                criteria: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: t('common:per_page'),
      filter: (
        <ChoiceList
          title={t('common:per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[blackListTable.perPage || '10']}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            type: '',
            page: 1,
          }),
        ),
      key: 'type',
      label: `${t('common:type')}: ${
        options.blockTypeOptions(t).find((item) => item.value === blackListTable.type)?.label || t('common:all')
      }`,
      hidden: !blackListTable.type,
    },
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `${t('common:criteria')}: ${
        options.criteriaFilters(t).find((option) => option.value === blackListTable.criteria)?.label || t('common:all')
      }`,
      hidden: !blackListTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: t('common:record_per_page', { perPage: blackListTable.perPage }),
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};

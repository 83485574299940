import BannerLimitRules from '@/components/BannerLimitRules';
import BoldText from '@/components/BoldText';
import HelpCenter from '@/components/HelpCenter';
import CustomLayout from '@/components/layout';
import { BREAKPOINT, OptionSettings } from '@/constants/enum';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleChangeBotList, selectedSelector } from '@/redux/slice/settings.slice';
import { Button, Icon, InlineGrid, OptionList } from '@shopify/polaris';
import { ComposeIcon, DisabledIcon, SettingsIcon, ShieldCheckMarkIcon, ViewIcon } from '@shopify/polaris-icons';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BlockerAndRedirector from './components/BlockerAndRedirector';
import ContentProtection from './components/contentProtection';
import CustomTemplate from './components/customTemplate';
import GeneralSettings from './components/GeneralSettings';
import { SettingsStyled } from './styled';
import { useTranslation } from 'react-i18next';

const configItems = [
  {
    value: OptionSettings.GeneralSettings,
    content: <GeneralSettings />,
  },
  {
    value: OptionSettings.BotKiller,
    content: <BlockerAndRedirector />,
  },
  {
    value: OptionSettings.ContentProtection,
    content: <ContentProtection />,
  },
  {
    value: OptionSettings.BlockingTemplate,
    content: <CustomTemplate />,
  },
];

const SecondaryAction = (): JSX.Element => {
  const { t } = useTranslation(['settings']);
  const dataSettings = useSelector(dataSettingsSelector);
  const handleOpenPreview = () => {
    window.open(dataSettings?.settings.testBlockUrl);
  };
  return (
    <Button onClick={handleOpenPreview} icon={ViewIcon}>
      {t('settings:preview')}
    </Button>
  );
};

const Settings = (): JSX.Element => {
  const { t } = useTranslation(['settings', 'common']);
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const selected = useSelector(selectedSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const banner = useSelector(bannerSelector);
  const { data } = apiCaller.useGetBotListQuery();

  useEffect(() => {
    if (data?.listBots) {
      dispatch(handleChangeBotList(data?.listBots));
    }
  }, [data, dispatch]);

  const options = [
    {
      value: OptionSettings.GeneralSettings,
      label: (
        <div className="settings-title">
          <Icon source={SettingsIcon} tone="base" />
          <BoldText>{t('settings:general_settings')}</BoldText>
        </div>
      ),
    },
    {
      value: OptionSettings.BotKiller,
      label: (
        <div className="settings-title">
          <Icon source={DisabledIcon} tone="base" />
          <BoldText>{t('settings:bot_killer')}</BoldText>
        </div>
      ),
    },
    {
      value: OptionSettings.ContentProtection,
      label: (
        <div className="settings-title">
          <Icon source={ShieldCheckMarkIcon} tone="base" />
          <BoldText>{t('settings:content_protection')}</BoldText>
        </div>
      ),
    },
    {
      value: OptionSettings.BlockingTemplate,
      label: (
        <div className="settings-title">
          <Icon source={ComposeIcon} tone="base" />
          <BoldText>{t('settings:blocking_template')}</BoldText>
        </div>
      ),
    },
  ];

  return (
    <SettingsStyled>
      <CustomLayout
        layoutProps={{
          title: t('settings:settings'),
          secondaryActions: selected[0] === 'custom' ? <SecondaryAction /> : undefined,
          primaryAction: <HelpCenter />,
        }}
      >
        {banner.bannerLimitRuleSetting && userPlanFree && (
          <div className="mb-16">
            <BannerLimitRules
              onDismiss={() => {
                dispatch(
                  handleVisibleBanner({
                    key: 'bannerLimitRuleSetting',
                    value: false,
                  }),
                );
              }}
              mixpanelName="Settings_increase_limit"
            />
          </div>
        )}
        <InlineGrid columns={isMobile ? 1 : ['oneThird', 'twoThirds']} gap={'400'}>
          <OptionList
            title=""
            onChange={(value: Array<string>) => {
              dispatch(slice.settingsSlice.actions.handleSelected(value));
            }}
            options={options}
            selected={selected}
          />
          {configItems.find((item) => item.value === selected[0])?.content}
        </InlineGrid>
      </CustomLayout>
    </SettingsStyled>
  );
};

export default memo(Settings);

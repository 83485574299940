import { apiCaller } from '@/redux/query';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { Banner, Button, Collapsible } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import RegularText from '../RegularText';
import { useTranslation } from 'react-i18next';
interface Props {
  title: string;
  isVisible: boolean;
  children?: React.ReactNode;
  enum: string;
  variant?: 'plain' | 'primary' | 'secondary' | 'tertiary' | 'monochromePlain';
}

const BannerGrantPermission = ({ ...props }: Props) => {
  const { t } = useTranslation(['common']);
  const dataSettings = useSelector(dataSettingsSelector);
  const [updateScope, updateScopeStatus] = apiCaller.useLazyGetUrlUpdatePaymentScopeQuery();
  const handleUpdateScope = () => {
    if (dataSettings) {
      updateScope({
        scopeGroups: props.enum,
      }).then((res) => {
        if (res.data?.url) {
          window.open(res.data.url, '_blank');
        }
      });
    }
  };
  return (
    <div
      style={{
        marginBottom: props.isVisible ? 16 : 0,
      }}
    >
      <Collapsible open={props.isVisible} id="basic-collapsible" transition={{ duration: '500ms' }} expandOnPrint>
        <Banner title={props.title} tone="warning">
          <RegularText>{props.children}</RegularText>
          <div className="mt-8">
            <Button onClick={handleUpdateScope} loading={updateScopeStatus.isFetching} variant={props.variant}>
              {t('common:grant_permission')}
            </Button>
          </div>
        </Banner>
      </Collapsible>
    </div>
  );
};

export default memo(BannerGrantPermission);

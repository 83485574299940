import { BREAKPOINT } from '@/constants/enum';
import { Button, Modal } from '@shopify/polaris';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import RegularText from '../RegularText';
import { useTranslation } from 'react-i18next';
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onConfirm?: () => void;
  title?: string;
  sectionText?: string;
  sectionContent?: ReactNode;
  primaryBtn?: ReactNode;
  secondaryBtn?: ReactNode;
}

const ModalConfirm = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  sectionText,
  title,
  sectionContent,
  primaryBtn,
  secondaryBtn,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { t } = useTranslation(['common']);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title || t('common:do_you_want_to_delete')}
      footer={
        <div
          style={{
            width: isMobile ? 'calc(100vw - 3rem)' : '588px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {secondaryBtn ? secondaryBtn : <Button onClick={onClose}>{t('common:cancel')}</Button>}
          <div className="ml-8">
            {primaryBtn ? (
              primaryBtn
            ) : (
              <Button tone="critical" variant="primary" onClick={onConfirm} loading={isLoading}>
                {t('common:delete')}
              </Button>
            )}
          </div>
        </div>
      }
    >
      <Modal.Section>
        <RegularText>{sectionText ?? t('common:once_you_delete_you_will_not_be_able_to_restore_it')}</RegularText>
        {sectionContent ? <div>{sectionContent}</div> : null}
      </Modal.Section>
    </Modal>
  );
};

export default ModalConfirm;

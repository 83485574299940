import AutoCompleteWithPaginate from '@/components/AutoCompletePaginate';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import useCountry from '@/hooks/useCountry';
import UserPlans from '@/hooks/userPlans';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import { apiCaller } from '@/redux/query';
import blockListSlice, { inputIspSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { IOptions } from '@/types/components';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ISP = () => {
  const { t } = useTranslation(['common']);
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const [getListIsp, { isFetching }] = apiCaller.useLazyGetListISPQuery();
  const { userPlanFree, userPlanPremium } = UserPlans();
  const blockRuleSelected = useSelector(settingSelector);
  const inputSearch = useSelector(inputIspSelector);
  const [listIsp, setListIsp] = useState<Array<{ label: string; value: string }>>();

  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  const handleChangeValueCountry = useCallback(
    (value: string[]) => {
      dispatch(
        blockListSlice.actions.handleSetting({
          ...blockRuleSelected,
          country: value,
          ispCode: [],
          ispName: [],
        }),
      );
      getListIsp({
        countryCode: value[0],
        search: '',
      }).then((res) => {
        const options = res.data?.listIsp.map((item) => ({
          label: item.asName,
          value: item.asCode,
        }));
        setListIsp(options);
      });
      dispatch(blockListSlice.actions.clearInputIsp());
    },
    [blockRuleSelected, dispatch, getListIsp],
  );

  useEffect(() => {
    if (blockRuleSelected.country && blockRuleSelected.country.length) {
      getListIsp({
        countryCode: blockRuleSelected.country[0],
        search: inputSearch,
      }).then((res) => {
        const options = res.data?.listIsp.map((item) => ({
          label: item.asName,
          value: item.asCode,
        }));
        setListIsp(options);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleSearch = useCallback(
    _debounce((value: string) => {
      dispatch(blockListSlice.actions.handleInputIsp(value));
    }, 500),
    [],
  );
  return (
    <div>
      <AutoCompleteWithTag
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label={t('common:select_country')}
        selectedOptions={blockRuleSelected.country || []}
        placeholder={`-${t('common:country')}-`}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChangeValueCountry(value);
        }}
        allowMultiple={false}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          }
        }}
        disable={userPlanFree || userPlanPremium}
      />

      <AutoCompleteWithPaginate
        tag={
          blockRuleSelected.ispName && blockRuleSelected.ispCode
            ? blockRuleSelected.ispCode.map((item, index) => {
                return {
                  value: item,
                  label: blockRuleSelected.ispName ? blockRuleSelected.ispName[index] : '',
                };
              })
            : []
        }
        handleSearch={debounceHandleSearch}
        helpText={!blockRuleSelected.id ? t('common:select_multiple_isp') : undefined}
        placeholder={t('common:search')}
        disable={isFetching || userPlanFree || userPlanPremium}
        options={listIsp || []}
        label={t('common:select_isp')}
        selectedOptions={blockRuleSelected.ispCode || []}
        setSelectedOptions={(selected: IOptions[]) => {
          if (selected.length === 0) {
            useError.handleErrorRule(t('common:isp_must_be_added'));
          } else if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              ispCode: selected.map((item) => item.value),
              ispName: selected.map((item) => item.label),
            }),
          );
        }}
        allowMultiple={!blockRuleSelected.id}
        requiredIndicator
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.ispCode?.length === 0) {
            useError.handleErrorRule(t('common:isp_must_be_added'));
          }
        }}
      />
    </div>
  );
};

export default memo(ISP);

import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import ScrollInToView from '@/components/ScrollInToView';
import { Enum } from '@/constants';
import { ActionType, IDScrollIntoView, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, disablePlan, handleToastMutation, validateUrl, sendMessageCrisp } from '@/helpers';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  BlockStack,
  Text,
  Button,
  Card,
  ChoiceList,
  Icon,
  Link,
  RangeSlider,
  TextField,
  Tooltip,
  DataTable,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@/components/Switch';
import { botListSelector } from '@/redux/slice/settings.slice';
import { IResponseApi } from '@/types/api/response.api';
import SettingSwitch from '@/components/SettingSwitch';
import { useTranslation } from 'react-i18next';
import { config } from '@/config';

enum CrispMessageType {
  REQUEST_BLOCK_EXTENSION = 'RequestBlockExtension',
  REQUEST_ALLOW_BOT = 'RequestAllowBot',
}

const BlockerAndRedirector = () => {
  const { t } = useTranslation(['settings', 'common']);
  const scope = useScope();
  const dispatch = useDispatch();

  const dataSettings = useSelector(dataSettingsSelector);
  const isLoading = useSelector(loadingSelector);
  const botList = useSelector(botListSelector);

  const { shopifyPlanPlus, userPlanPremium } = userPlans();
  const [activeVpn, activeVpnStatus] = apiCaller.useActiveProxyVpnMutation();
  const [activeAutoBlock, autoBlockStatus] = apiCaller.useActiveAutoBlockMutation();
  const [activeTorBlock, torBlockStatus] = apiCaller.useActiveTorBlockMutation();
  const [botListUpdate] = apiCaller.useUpdateBotListMutation();
  const [activeSpyBlock, spyBlockStatus] = apiCaller.useActiveSpyBlockMutation();
  const [activeUnknownBotsBlock, unknownBotsBlockStatus] = apiCaller.useActiveUnknownBotsBlockMutation();

  const [buttonClick, setButtonClick] = useState('');
  const [allowPrivateRelayStatus, setAllowPrivateRelayStatus] = useState(dataSettings?.settings?.allowVpnFromPrivateRelay);
  const [rangeValue, setRangeValue] = useState(dataSettings?.settings.vpnRiskCore);
  const [selectedMode, setSelectedMode] = useState<string[]>([dataSettings?.settings?.typeRestrictionVpnProxy || '']);
  const [textFieldValue, setTextFieldValue] = useState(dataSettings?.settings?.linkRedirectVpnProxy || '');
  const [showRenderChildren, setShowRenderChildren] = useState(selectedMode.toString() === '2');

  const handleRangeSliderChange = useCallback((value: number) => setRangeValue(value), []);

  const handleActiveAutoBlock = useCallback(() => {
    const autoBlock: IParamsApi.IContentProtection = {
      autoBlock: !dataSettings?.settings.autoBlock,
    };
    activeAutoBlock(autoBlock).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('2');
  }, [activeAutoBlock, dataSettings?.settings.autoBlock, dispatch, t]);

  const handleActiveTorBlock = useCallback(() => {
    const torBlock: IParamsApi.IContentProtection = {
      torBlock: !dataSettings?.settings.torBlock,
    };
    activeTorBlock(torBlock).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('3');
  }, [activeTorBlock, dataSettings?.settings.torBlock, dispatch, t]);

  const handleActiveSpyBlock = useCallback(() => {
    const spyBlock: IParamsApi.IContentProtection = {
      disableSpyExtensions: !dataSettings?.settings.disableSpyExtensions,
    };
    activeSpyBlock(spyBlock).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('4');
  }, [activeSpyBlock, dataSettings?.settings.disableSpyExtensions, dispatch, t]);

  const handleChoiceListChange = useCallback((value: string[]) => {
    setSelectedMode(value);
    setShowRenderChildren(value.includes('2'));
  }, []);

  const handleTextFieldChange = useCallback((value: string) => setTextFieldValue(value), []);

  const handleDisableBtn = useCallback((): boolean => {
    if (disablePlan([UserPlan.FREE]) || scope.isViewOnly) return true;
    const caseUrl =
      (textFieldValue === dataSettings?.settings?.linkRedirectVpnProxy && rangeValue === dataSettings.settings.vpnRiskCore) ||
      !validateUrl(textFieldValue);
    const caseBlock = rangeValue === dataSettings?.settings.vpnRiskCore;
    const caseAllowPrivateStatus = allowPrivateRelayStatus === dataSettings?.settings.allowVpnFromPrivateRelay;
    return caseAllowPrivateStatus && selectedMode[0] === dataSettings?.settings?.typeRestrictionVpnProxy
      ? selectedMode[0] === ActionType.Block
        ? caseBlock
        : caseUrl
      : false;
  }, [
    allowPrivateRelayStatus,
    dataSettings?.settings.allowVpnFromPrivateRelay,
    dataSettings?.settings?.linkRedirectVpnProxy,
    dataSettings?.settings?.typeRestrictionVpnProxy,
    dataSettings?.settings.vpnRiskCore,
    rangeValue,
    scope.isViewOnly,
    selectedMode,
    textFieldValue,
  ]);

  const handleActiveVpn = useCallback(() => {
    setButtonClick('4');
    activeVpn({
      status: !dataSettings?.settings.activeVpnProxy,
      type: selectedMode.toString(),
      url: textFieldValue,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
  }, [activeVpn, dataSettings?.settings.activeVpnProxy, dispatch, selectedMode, textFieldValue, t]);

  const handleSaveVpn = useCallback(() => {
    setButtonClick('1');
    activeVpn({
      status: dataSettings?.settings.activeVpnProxy || true,
      type: selectedMode[0],
      url: selectedMode[0] === ActionType.Redirect ? textFieldValue : undefined,
      riskCore: rangeValue,
      allowPrivateRelay: allowPrivateRelayStatus,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
  }, [
    activeVpn,
    dataSettings?.settings.activeVpnProxy,
    dispatch,
    selectedMode,
    textFieldValue,
    rangeValue,
    allowPrivateRelayStatus,
    t,
  ]);

  const toggleRule = useCallback((status: boolean) => {
    setAllowPrivateRelayStatus(status);
  }, []);

  const handleICloudRelay = () => {
    window.open('https://docs.ipblocker.io/faq/general-faq');
  };

  const renderChildren = () => (
    <div style={{ marginTop: '8px' }}>
      <TextField
        label=""
        labelHidden
        onChange={handleTextFieldChange}
        value={textFieldValue}
        autoComplete="off"
        prefix={textFieldValue ? null : 'https://'}
      />
    </div>
  );

  const handleToggle = async (bot: IResponseApi.BotItem) => {
    const res = await botListUpdate({
      ids: [bot?.id],
      status: !bot?.isAllow,
    });

    const condition = checkShowErrorInline(res);
    if (!condition.status) {
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
    }
  };

  const rows = botList.map((bot, index) => [
    bot.botName,
    <a key={bot.id} href={bot.url} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
      {bot.url}
    </a>,
    <Switch key={index} onSwitch={(checked) => handleToggle(bot)} isActive={!!bot.isAllow} />,
  ]);

  const sendRequestMessage = (type: CrispMessageType) => {
    const messages: Record<CrispMessageType, string> = {
      [CrispMessageType.REQUEST_BLOCK_EXTENSION]: `Hi team, I'd like to request a block for a new extension that is affecting our store.`,
      [CrispMessageType.REQUEST_ALLOW_BOT]: `Hi team, I'd like to request approval for a new bot to be allowed on our store.`,
    };

    const message = messages[type];

    if (!message) {
      console.log('Invalid message type');
      return;
    }

    sendMessageCrisp(message, [type]);
  };

  const handleActiveUnknownBotBlock = useCallback(() => {
    const unknownBotsBlock: IParamsApi.IContentProtection = {
      blockUnknownBots: !dataSettings?.settings.blockUnknownBots,
    };
    activeUnknownBotsBlock(unknownBotsBlock).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('5');
  }, [activeUnknownBotsBlock, dataSettings?.settings.blockUnknownBots, dispatch, t]);

  return (
    <BlockStack gap="400">
      {/* Customize option turn on/off block spam for classic-fella1.myshopify.com */}
      {config.shop === 'classic-fella1.myshopify.com' && (
        <ScrollInToView idScrollToBlock={IDScrollIntoView.Unknown_bots_blocker}>
          <SettingSwitch
            trackAction="setting_unknown_bots_block"
            title="Spam bots blocker"
            settingToggleProps={{
              action: {
                onAction: handleActiveUnknownBotBlock,
                loading: (unknownBotsBlockStatus.isLoading || isLoading) && buttonClick === '5',
              },
              enabled: dataSettings?.settings.blockUnknownBots,
            }}
          >
            <RegularText>Turn on to automatically block spam bots that create fake signups.</RegularText>
          </SettingSwitch>
        </ScrollInToView>
      )}

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Proxy_and_vpn_blocker}>
        <SettingSwitch
          trackAction="setting_proxy_vpn_blocker"
          title={t('settings:proxy_vpn_blocker')}
          minActivePlan={shopifyPlanPlus ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.PREMIUM}
          settingToggleProps={{
            action: { onAction: handleActiveVpn, loading: (activeVpnStatus.isLoading || isLoading) && buttonClick === '4' },
            enabled: disablePlan([UserPlan.FREE]) ? false : dataSettings?.settings.activeVpnProxy,
          }}
        >
          <RegularText>
            {t('settings:turn_on_auto_block_visitors')}{' '}
            <Link external target="_blank" url="https://docs.ipblocker.io/getting-started/proxy-and-vpn-blocker">
              {t('settings:proxy_or_vpn')}
            </Link>
            {dataSettings?.settings.activeVpnProxy ? (
              <div>
                <div className="mt-16 mb-16">
                  <RangeSlider
                    output
                    label={
                      <>
                        <div className="d-flex setting-risk-score">
                          <RegularText>{t('settings:risk_score_starts_at')}</RegularText>
                          <div
                            className="pointer"
                            onClick={() =>
                              window.open('https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score', '_blank')
                            }
                          >
                            <Icon source={InfoIcon} tone="subdued" />
                          </div>
                        </div>
                      </>
                    }
                    helpText={t('settings:block_ip_addresses_vpn_proxy')}
                    value={rangeValue || 0}
                    suffix={
                      <p
                        style={{
                          minWidth: '24px',
                          textAlign: 'right',
                        }}
                      >
                        {rangeValue}
                      </p>
                    }
                    min={0}
                    max={100}
                    step={1}
                    onChange={handleRangeSliderChange}
                  />
                </div>
                <ChoiceList
                  title={t('common:mode')}
                  choices={[
                    { label: t('common:block'), value: '1' },
                    { label: t('common:redirect_to'), value: '2' },
                  ]}
                  selected={selectedMode}
                  onChange={handleChoiceListChange}
                />
                <div className="mt-8">
                  <BlockStack gap="300">
                    <RegularText>
                      {t('settings:allow')}{' '}
                      <Tooltip content={t('settings:icloud_private_relay_safari')}>
                        <Link onClick={handleICloudRelay}>{t('settings:icloud_private_relay')}</Link>
                      </Tooltip>
                    </RegularText>
                    <Switch
                      onSwitch={(checked) => toggleRule(checked)}
                      isActive={allowPrivateRelayStatus}
                      isLoading={activeVpnStatus.isLoading || scope.isViewOnly}
                    />
                  </BlockStack>
                </div>
                {showRenderChildren && renderChildren()}
                <div className="mt-8">
                  <Button
                    onClick={handleSaveVpn}
                    disabled={handleDisableBtn()}
                    variant="primary"
                    loading={(activeVpnStatus.isLoading || isLoading) && buttonClick === '1'}
                  >
                    {t('common:save')}
                  </Button>
                </div>
              </div>
            ) : null}
          </RegularText>
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Auto_block_visitors}>
        <SettingSwitch
          trackAction="settings-auto-block_visitor"
          title={t('settings:auto_block_visitors')}
          minActivePlan={shopifyPlanPlus ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.PREMIUM}
          settingToggleProps={{
            action: { onAction: handleActiveAutoBlock, loading: (autoBlockStatus.isLoading || isLoading) && buttonClick === '2' },
            enabled: disablePlan([UserPlan.FREE]) ? false : dataSettings?.settings.autoBlock,
          }}
        >
          {t('settings:auto_block_visitors_description')}
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Spy_blocker}>
        <SettingSwitch
          trackAction="setting_spy_block"
          title={t('settings:spy_extensions_blocker')}
          isBeta={true}
          settingToggleProps={{
            action: { onAction: handleActiveSpyBlock, loading: (spyBlockStatus.isLoading || isLoading) && buttonClick === '4' },
            enabled: dataSettings?.settings.disableSpyExtensions,
          }}
        >
          <RegularText>
            {t('settings:spy_extensions_blocker_description')}{' '}
            <Link onClick={() => sendRequestMessage(CrispMessageType.REQUEST_BLOCK_EXTENSION)}>
              {t('settings:request_to_block_new_extension')}
            </Link>
          </RegularText>
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Tor_blocker}>
        <SettingSwitch
          trackAction="setting_tor_block"
          title={t('settings:tor_blocker')}
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: { onAction: handleActiveTorBlock, loading: (torBlockStatus.isLoading || isLoading) && buttonClick === '3' },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings?.settings.torBlock,
          }}
        >
          <RegularText>
            {t('settings:turn_on_block_automatic_visitors')}{' '}
            <Link external url="https://docs.ipblocker.io/getting-started/tor-blocker">
              {t('settings:tor')}
            </Link>
            .
          </RegularText>
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Allowed_bot_list}>
        <Card>
          <BlockStack gap="300">
            <Text as="h3" variant="headingMd">
              {t('settings:allowed_bot_list')}
            </Text>
            <Text as="h6">{t('settings:trusted_bots_access')}</Text>

            <DataTable
              columnContentTypes={['text', 'text', 'text']}
              headings={[
                <BoldText key="bot_name">{t('settings:bot_name')}</BoldText>,
                <BoldText key="resource">{t('settings:resource')}</BoldText>,
                <BoldText key="action">{t('settings:action')}</BoldText>,
              ]}
              rows={rows}
            />

            <Text as="p" tone="subdued">
              {t('settings:trusted_bots_access_request')}{' '}
              <Link onClick={() => sendRequestMessage(CrispMessageType.REQUEST_ALLOW_BOT)}>
                {t('settings:request_allow_new_bot')}
              </Link>
            </Text>
          </BlockStack>
        </Card>
      </ScrollInToView>
    </BlockStack>
  );
};
export default memo(BlockerAndRedirector);

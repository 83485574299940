import { CriteriaType, IDScrollIntoView, SubKeyHelpCenter } from '@/constants/enum';
import IPAdress from '@/pages/block-list/components/BlockPage/Criteria/IpAddress';
import Location from '@/pages/block-list/components/BlockPage/Criteria/Location';
import Products from '@/pages/block-list/components/BlockPage/Criteria/Products';
import Agents from '@/pages/block-list/components/BlockPage/Criteria/Agents';
import Page from '@/pages/block-list/components/BlockPage/Criteria/Page';
import ISP from '@/pages/block-list/components/BlockPage/Criteria/ISP';
import ReferrerLink from '@/pages/block-list/components/BlockPage/Criteria/ReferrerLink';
import { settingSelector } from '@/redux/slice/blockList.slice';
import { useSelector } from 'react-redux';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
const useCondition = () => {
  const blockRuleSelected = useSelector(settingSelector);
  const { t } = useTranslation(['common', 'block_redirect']);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = userPlans();
  const criteria = [
    // Ip address
    {
      value: CriteriaType.IpAddress,
      label: capitalize(t('common:ip_address')),
      placeholder: 'E.g: 177.229.213.89,123.234.456.89',
      fieldName: 'IP address',
    },
    {
      value: CriteriaType.IpAddressStartWith,
      label: capitalize(t('common:ip_start_with')),
      placeholder: 'E.g: 177.229,192.0,2002:db8::',
      fieldName: 'IP address',
    },
    {
      value: CriteriaType.IpRanges,
      label: capitalize(t('common:ip_range')),
      placeholder: 'E.g: 177.229.213.89/1,2002:db8::8a3f:362:7897/128',
      fieldName: 'IP address',
      badge: userPlanFree
        ? t('common:paid_plan')
        : userPlanPremium && !shopifyPlanPlus
        ? t('common:enterprise_plan')
        : userPlanPremium && shopifyPlanPlus
        ? t('common:enterprise_plan_or_shopify_plus')
        : null,
      scrollToID: userPlanFree
        ? IDScrollIntoView.None
        : userPlanPremium && !shopifyPlanPlus
        ? IDScrollIntoView.Pricing_enterprise
        : userPlanPremium && shopifyPlanPlus
        ? IDScrollIntoView.Pricing_shopify_plus
        : IDScrollIntoView.None,
    },
    // Location
    {
      value: CriteriaType.Country,
      label: capitalize(t('common:country')),
      helpText: t('common:select_multiple_countries'),
      fieldName: 'country',
    },
    {
      value: CriteriaType.Province,
      label: capitalize(t('common:province')),
      helpText: t('common:select_multiple_countries'),
      fieldName: 'province',
      badge: userPlanFree ? t('common:paid_plan') : null,
    },
    // Products
    {
      value: CriteriaType.Product,
      label: capitalize(t('common:specific_products')),
      fieldName: 'product',
    },
    {
      value: CriteriaType.Collection,
      label: capitalize(t('common:specific_collection')),
      fieldName: 'collection',
    },

    // User agent
    {
      value: CriteriaType.UserAgent,
      label: capitalize(t('common:user_agent')),
      fieldName: 'user agent',
    },
    // User specifics page
    {
      value: CriteriaType.SpecificPage,
      label: capitalize(t('common:specific_pages')),
      fieldName: 'specific page',
    },
    // User ISP
    {
      value: CriteriaType.ISP,
      label: capitalize(t('common:isp')),
      fieldName: 'ISP',
    },
    // referrer URL
    {
      value: CriteriaType.ReferralLink,
      label: capitalize(t('common:referrer_url')),
      fieldName: 'referrer',
    },
  ];
  return {
    listCriteria: criteria,
    condition: [
      {
        subKeyHelpCenter: SubKeyHelpCenter.IpAddress,
        label: capitalize(t('common:ip_address')),
        groupCriteria: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
        component: (props: { label: string; error?: string; placeholder?: string }) => (
          <IPAdress error={props.error} label={props.label} placeholder={props.placeholder} />
        ),
        fieldsRequired: ['ipAddress'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Location,
        label: capitalize(t('common:location')),
        groupCriteria: [CriteriaType.Country, CriteriaType.Province],
        component: () => <Location />,
        fieldsRequired:
          blockRuleSelected.criteria === CriteriaType.Country || handleCountry.listStates.length === 0
            ? ['country']
            : ['country', 'state'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Product,
        label: capitalize(t('common:products')),
        groupCriteria: [CriteriaType.Product, CriteriaType.Collection],
        component: () => <Products />,
        fieldsRequired:
          blockRuleSelected.criteria === CriteriaType.Product ? ['productId', 'country'] : ['collectionId', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Agent,
        label: capitalize(t('common:user_agent')),
        groupCriteria: [CriteriaType.UserAgent],
        component: () => <Agents />,
        fieldsRequired: ['browserName', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Page,
        label: capitalize(t('common:specific_page')),
        groupCriteria: [CriteriaType.SpecificPage],
        component: () => <Page />,
        fieldsRequired: ['pageId', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Isp,
        label: capitalize(t('common:isp')),
        groupCriteria: [CriteriaType.ISP],
        component: () => <ISP />,
        fieldsRequired: ['ispCode', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Url,
        label: capitalize(t('common:referrer_url')),
        groupCriteria: [CriteriaType.ReferralLink],
        component: () => <ReferrerLink />,
        fieldsRequired: ['referUrl', 'country'],
      },
    ],
  };
};

export default useCondition;

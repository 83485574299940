import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import RegularText from '@/components/RegularText';
import { CriteriaType } from '@/constants/enum';
import { validateUrl } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import UserPlans from '@/hooks/userPlans';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { Link, TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ReferrerLink = () => {
  const { t } = useTranslation(['common']);
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const blockRuleSelected = useSelector(settingSelector);
  const { userPlanFree, userPlanPremium } = UserPlans();
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | string[]) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };

  return (
    <div>
      <AutoCompleteWithTag
        requiredIndicator
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label={t('common:select_country')}
        selectedOptions={blockRuleSelected.country || []}
        placeholder={`-${t('common:country')}-`}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('country')(value);
        }}
        allowMultiple={false}
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          }
        }}
        disable={userPlanFree || userPlanPremium}
      />
      <div className="mt-8" />
      <TextField
        autoComplete="off"
        requiredIndicator
        label={t('common:enter_referrer_url')}
        value={blockRuleSelected.referUrl}
        helpText={
          <RegularText>
            <Link url="https://docs.ipblocker.io/faq/what-is-a-referrer-url" target="_blank">
              {t('common:read_more')}
            </Link>{' '}
            {t('common:about_referrer_url')}
          </RegularText>
        }
        onChange={(value) => {
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('referUrl')(value);
        }}
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (!blockRuleSelected.referUrl) {
            useError.handleErrorRule(t('common:referrer_link_required'));
          } else if (!validateUrl(blockRuleSelected.referUrl)) {
            useError.handleErrorRule(t('common:referrer_link_invalid'));
          }
        }}
        disabled={userPlanFree || userPlanPremium}
      />
      <div className="mt-8" />

      <TextField
        autoComplete="off"
        label={t('common:ref_url_name_optional')}
        value={blockRuleSelected.shortReferUrl}
        onChange={handleChange('shortReferUrl')}
        disabled={userPlanFree || userPlanPremium}
      />
    </div>
  );
};

export default memo(ReferrerLink);

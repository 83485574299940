import { SubKeyHelpCenter } from '@/constants/enum';
import { TFunction } from 'i18next';

export enum Key {
  Analytics = 'analytics',
  FraudOrders = 'fraud-orders',
  BlockPage = 'block-and-redirect',
  BlockCheckout = 'block-checkout',
  Settings = 'settings',
}

export const config = (t: TFunction, key: Key, subKey?: SubKeyHelpCenter) =>
  [
    // Analytics
    {
      key: Key.Analytics,
      title: t('common:how_do_the_visitor_analytics_work'),
      content: t('common:visitor_analytics_overview'),
      url: 'https://docs.ipblocker.io/getting-started/visitor-analytics',
    },
    {
      key: Key.Analytics,
      title: t('common:how_do_we_calculate_the_ip_risk_score'),
      content: t('common:score_range_guide'),
      url: 'https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score',
    },
    // Block and redirect
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.IpAddress,
      title: t('common:how_to_add_a_new_block_ip_address_rule'),
      content: t('common:new_ip_block_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.IpAddress,
      title: t('common:faqs_about_block_ip_address_rule'),
      content: t('common:ip_block_faq'),
      url: 'https://docs.ipblocker.io/faq/general-faq',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Location,
      title: t('common:how_to_add_a_new_block_location_rule'),
      content: t('common:new_block_location_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Location,
      title: t('common:how_can_i_access_my_store_if_i_have_blocked_my_own_country'),
      content: t('common:tip_to_test_your_store_if_you_have_set_blocked_for_your_own_country'),
      url: 'https://docs.ipblocker.io/tips-and-tricks/how-can-i-access-my-store-if-i-have-blocked-my-own-country',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Product,
      title: t('common:how_to_add_a_new_block_products_rule'),
      content: t('common:new_block_products_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Agent,
      title: t('common:how_to_add_a_new_block_user_agent_rule'),
      content: t('common:new_block_user_agent_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Page,
      title: t('common:how_to_add_a_new_block_specific_page_rule'),
      content: t('common:new_block_specific_page_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block/block-specific-page',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Isp,
      title: t('common:how_to_add_a_new_block_isp_rule'),
      content: t('common:new_block_isp_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Url,
      title: t('common:what_is_a_referrer_url'),
      content: t('common:understanding_what_is_referrer_url_and_how_does_it_work'),
      url: 'https://docs.ipblocker.io/faq/what-is-a-referrer-url',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Url,
      title: t('common:how_to_add_a_new_block_referrer_url_rule'),
      content: t('common:new_block_referrer_url_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block/block-referrer-url',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Redirect,
      title: t('common:how_to_add_a_new_redirect_rule'),
      content: t('common:new_redirect_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-redirect',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.WhiteList,
      title: t('common:how_to_add_a_new_whitelist_rule'),
      content: t('common:new_whitelist_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-whitelist',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.WhiteListLocation,
      title: t('common:how_to_add_a_new_whitelist_rule'),
      content: t('common:new_whitelist_rule'),
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-whitelist',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.WhiteListLocation,
      title: t('common:how_to_allow_only_a_country_to_access_my_site'),
      content: t('common:tip_to_allow_only_a_country_to_access_my_site'),
      url: 'https://docs.ipblocker.io/tips-and-tricks/how-to-allow-only-a-country-to-access-my-site',
    },

    {
      key: Key.FraudOrders,
      title: t('common:how_does_fraud_order_analytics_work'),
      content: t('common:fraud_order_analytics_overview'),
      url: 'https://docs.ipblocker.io/getting-started/fraud-orders',
    },
    {
      key: Key.FraudOrders,
      title: t('common:what_is_auto_block_visitors_placing_fraud_orders'),
      content: t('common:auto_block_visitors_placing_fraud_orders'),
      url: 'https://docs.ipblocker.io/getting-started/fraud-orders/auto-block-visitors-placing-fraud-orders',
    },
    {
      key: Key.FraudOrders,
      title: t('common:what_is_auto_cancel_high_risk_order_feature'),
      content: t('common:auto_cancel_high_risk_order_feature'),
      url: 'https://docs.ipblocker.io/getting-started/fraud-orders/auto-cancel-high-risk-orders',
    },

    // Block Checkout
    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutGeneral,
      title: t('common:what_is_auto_block_creating_order_with_a_subtotal_of_0'),
      content: t('common:auto_block_creating_order_with_a_subtotal_of_0'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/auto-block-checkout-with-free-products',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutEmail,
      title: t('common:how_to_set_up_a_block_checkout_with_specific_email'),
      content: t('common:understanding_the_way_on_how_to_set_up_the_block_email_on_checkout'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutPhoneNumber,
      title: t('common:how_to_set_up_a_block_checkout_with_specific_phone_number'),
      content: t('common:understanding_the_way_on_how_to_set_up_the_block_phone_number_on_checkout'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutName,
      title: t('common:how_to_set_up_a_block_checkout_with_specific_customer_name'),
      content: t('common:understanding_the_way_on_how_to_set_up_the_block_customer_name_on_checkout'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutCountry,
      title: t('common:how_to_set_up_a_block_checkout_with_specific_country'),
      content: t('common:understanding_the_way_on_how_to_set_up_the_block_country_on_checkout'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutAddress,
      title: t('common:how_to_set_up_a_block_checkout_with_specific_address'),
      content: t('common:understanding_the_way_on_how_to_set_up_the_block_specific_address_on_checkout'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutZipcode,
      title: t('common:how_to_set_up_a_block_checkout_with_zip_code'),
      content: t('common:understanding_the_way_on_how_to_set_up_the_block_zip_code_on_checkout'),
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.Settings,
      title: t('common:how_to_enable_proxy_and_vpn_blocker'),
      content: t('common:enable_this_function_can_help_you_prevent_ips_uses_proxy_and_vpn'),
      url: 'https://docs.ipblocker.io/getting-started/proxy-and-vpn-blocker',
    },
    {
      key: Key.Settings,
      title: t('common:how_to_enable_tor_blocker'),
      content: t('common:prevent_tor_traffic'),
      url: 'https://docs.ipblocker.io/getting-started/tor-blocker',
    },
    {
      key: Key.Settings,
      title: t('common:how_to_enable_content_protection_feature'),
      content: t('common:block_content_select'),
      url: 'https://docs.ipblocker.io/getting-started/content-protection',
    },
    {
      key: Key.Settings,
      title: t('common:how_to_auto_block_visitors_when_ip_changes'),
      content: t('common:smart_device_block'),
      url: 'https://docs.ipblocker.io/getting-started/auto-block-visitors-when-ip-changes',
    },
  ].filter((item) => (item.subKey ? item.subKey === subKey && item.key === key : item.key === key));

import userPlans from '@/hooks/userPlans';
import { Icon, IconProps, Tooltip } from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
interface IProps {
  url?: string;
  content?: string;
  tone?: IconProps['tone'];
}

const TooltipUpdateHigherPlan = ({ url, content, tone = 'subdued' }: IProps) => {
  const { userPlanFree, shopifyPlanPlus } = userPlans();
  const { t } = useTranslation(['common']);

  const isFreeUserAndPlusShopifyPlan = userPlanFree && shopifyPlanPlus;

  const contentTooltip = content
    ? content
    : isFreeUserAndPlusShopifyPlan
    ? t('common:upgrade_to_shopify_plus_plan')
    : t('common:upgrade_to_premium_plan');

  return (
    <div
      className={url ? 'pointer' : ''}
      onClick={() => {
        if (url) window.open(url, '_blank');
      }}
    >
      <Tooltip content={contentTooltip}>
        <Icon source={InfoIcon} tone={tone} />
      </Tooltip>
    </div>
  );
};

export default TooltipUpdateHigherPlan;

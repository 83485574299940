import BoldText from '@/components/BoldText';
import ModalConfirm from '@/components/ModalConfirm';
import RegularText from '@/components/RegularText';
import Layout from '@/components/layout';
import { PATH } from '@/constants';
import { BREAKPOINT, ConditionHidePayment, CriteriaHidePayment, StatusExpressCheckout } from '@/constants/enum';
import options, { conditionToCriteriaMapping } from '@/constants/options';
import { capitalizeFirstLetter, checkShowErrorInline, formatDate, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import hidePaymentSlice, {
  initialState,
  paymentDetailSelector,
  selectedConditionSelector,
} from '@/redux/slice/hidePayment.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { Badge, BlockStack, Box, Button, Card, InlineGrid, List, Select, Text } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import useDisable from '../../hooks/useDisable';
import HideExpressCheckout from '../HideExpressCheckout';
import Address from './Criteria/Address';
import Contact from './Criteria/Contact';
import Customer from './Criteria/Customer';
import DayOfTheWeek from './Criteria/DayOfTheWeek';
import { HidePaymentDetailStyled } from './styled';
import useCountry from '@/hooks/useCountry';
import { useTranslation } from 'react-i18next';
const HidePaymentDetail = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const handleCountry = useCountry();

  const selectedCondition = useSelector(selectedConditionSelector);
  const paymentDetail = useSelector(paymentDetailSelector);
  const {
    id,
    criteria,
    startDate,
    endDate,
    country,
    value,
    paymentMethodsApply,
    enabledExpressCheckout,
    condition,
    expressMethodsApply,
    isActive,
    createdAt,
    lastUpdatedAt,
  } = paymentDetail ?? {};

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  useEffect(() => {
    if (!criteria) {
      navigate(PATH.CHECKOUT_RULES);
    }
  }, [criteria, navigate]);

  const [mutationHidePaymentRules, mutationHidePaymentRulesStatus] = apiCaller.useHidePaymentRulesMutation();
  const [removeItem, removeItemStatus] = apiCaller.useDeleteHidePaymentRulesMutation();

  const handleChangeStatus = (key: keyof typeof paymentDetail) => (value: string | boolean) => {
    dispatch(
      hidePaymentSlice.actions.handleSettingHidePayment({
        ...paymentDetail,
        [key]: value,
      }),
    );
  };

  const handleDelete = useCallback(() => {
    if (id) {
      removeItem({ ids: JSON.stringify([id]) }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
          navigate(PATH.CHECKOUT_RULES);
        }
      });
    }
  }, [dispatch, id, navigate, removeItem, t]);

  const handleChangeSelectCondition = useCallback(
    (value: ConditionHidePayment) => {
      dispatch(slice.hidePaymentSlice.actions.handleChangeSelectedCondition(value));
      dispatch(
        slice.hidePaymentSlice.actions.clearSettingHidePayment({
          ...initialState.itemSelected,
          criteria: conditionToCriteriaMapping[value],
        }),
      );
      dispatch(
        slice.hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );
    },
    [dispatch],
  );

  const optionsCondition = [
    { label: 'Day of the week', value: ConditionHidePayment.DayOfTheWeek },
    { label: 'Customer', value: ConditionHidePayment.Customer },
    { label: 'Contact', value: ConditionHidePayment.Contact },
    { label: 'Address', value: ConditionHidePayment.Address },
  ];

  const renderOption = [
    {
      id: ConditionHidePayment.DayOfTheWeek,
      component: <DayOfTheWeek />,
    },
    {
      id: ConditionHidePayment.Customer,
      component: <Customer />,
    },
    {
      id: ConditionHidePayment.Contact,
      component: <Contact />,
    },
    {
      id: ConditionHidePayment.Address,
      component: <Address />,
    },
  ];

  const handleResponse = useCallback(
    (res: any) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        dispatch(hidePaymentSlice.actions.handleVerifyErrorHidePayment({ status: true, data: res?.data }));
      } else {
        dispatch(slice.toastSlice.actions.handleToast(handleToastMutation(res, t)));
        dispatch(
          hidePaymentSlice.actions.handleVerifyErrorHidePayment({
            status: false,
            data: undefined,
          }),
        );
        navigate(PATH.CHECKOUT_RULES);
      }
    },
    [dispatch, navigate, t],
  );

  const handleSave = useCallback(() => {
    try {
      let valueMatchCriteria;

      switch (criteria) {
        case CriteriaHidePayment.DayOfWeek:
          valueMatchCriteria = `${startDate};${endDate}`;
          break;
        case CriteriaHidePayment.NewCustomer:
        case CriteriaHidePayment.RepeatCustomer:
          valueMatchCriteria = undefined;
          break;
        default:
          valueMatchCriteria = value;
      }

      const commonPayload = {
        id: id,
        criteria: criteria,
        value: valueMatchCriteria,
        countryCode: criteria === CriteriaHidePayment.Country ? country.join(',') : undefined,
        paymentMethodsApply: paymentMethodsApply,
        enabledExpressCheckout: enabledExpressCheckout,
        condition: enabledExpressCheckout === StatusExpressCheckout.enable ? condition : undefined,
        expressMethodsApply: enabledExpressCheckout === StatusExpressCheckout.enable ? expressMethodsApply : undefined,
        isActive: isActive,
      };

      mutationHidePaymentRules(commonPayload).then(handleResponse);
    } catch (error) {
      console.log('🚀 - error:', error);
    }
  }, [
    criteria,
    id,
    country,
    paymentMethodsApply,
    enabledExpressCheckout,
    condition,
    expressMethodsApply,
    isActive,
    mutationHidePaymentRules,
    handleResponse,
    startDate,
    endDate,
    value,
  ]);

  return (
    <HidePaymentDetailStyled>
      <Layout
        layoutProps={{
          title: 'Payment Detail',
          backAction: {
            onAction: () => navigate(PATH.CHECKOUT_RULES),
          },
        }}
      >
        <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']} gap={'400'}>
          <BlockStack gap="400">
            <Card padding={'400'}>
              <div className="block-page-status">
                <BoldText>
                  {t('common:rule_status')}{' '}
                  <Badge tone={paymentDetail.isActive ? 'success' : undefined}>
                    {!paymentDetail.isActive ? 'Disabled' : 'Enabled'}
                  </Badge>
                </BoldText>
                <Button
                  onClick={() => handleChangeStatus('isActive')(!paymentDetail.isActive)}
                  variant={paymentDetail.isActive ? undefined : 'primary'}
                >
                  {paymentDetail.isActive ? 'Disable' : 'Enable'}
                </Button>
              </div>
              <RegularText>{t('common:enable_rules_activation')}</RegularText>
            </Card>

            <Card padding="400">
              <Select
                label="Select condition"
                options={optionsCondition}
                onChange={handleChangeSelectCondition}
                value={selectedCondition}
                disabled={!!id}
              />

              {renderOption.find((option) => option.id === selectedCondition)?.component}
            </Card>

            <Card padding="400">
              <HideExpressCheckout />
            </Card>
          </BlockStack>

          <div className="rule-summary-container">
            <Card padding={'0'}>
              <div className="block-page-rule-summary">
                <BoldText>Rule summary</BoldText>
              </div>
              <div className="block-page-rule-summary-content">
                <List type="bullet">
                  <List.Item>Status: {isActive ? 'Enable' : 'Disable'}</List.Item>
                  <List.Item>
                    Condition: <b>{options.configRulesPayment.find((item) => item.id === criteria)?.title}</b>
                  </List.Item>
                  <Box paddingInlineStart="400">
                    {criteria === CriteriaHidePayment.DayOfWeek && (
                      <List.Item>
                        {t('common:from')}: <b>{capitalizeFirstLetter(startDate)}</b> - {t('common:to')}:{' '}
                        <b>{capitalizeFirstLetter(endDate)}</b>
                      </List.Item>
                    )}

                    {[
                      CriteriaHidePayment.DayOfWeek,
                      CriteriaHidePayment.NewCustomer,
                      CriteriaHidePayment.RepeatCustomer,
                    ].includes(criteria as CriteriaHidePayment) ? null : (
                      <List.Item>
                        Value:{' '}
                        {country.length > 0 ? (
                          <b>{country?.map((item) => handleCountry.renderCountry(item)).join(', ')}</b>
                        ) : value ? (
                          <b>{capitalizeFirstLetter(value)}</b>
                        ) : (
                          <Text as="span" variant="bodyMd" tone="subdued">
                            None
                          </Text>
                        )}
                      </List.Item>
                    )}

                    <List.Item>
                      Payment method:{' '}
                      {paymentMethodsApply ? (
                        <b>{capitalizeFirstLetter(paymentMethodsApply)}</b>
                      ) : (
                        <Text as="span" variant="bodyMd" tone="subdued">
                          None
                        </Text>
                      )}
                    </List.Item>
                  </Box>
                  <List.Item>
                    Hide express checkout: <b>{capitalizeFirstLetter(enabledExpressCheckout)}</b>
                  </List.Item>
                  <Box paddingInlineStart="400">
                    <List.Item>
                      Method:{' '}
                      {expressMethodsApply ? (
                        <b>{capitalizeFirstLetter(expressMethodsApply)}</b>
                      ) : (
                        <Text as="span" variant="bodyMd" tone="subdued">
                          None
                        </Text>
                      )}
                    </List.Item>
                  </Box>

                  {createdAt && <List.Item>Created at: {formatDate(createdAt / 1000, 'D MMM YYYY, h:mm:ss a')}</List.Item>}
                  {lastUpdatedAt && (
                    <List.Item>Last updated : {formatDate(lastUpdatedAt / 1000, 'D MMM YYYY, h:mm:ss a')}</List.Item>
                  )}
                </List>
              </div>
            </Card>
          </div>
        </InlineGrid>

        <div className="block-page-group-button">
          {paymentDetail.id ? (
            <Button
              onClick={() => {
                setIsOpenModalDelete(true);
              }}
              tone="critical"
              variant="primary"
            >
              Delete
            </Button>
          ) : null}
          <Button
            disabled={useDisable()}
            loading={mutationHidePaymentRulesStatus.isLoading}
            onClick={handleSave}
            variant="primary"
          >
            Save & Exit
          </Button>
        </div>
      </Layout>

      <ModalConfirm
        onClose={() => setIsOpenModalDelete(false)}
        isLoading={removeItemStatus.isLoading}
        isOpen={isOpenModalDelete}
        title={t('common:delete_rule')}
        onConfirm={handleDelete}
        sectionText={t('common:delete_rule_no_revert')}
      />
    </HidePaymentDetailStyled>
  );
};

export default HidePaymentDetail;

import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import { Modal, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  isOpen: boolean;
  handleIsOpen: (status: boolean) => void;
}

const ProcessOldOrders = ({ ...props }: Props) => {
  const { t } = useTranslation(['common', 'fraud_orders']);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    oldOrderRange: '30',
    validateMessage: '',
  });
  const [fetchOldOrder, oldOrderStatus] = apiCaller.useGetOldOrdersMutation();
  const clearState = () => {
    props.handleIsOpen(false);
    setState({
      oldOrderRange: '30',
      validateMessage: '',
    });
  };

  const handleChangeOldOrderRange = async (value: string) => {
    let result = value.toString();
    if (Number(value) > 60) {
      result = '60';
    }
    setState({ ...state, oldOrderRange: result, validateMessage: '' });
  };

  const handleBlurOldOrderRange = () => {
    if (!state.oldOrderRange) {
      setState({ ...state, validateMessage: t('fraud_orders:date_range_is_required') });
    }

    if (Number(state.oldOrderRange) <= 0) {
      setState({ ...state, validateMessage: t('fraud_orders:date_range_positive') });
    }
  };

  const handleGetOldOrder = () => {
    fetchOldOrder({ rangeDay: Number(state.oldOrderRange) }).then((res) => {
      const checkError = checkShowErrorInline(res, t);
      if (checkError.status) {
        setState({ ...state, validateMessage: checkError.msg });
      } else {
        clearState();
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={clearState}
      title={t('fraud_orders:analyze_old_orders')}
      primaryAction={{
        disabled: state.oldOrderRange === '' || !!state.validateMessage,
        content: t('common:ok'),
        onAction: handleGetOldOrder,
        loading: oldOrderStatus.isLoading,
      }}
      secondaryActions={[
        {
          content: t('common:cancel'),
          onAction: clearState,
        },
      ]}
    >
      <Modal.Section>
        <div>
          {t('fraud_orders:the_range_for_getting_old_orders_max_60_days')}
          <div className="mt-8">
            <TextField
              label=""
              autoComplete="off"
              type="number"
              value={state.oldOrderRange}
              onChange={(value) => handleChangeOldOrderRange(value)}
              onBlur={handleBlurOldOrderRange}
              error={state.validateMessage}
              min={1}
              max={60}
            />
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default memo(ProcessOldOrders);

import { Badge, Card, Tabs } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import VisitorAnalyticsChart from './VisitorAnalytics';
import BlockedChart from './Blocked';
import RedirectChart from './Redirect';
import userPlans from '@/hooks/userPlans';
import { useDispatch, useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleTabTotalVisitors, tabTotalVisitorSelector } from '@/redux/slice/visitorAnalytics.slice';
import ScrollInToView from '@/components/ScrollInToView';
import { IDScrollIntoView } from '@/constants/enum';
import { useTranslation } from 'react-i18next';

const AnalyticsChart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['visitor_analytics']);
  const { userPlanFree } = userPlans();
  const dataSettings = useSelector(dataSettingsSelector);
  const tabTotalVisitor = useSelector(tabTotalVisitorSelector);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(handleTabTotalVisitors(selectedTabIndex)),
    [dispatch],
  );

  const tabs = [
    {
      id: 'visited',
      content: t('visitor_analytics:allowed_visitor'),
      component: <VisitorAnalyticsChart />,
    },
    {
      id: 'blocked',
      content: t('visitor_analytics:blocked_visitor'),
      component: <BlockedChart />,
    },
    {
      id: 'redirect',
      content: t('visitor_analytics:redirected_visitor'),
      component: <RedirectChart />,
    },
  ];
  return (
    <ScrollInToView idScrollToBlock={IDScrollIntoView.Allowed_block_visitor}>
      <Card padding={'100'}>
        <div className="card-chart-container">
          <div className="d-flex align-center">
            <Tabs tabs={tabs} fitted selected={tabTotalVisitor} onSelect={handleTabChange} />
            {userPlanFree && !dataSettings?.settings.enableVisitorAnalytics && (
              <div className="badge-demo">
                <Badge tone="enabled">{t('visitor_analytics:demo')}</Badge>
              </div>
            )}
          </div>
          {tabs[tabTotalVisitor].component}
        </div>
      </Card>
    </ScrollInToView>
  );
};
export default memo(AnalyticsChart);

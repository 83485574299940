import blockify from '@/asset/images/blockify.png';
import logo from '@/asset/images/logo.webp';
import illustration from '@/asset/images/Illustration.webp';
import insurance from '@/asset/images/Insurance.webp';
import block_collection from '@/asset/images/block_collection.webp';
import block_isp from '@/asset/images/block_isp.webp';
import block_state from '@/asset/images/block_state.webp';
import block_ip_range from '@/asset/images/block_ip_range.webp';
import block_ip_ranges from '@/asset/images/ip_ranges.png';
import block_ip_address from '@/asset/images/block_ip_address.webp';
import block_country from '@/asset/images/block_country.webp';
import block_specific_page from '@/asset/images/specific_page.png';
import countries from '@/asset/images/countries.png';
import referralLink from '@/asset/images/referral.png';
import userAgent from '@/asset/images/user-agent.png';
import emptyState from './images/emptystate-files.webp';
import openTheme from './images/welcome/open_theme.gif';
import welcome_ip_address from './images/welcome/ip_address.svg';
import welcome_block_country from './images/welcome/country.svg';
import welcome_province from './images/welcome/province.svg';
import welcome_redirect_country from './images/welcome/redirect_country.svg';
import welcome_vpn from './images/welcome/vpn.svg';
import welcome_protected_content from './images/welcome/protect_content.svg';
import products from './images/products.webp';
import CTA from './images/recommendedApps/CTA.png';
import orderTracking from './images/recommendedApps/orderTracking.png';
import returnDrive from './images/recommendedApps/returnDrive.avif';
import avada from './images/recommendedApps/avada.png';
import syntrack from './images/recommendedApps/syntrack.avif';
import step3Image from '@/asset/images/step3Image.png';
import dashboardImg from '@/asset/images/dashboardImg.png';
import helpImg from '@/asset/images/helpImg.png';
import vaDemo from '@/asset/images/va_demo.webp';
import vaLock from '@/asset/images/va_lock.png';
import lockFreePlanVA from '@/asset/images/lockFreePlanVA.png';
import disputeImg from '@/asset/images/dispute.webp';
import quoteSnap from '@/asset/images/quote_snap.png';
import requestFeature from '@/asset/images/request-feature.png';
import turnOnTheme from '@/asset/images/turn-on-theme.png';
import specialSale from '@/asset/images/special_sale.png';
import ordertrackingBanner from './images/recommendedApps/order_tracking_banner.webp';
import returnsDriveBanner from './images/recommendedApps/return_banner.webp';
import synctrack_banner from './images/synctrack_banner.png';
import gradient_template from './images/template/gradient.png';
import light_purple_template from './images/template/light-purple.png';
import pixel_template from './images/template/pixel.png';
import simple_template from './images/template/simple.png';
import default_template from './images/template/default.png';
import template3D_template from './images/template/template3D.png';
import bc_email from './block_checkout/block-email.png';
import bc_phone from './block_checkout/block-phone.png';
import bc_name from './block_checkout/block-user.png';
import bc_country from './block_checkout/country.png';
import bc_address from './block_checkout/block-address.png';
import bc_email_domain from './block_checkout/block_email_domain.png';
import bc_zip_code from './block_checkout/block_zip_code.png';
import hp_date_time from './hide_payment/dateTime.png';
import hp_customer from './hide_payment/customer.png';
import hp_contact from './hide_payment/contact.png';
import hp_shipping_address from './hide_payment/shippingAddress.png';
import hp_banner from './hide_payment/hidePayment.png';
import hs_banner from './hide_payment/hideShipping.png';
import contact_development_store from './images/development-store.png';
import gift_box from './images/dashboard/gift_box.png';
import balloon from './images/dashboard/balloon.png';
import BlockifyFraudCountryBlocker from './images/dashboard/BlockifyFraudCountryBlocker.png';
import checkoutRulesBanner from './images/dashboard/checkout_rules_banner.png';
import checkoutRulesLogo from './images/dashboard/checkout_logo.png';
import circleChecked from '@/asset/images/circleChecked.png';
import preview_error_checkout from '@/asset/images/preview_error_checkout.png';
import consentikLogo from './images/dashboard/logo_consntik.webp';
import trustifyLogo from './images/dashboard/logo_trustiy.webp';

export const images = {
  bc_email,
  bc_email_domain,
  bc_phone,
  bc_name,
  bc_country,
  bc_address,
  bc_zip_code,
  blockify,
  logo,
  illustration,
  insurance,
  block_collection,
  block_isp,
  block_state,
  block_ip_range,
  block_ip_address,
  block_country,
  block_specific_page,
  emptyState,
  openTheme,
  products,
  helpImg,
  CTA,
  orderTracking,
  returnDrive,
  avada,
  syntrack,
  step3Image,
  dashboardImg,
  vaDemo,
  vaLock,
  disputeImg,
  turnOnTheme,
  specialSale,
  quoteSnap,
  ordertrackingBanner,
  returnsDriveBanner,
  requestFeature,
  referralLink,
  countries,
  synctrack_banner,
  gradient_template,
  light_purple_template,
  pixel_template,
  simple_template,
  template3D_template,
  default_template,
  userAgent,
  block_ip_ranges,
  hp_date_time,
  hp_customer,
  hp_contact,
  hp_shipping_address,
  hp_banner,
  hs_banner,
  lockFreePlanVA,
  contact_development_store,
  gift_box,
  balloon,
  BlockifyFraudCountryBlocker,
  checkoutRulesBanner,
  checkoutRulesLogo,
  welcome_block_country,
  welcome_ip_address,
  welcome_protected_content,
  welcome_province,
  welcome_redirect_country,
  welcome_vpn,
  circleChecked,
  preview_error_checkout,
  consentikLogo,
  trustifyLogo,
};

import options, { perPageOptions } from '@/constants/options';
import hidePaymentSlice, { hidePaymentTableSelector } from '@/redux/slice/hidePayment.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const HidePaymentFilter = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const hidePaymentTable = useSelector(hidePaymentTableSelector);
  const filters = [
    {
      key: 'perPage',
      label: t('common:per_page'),
      filter: (
        <ChoiceList
          title={t('common:per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[hidePaymentTable.perPage]}
          onChange={(value) =>
            dispatch(
              hidePaymentSlice.actions.handleHidePaymentTable({
                ...hidePaymentTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: t('common:criteria'),
      filter: (
        <ChoiceList
          title={t('common:criteria')}
          titleHidden
          choices={[{ label: t('common:all'), value: '' }, ...options.criteriaHidePaymentFilter]}
          selected={[hidePaymentTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              hidePaymentSlice.actions.handleHidePaymentTable({
                ...hidePaymentTable,
                criteria: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          hidePaymentSlice.actions.handleHidePaymentTable({
            ...hidePaymentTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `${t('common:criteria')}: ${
        options.criteriaHidePaymentFilter.find((option) => option.value === hidePaymentTable.criteria)?.label || t('common:all')
      }`,
      hidden: !hidePaymentTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          hidePaymentSlice.actions.handleHidePaymentTable({
            ...hidePaymentTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: t('common:record_per_page', { perPage: hidePaymentTable.perPage }),
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters,
  };
};

import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { IsVpn, OrderRisk } from '@/constants/enum';
import { orderTypeOptions, orderVpnOptions, perPageOptions } from '@/constants/options';
import userPlans from '@/hooks/userPlans';
import fraudOrdersSlice, { fraudOrdersTableSelector } from '@/redux/slice/fraudOrders';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useFilter = () => {
  const { t } = useTranslation(['common', 'fraud_orders']);
  const dispatch = useDispatch();
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const { userPlanFree } = userPlans();
  const handleChangeFilter = (key: keyof typeof fraudOrdersTable) => (value: Array<string>) => {
    dispatch(fraudOrdersSlice.actions.handleFraudOrdersTable({ ...fraudOrdersTable, [key]: value[0] || undefined, page: 1 }));
  };
  const handleChangeSelectedItem = (key: keyof typeof fraudOrdersTable) => (value: Array<string>) => {
    if (value[0] === OrderRisk.Low) {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(1));
    } else if (value[0] === OrderRisk.Medium) {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(2));
    } else if (value[0] === OrderRisk.High) {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(3));
    } else {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(0));
    }
  };
  const filters = [
    {
      key: 'perPage',
      label: t('common:per_page'),
      filter: (
        <ChoiceList
          title={t('common:per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[fraudOrdersTable.perPage]}
          onChange={handleChangeFilter('perPage')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'orderType',
      label: t('fraud_orders:order_type'),
      shortcut: true,
      filter: (
        <ChoiceList
          title={t('fraud_orders:order_type')}
          titleHidden
          choices={orderTypeOptions(t)}
          selected={[fraudOrdersTable.risk || '']}
          onChange={(selected) => {
            handleChangeFilter('risk')(selected);
            handleChangeSelectedItem('risk')(selected);
          }}
        />
      ),
    },
    {
      key: 'orderVpn',
      label: t('fraud_orders:order_from_vpn_proxy'),
      shortcut: true,
      suffix: userPlanFree ? <TooltipUpdateHigherPlan /> : undefined,
      filter: (
        <ChoiceList
          title={t('fraud_orders:order_from_vpn_proxy')}
          choices={orderVpnOptions(t)}
          selected={[fraudOrdersTable.vpn || '']}
          onChange={handleChangeFilter('vpn')}
        />
      ),
    },
  ];

  const riskLabel =
    fraudOrdersTable.risk === OrderRisk.Low
      ? t('fraud_orders:low')
      : fraudOrdersTable.risk === OrderRisk.Medium
      ? t('fraud_orders:medium')
      : t('fraud_orders:high');

  const vpnLabel =
    fraudOrdersTable.vpn === IsVpn.Yes
      ? t('common:yes')
      : fraudOrdersTable.vpn === IsVpn.No
      ? t('common:no')
      : t('common:not_detected');

  const appliedFilters = [
    {
      onRemove: () => {
        dispatch(
          fraudOrdersSlice.actions.handleFraudOrdersTable({
            ...fraudOrdersTable,
            page: 1,
            perPage: '10',
          }),
        );
      },
      key: 'perPage',
      label: t('common:record_per_page', { perPage: fraudOrdersTable.perPage }),
      hidden: fraudOrdersTable.perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          fraudOrdersSlice.actions.handleFraudOrdersTable({
            ...fraudOrdersTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'orderType',
      label: `${t('fraud_orders:order_type')}: ${riskLabel}`,
      hidden: !fraudOrdersTable.risk,
    },
    {
      onRemove: () =>
        dispatch(
          fraudOrdersSlice.actions.handleFraudOrdersTable({
            ...fraudOrdersTable,
            vpn: undefined,
            page: 1,
          }),
        ),
      key: 'orderVpn',
      label: `${t('fraud_orders:order_from_vpn_proxy')}: ${vpnLabel}`,
      hidden: !fraudOrdersTable.vpn,
    },
  ];

  return {
    filters,
    appliedFilters,
  };
};

export default useFilter;

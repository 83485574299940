import { BREAKPOINT, IDScrollIntoView, UserPlan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { loadingSelector } from '@/redux/slice/dataSettings.slice';
import { ISettingToggle } from '@/types/components';
import { Badge, Button, Card, InlineGrid, Link, SkeletonDisplayText, Text, Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { CustomTooltip } from '../CustomTooltip';
import RegularText from '../RegularText';
import { CustomSettingToggleStyled } from './styled';
import { apiCaller } from '@/redux/query';
import CustomBadge from '../CustomBadge';
import { useTranslation } from 'react-i18next';

const CustomSettingToggle = ({ ...props }: ISettingToggle.IProps) => {
  const navigate = useNavigate();
  const scope = useScope();
  const isLoading = useSelector(loadingSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = userPlans();
  const { t } = useTranslation(['common']);
  const [trackAction] = apiCaller.useTrackActionMutation();

  const isUserPlanFree = () => props.minActivePlan === UserPlan.PREMIUM && userPlanFree;
  const isUserPlanEnterprise = () => props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium);
  const isUserPlanEnterpriseWithoutShopifyPlus = () =>
    props.minActivePlan && props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium) && !shopifyPlanPlus;
  const isUserPlanEnterpriseWithShopifyPlus = () =>
    props.minActivePlan && props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium) && shopifyPlanPlus;
  const isUserPlanShopifyPlus = () => props.minActivePlan && props.minActivePlan === UserPlan.SHOPIFYPLUS && userPlanFree;
  const condition =
    isUserPlanFree() ||
    isUserPlanEnterprise() ||
    isUserPlanEnterpriseWithoutShopifyPlus() ||
    isUserPlanEnterpriseWithShopifyPlus() ||
    isUserPlanShopifyPlus();
  const renderBadge = () => {
    if (isUserPlanFree()) {
      return <CustomBadge scrollToID={IDScrollIntoView.Pricing_premium}>Premium plan or higher</CustomBadge>;
    }
    if (isUserPlanEnterpriseWithoutShopifyPlus()) {
      return <CustomBadge scrollToID={IDScrollIntoView.Pricing_enterprise}>Enterprise plan</CustomBadge>;
    }
    if (isUserPlanEnterpriseWithShopifyPlus()) {
      return <CustomBadge scrollToID={IDScrollIntoView.Pricing_enterprise}>Enterprise plan or higher</CustomBadge>;
    }
    if (isUserPlanShopifyPlus()) {
      return <CustomBadge scrollToID={IDScrollIntoView.Pricing_shopify_plus}>Shopify plus plan</CustomBadge>;
    }
    return null;
  };
  return (
    <CustomSettingToggleStyled>
      <Card>
        <InlineGrid columns={isMobile || props.oneColumn ? 1 : ['twoThirds', 'oneThird']}>
          <div>
            <div className="toggle-settings-title">
              <Text as="h5" variant="headingMd">
                {props.title}
                <div className="ml-4">
                  <div className="d-flex">
                    <Badge tone={props.settingToggleProps.enabled ? 'success' : 'enabled'}>
                      {props.settingToggleProps.enabled ? t('common:on') : t('common:off')}
                    </Badge>
                    {renderBadge()}
                  </div>
                </div>
              </Text>
              {props.oneColumn ? (
                <div className="toggle-btn">
                  {isLoading && props.settingToggleProps.action?.loading ? (
                    <SkeletonDisplayText size="small" />
                  ) : condition ? (
                    <Button
                      onClick={() => {
                        navigate(PATH.PRICING_PLAN);
                        trackAction(props.trackAction);
                      }}
                      loading={props.settingToggleProps.action?.loading}
                      variant="primary"
                    >
                      {t('common:turn_on_by_upgrade')}
                    </Button>
                  ) : (
                    <Button
                      disabled={scope.isViewOnly}
                      onClick={props.settingToggleProps.action?.onAction}
                      loading={props.settingToggleProps.action?.loading}
                      variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                    >
                      {props.settingToggleProps.enabled ? t('common:turn_off') : t('common:turn_on')}
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
            {props.children ? (
              <div className="mt-8">
                {typeof props.children === 'string' ? <RegularText>{props.children}</RegularText> : props.children}
              </div>
            ) : null}
          </div>
          {!props.oneColumn ? (
            <div className="toggle-btn">
              {isLoading && props.settingToggleProps.action?.loading ? (
                <SkeletonDisplayText size="small" />
              ) : condition ? (
                <Button
                  onClick={() => {
                    navigate(PATH.PRICING_PLAN);
                    trackAction(props.trackAction);
                  }}
                  loading={props.settingToggleProps.action?.loading}
                  variant="primary"
                >
                  {t('common:turn_on_by_upgrade')}
                </Button>
              ) : (
                <>
                  {!!props.contentTooltip ? (
                    <Tooltip content={props.contentTooltip}>
                      <Button
                        disabled
                        onClick={props.settingToggleProps.action?.onAction}
                        loading={props.settingToggleProps.action?.loading}
                        variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                      >
                        {props.settingToggleProps.enabled ? t('common:turn_off') : t('common:turn_on')}
                      </Button>
                    </Tooltip>
                  ) : (props.disabled && !props.contentTooltipWithLink) || !props.disabled ? (
                    <Button
                      disabled={scope.isViewOnly || props.disabled}
                      onClick={props.settingToggleProps.action?.onAction}
                      loading={props.settingToggleProps.action?.loading}
                      variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                    >
                      {props.settingToggleProps.enabled ? t('common:turn_off') : t('common:turn_on')}
                    </Button>
                  ) : (
                    <CustomTooltip
                      activator={
                        <div className="btn-toggle">
                          <RegularText>
                            {props.settingToggleProps.enabled ? t('common:turn_off') : t('common:turn_on')}
                          </RegularText>
                        </div>
                      }
                    >
                      <Link onClick={props.onClick}>{props.contentTooltipWithLink}</Link>
                    </CustomTooltip>
                  )}
                </>
              )}
            </div>
          ) : null}
        </InlineGrid>
        {props.content ? <div className="mt-16">{props.content}</div> : null}
      </Card>
    </CustomSettingToggleStyled>
  );
};

export default memo(CustomSettingToggle);

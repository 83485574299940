import BannerGrantPermission from '@/components/BannerGrantPermission';
import CriticalBanner from '@/components/CriticalBanner';
import CustomButton from '@/components/CustomButton';
import CustomCard from '@/components/customCard';
import InformationBanner from '@/components/InformationBanner';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import {
  BlockCheckoutType,
  BREAKPOINT,
  IDScrollIntoView,
  ScopeGroups,
  SortType,
  SubKeyHelpCenter,
  UserPlan,
} from '@/constants/enum';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import blockCheckoutSlice, { dataTableSelector, inputSearchSelector, tableTabsSelector } from '@/redux/slice/blockCheckout.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import {
  Button,
  Card,
  Checkbox,
  IndexFilters,
  IndexFiltersMode,
  Link,
  Modal,
  SortButtonChoice,
  TextField,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { DeleteIcon, RefreshIcon } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tabs from './Components/Tabs';
import { BlockCheckoutStyled } from './styled';
import Table from './Table';
import { BlockCheckoutFilter } from './Table/filter';
// import TableBlackList from './BlockCheckoutAccess/Table/TableBlackList';
import BannerReviewApp from '@/components/BannerReviewApp';
import HelpCenter from '@/components/HelpCenter';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockCheckoutAccessSlice, {
  blackListTableSelector,
  inputSearchBlockAccessSelector,
} from '@/redux/slice/blockCheckoutAccess';
import helpCenterSlice, { filterSubKeyHelpCenterWhenEditBlockCheckout, subKeySelector } from '@/redux/slice/helpCenter.slice';
import { BlackListFilter } from './BlockCheckoutAccess/Table/Filters/Filter.blacklist';
import TableBlackList from './BlockCheckoutAccess/Table/TableBlackList';
import { useMediaQuery } from 'react-responsive';
import { handleIdScrollIntoView } from '@/redux/slice/home.slice';
import { ContextualBar } from '@/components/ContextualBar';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

const BlockCheckout = () => {
  const scope = useScope();
  const { t } = useTranslation(['common', 'block_checkout']);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableTabs = useSelector(tableTabsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const banner = useSelector(bannerSelector);
  const inputSearchBlockFields = useSelector(inputSearchSelector);
  const inputSearchBlockAccess = useSelector(inputSearchBlockAccessSelector);
  const dataTableBlockFields = useSelector(dataTableSelector);
  const dataTableBlockAccess = useSelector(blackListTableSelector);
  const subKeyHelpCenter = useSelector(subKeySelector);

  const { userPlanFree, userPlanPremium, shopifyPlanPlus, userPlanShopifyPlus, userPlanEnterprise } = userPlans();
  const contentTooltip =
    userPlanFree && shopifyPlanPlus ? t('common:available_on_shopify_plus') : t('common:available_on_enterprise_plan');
  const { mode, setMode } = useSetIndexFiltersMode();
  const { data } = apiCaller.useFetchBlockCheckoutQuery(config.shop, { skip: isSkip });
  const [trigger, updateStatus] = apiCaller.useUpdateBlockCheckoutMutation();
  const [deleteAllBlockCheckout, deleteAllBlockCheckoutStatus] = apiCaller.useDeleteAllBlockCheckoutRulesMutation();
  const [deleteAllBlockAccess, deleteAllBlockAccessStatus] = apiCaller.useDeleteAllBlackListSettingMutation();
  const isShopifyPlus = process.env.REACT_APP_ENV === 'production' ? shopifyPlanPlus : true;
  const [autoSync, autoSyncStatus] = apiCaller.useAutoSyncRuleToBlockCheckoutAccessMutation();
  const [trackAction] = apiCaller.useTrackActionMutation();

  const [onGuide, setOnGuild] = useState(false);
  const [errorBanner, setErrorBanner] = useState({
    status: false,
    msg: '',
  });
  const [stateBackup, setStateBackup] = useState({
    feature: false,
    bot: false,
    message: 'Order cannot be placed due to suspected fraud.',
    error: '',
  });
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [state, setState] = useState({
    feature: false,
    bot: false,
    message: 'Order cannot be placed due to suspected fraud.',
    error: '',
  });

  const handleOpenModalDeleteAll = () => {
    setIsOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const sortOptions: SortButtonChoice[] = [
    { label: t('block_checkout:created_time'), value: 'createdAt asc', directionLabel: t('block_checkout:ascending') },
    { label: t('block_checkout:created_time'), value: 'createdAt desc', directionLabel: t('block_checkout:descending') },
  ];

  const handleDeleteAllBlockCheckout = useCallback(() => {
    if (tableTabs) {
      deleteAllBlockCheckout().then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
          handleCloseModalDelete();
        }
      });
    } else {
      deleteAllBlockAccess({ pathName: 'checkout-page' }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
          handleCloseModalDelete();
        }
      });
    }
  }, [deleteAllBlockAccess, deleteAllBlockCheckout, dispatch, tableTabs, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _debounce((value: IParamsApi.IGetListCheckout | IParamsApi.IGetSettingList) => {
      if (tableTabs) {
        dispatch(blockCheckoutSlice.actions.handleDataTable(value));
      } else {
        if ('priority' in value) dispatch(blockCheckoutAccessSlice.actions.handleBlackListTable(value));
      }
    }, 1000),
    [],
  );

  const handleInputChangeTable = useCallback(
    (search: string) => {
      if (tableTabs) {
        dispatch(blockCheckoutSlice.actions.handleInputSearch(search));
        debounceSearch({
          ...dataTableBlockFields,
          search,
          page: search ? 1 : dataTableBlockFields.page,
        });
      } else {
        dispatch(blockCheckoutAccessSlice.actions.handleInputSearch(search));
        debounceSearch({
          ...dataTableBlockAccess,
          search,
          page: search ? 1 : dataTableBlockAccess.page,
        });
      }
    },
    [dataTableBlockAccess, dataTableBlockFields, debounceSearch, dispatch, tableTabs],
  );

  const handleClearAllFilter = useCallback(() => {
    if (tableTabs) {
      dispatch(
        blockCheckoutSlice.actions.handleDataTable({
          ...dataTableBlockFields,
          perPage: '10',
          criteria: '',
        }),
      );
    } else {
      dispatch(
        blockCheckoutAccessSlice.actions.handleBlackListTable({
          ...dataTableBlockAccess,
          perPage: '10',
          criteria: '',
          type: '',
          priority: '',
        }),
      );
    }
  }, [dataTableBlockAccess, dataTableBlockFields, dispatch, tableTabs]);

  const handleOpenLearnMore = () => {
    window.open('https://docs.ipblocker.io/getting-started/block-checkout/auto-block-checkout-with-free-products', '_blank');
  };
  const handleOpenLearnMoreBanner = () => {
    window.open('https://docs.ipblocker.io/getting-started/block-checkout', '_blank');
  };

  const handleUpdateGeneral = () => {
    if (state.message.trim().length === 0) {
      setState((pre) => ({
        ...pre,
        error: t('block_checkout:error_message_blank'),
      }));
    } else {
      trigger({
        enable: state.feature,
        enableBlockBot: state.bot,
        message: state.message.trim(),
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setErrorBanner(condition);
        } else {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        }
      });
    }
  };

  const tabsConfig = [
    {
      id: 'block-checkout-access',
      content: t('block_checkout:block_checkout_access'),
      panelID: 'block-checkout-access',
      disabled: !isShopifyPlus,
      children: (
        <TableBlackList
          onGuide={() => {
            setOnGuild(true);
          }}
        />
      ),
    },

    {
      id: 'block-checkout-fields',
      panelID: 'block-checkout-fields',
      content: t('block_checkout:block_checkout_fields'),
      children: (
        <div>
          <div className="mt-8">
            <InformationBanner
              isVisible={banner.abandonedCheckout}
              onDismiss={() =>
                dispatch(
                  handleVisibleBanner({
                    key: 'abandonedCheckout',
                    value: false,
                  }),
                )
              }
            >
              {t('block_checkout:shopify_abandoned_checkout_restriction')}
            </InformationBanner>
          </div>
          <Table
            onEdit={(item: IResponseApi.IBlockCheckoutRule) => {
              const subKey = filterSubKeyHelpCenterWhenEditBlockCheckout.find((filter) =>
                filter.criteria.includes(item.criteria as BlockCheckoutType),
              )?.value;
              if (subKey) {
                dispatch(helpCenterSlice.actions.handleSubKey(subKey));
              }
              dispatch(blockCheckoutSlice.actions.handleBlockRuleSelected(item));
              dispatch(blockCheckoutSlice.actions.handleBlockRuleBackupSelected(item));
              navigate(PATH.BLOCK_CHECKOUT_PAGE);
            }}
            onGuide={() => {
              setOnGuild(true);
            }}
          />
        </div>
      ),
    },
  ].filter((item) => !!item);

  const handleSync = () => {
    autoSync().then((res) => {
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
    });
  };

  useEffect(() => {
    if (data?.data) {
      setState({
        bot: data.data.enableBlockBot,
        message: data.data.message,
        feature: data.data.enable,
        error: '',
      });
      setStateBackup({
        bot: data.data.enableBlockBot,
        message: data.data.message,
        feature: data.data.enable,
        error: '',
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (subKeyHelpCenter !== SubKeyHelpCenter.BlockCheckoutGeneral) {
      dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.BlockCheckoutGeneral));
    }
    dispatch(blockCheckoutSlice.actions.handleErrorCSV(''));
    dispatch(blockCheckoutSlice.actions.handleChangeFileDefault(null));
  }, [dispatch, subKeyHelpCenter]);

  return (
    <Layout
      layoutProps={{
        title: t('block_checkout:block_checkout'),
        primaryAction: <HelpCenter />,
      }}
    >
      {/* {!userPlanFree ? <CheckoutRulesBanner marginBottom={'12px'} /> : null} */}

      <ContextualBar
        disabledSave={isEqual(state, stateBackup) || scope.isViewOnly}
        loadingSave={updateStatus.isLoading}
        onSave={handleUpdateGeneral}
        onDiscard={() => setState(stateBackup)}
        value={state}
        disabledDiscard={isEqual(state, stateBackup)}
      />

      <BlockCheckoutStyled isBlockAccess={!tableTabs}>
        {onGuide ? <div className="wrapper-guide" onClick={() => setOnGuild(false)} /> : null}
        {userPlanShopifyPlus && (
          <InformationBanner
            hideIcon
            isVisible={banner.bannerBlockCheckoutAccess && isShopifyPlus}
            onDismiss={() => {
              dispatch(
                handleVisibleBanner({
                  key: 'bannerBlockCheckoutAccess',
                  value: false,
                }),
              );
            }}
            title={t('block_checkout:more_protection_2fa')}
          >
            <RegularText>
              {t('block_checkout:recommend_2fa_blockify')}{' '}
              <span className="link-no-monochrome">
                <Link
                  url="https://docs.ipblocker.io/~/changes/44Zuc8j7J1SjykZJzwQn/getting-started/instruction-to-add-checkout-blocks-for-shopify-plus-only"
                  target="_blank"
                >
                  {t('block_checkout:this_article')}
                </Link>
              </span>
              .
            </RegularText>
          </InformationBanner>
        )}
        <BannerGrantPermission
          title={t('block_checkout:important_step_to_use_feature')}
          isVisible={
            !dataSettings?.settings.user.validationScope &&
            (dataSettings?.settings.user.plan === UserPlan.ENTERPRISE ||
              dataSettings?.settings.user.plan === UserPlan.SHOPIFYPLUS)
          }
          enum={ScopeGroups.validation}
          children={t('block_checkout:manage_checkout_permissions')}
          variant="primary"
        />

        <BannerReviewApp />

        {!userPlanShopifyPlus && !userPlanEnterprise && (
          <InformationBanner
            title={
              shopifyPlanPlus
                ? t('block_checkout:block_checkout_feature_shopify_plus')
                : t('block_checkout:block_checkout_enterprise_only')
            }
            isVisible={banner.bannerUpgradeShopifyPlus}
            onDismiss={() => {
              dispatch(
                handleVisibleBanner({
                  key: 'bannerUpgradeShopifyPlus',
                  value: false,
                }),
              );
            }}
          >
            <RegularText>
              {t('block_checkout:prevent_unexpected_orders')}{' '}
              <Link onClick={handleOpenLearnMoreBanner}>{t('common:learn_more')}</Link>
            </RegularText>
            <div className="mt-16 ">
              <Button
                onClick={() => {
                  navigate(PATH.PRICING_PLAN);
                  trackAction('block_checkout_banner_checkout_feature');
                  dispatch(
                    handleIdScrollIntoView(
                      shopifyPlanPlus ? IDScrollIntoView.Pricing_shopify_plus : IDScrollIntoView.Pricing_enterprise,
                    ),
                  );
                }}
                variant="primary"
              >
                {t('block_checkout:upgrade_now')}
              </Button>
            </div>
          </InformationBanner>
        )}

        {userPlanFree || userPlanPremium ? (
          <Tooltip content={contentTooltip}>
            <div className={disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) ? 'div-disable' : ''}>
              <CustomCard title={t('block_checkout:quick_setup')}>
                <CriticalBanner isVisible={errorBanner.status}>
                  <RegularText>{errorBanner.msg}</RegularText>
                </CriticalBanner>
                <div className="mt-16">
                  <Checkbox
                    label={
                      <RegularText>
                        {t('block_checkout:auto_block_orders_zero_subtotal')}{' '}
                        <Link onClick={handleOpenLearnMore}>{t('common:learn_more')}</Link>
                      </RegularText>
                    }
                    checked={state.bot}
                    onChange={() => {}}
                  />
                </div>
                <div className="mt-8">
                  <TextField
                    autoComplete="off"
                    label={t('block_checkout:error_message')}
                    helpText={t('block_checkout:checkout_page_block_message')}
                    value={state.message}
                    onChange={() => {}}
                  />
                </div>
                {config.embedded !== '1' && (
                  <div className={`mt-16 ${isMobile ? '' : 'd-flex flex-end'}`}>
                    <Button
                      variant="primary"
                      onClick={() => {}}
                      loading={updateStatus.isLoading}
                      disabled={JSON.stringify(stateBackup) === JSON.stringify(state) || scope.isViewOnly}
                    >
                      {t('common:save')}
                    </Button>
                  </div>
                )}
              </CustomCard>
            </div>
          </Tooltip>
        ) : (
          <div>
            <CustomCard title={t('block_checkout:quick_setup')}>
              <CriticalBanner isVisible={errorBanner.status}>
                <RegularText>{errorBanner.msg}</RegularText>
              </CriticalBanner>
              <div className="mt-16">
                <Checkbox
                  label={
                    <RegularText>
                      {t('block_checkout:auto_block_orders_zero_subtotal')}{' '}
                      <Link onClick={handleOpenLearnMore}>{t('common:learn_more')}</Link>
                    </RegularText>
                  }
                  checked={state.bot}
                  onChange={() =>
                    setState({
                      ...state,
                      bot: !state.bot,
                    })
                  }
                />
              </div>
              <div className="mt-8">
                <TextField
                  autoComplete="off"
                  label={t('block_checkout:error_message')}
                  helpText={t('block_checkout:checkout_page_block_message')}
                  value={state.message}
                  onChange={(value) => {
                    setState({
                      ...state,
                      message: value,
                      error: '',
                    });
                  }}
                  error={state.error}
                />
              </div>
              {config.embedded !== '1' && (
                <div className={`mt-16 ${isMobile ? '' : 'd-flex flex-end'}`}>
                  <Button
                    variant="primary"
                    onClick={handleUpdateGeneral}
                    loading={updateStatus.isLoading}
                    disabled={JSON.stringify(stateBackup) === JSON.stringify(state) || scope.isViewOnly}
                  >
                    {t('common:save')}
                  </Button>
                </div>
              )}
            </CustomCard>
          </div>
        )}

        {userPlanFree || userPlanPremium ? (
          <Tooltip content={contentTooltip}>
            <div className={disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) ? 'div-disable' : ''}>
              <div className="mt-16 control-rule-container">
                <Tabs
                  onCloseGuide={() => {
                    setOnGuild(false);
                  }}
                />
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="mt-16 control-rule-container">
            <Tabs
              onCloseGuide={() => {
                setOnGuild(false);
              }}
            />
          </div>
        )}

        {userPlanFree || userPlanPremium ? (
          <Tooltip content={contentTooltip} zIndexOverride={600}>
            <div className={disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) ? 'div-disable' : ''}>
              <div className="mt-16">
                <Card padding={'0'}>
                  <IndexFilters
                    queryValue={tableTabs ? inputSearchBlockFields : inputSearchBlockAccess}
                    queryPlaceholder={
                      tableTabs
                        ? t('block_checkout:search_by_email_phone_name_country_address')
                        : t('common:searching_by_ip_and_location')
                    }
                    onQueryChange={handleInputChangeTable}
                    onQueryClear={() => {
                      handleInputChangeTable('');
                    }}
                    tabs={tabsConfig}
                    selected={tableTabs}
                    sortOptions={sortOptions}
                    sortSelected={
                      tableTabs
                        ? [`${dataTableBlockFields.sortBy} ${dataTableBlockFields.sort.toLowerCase()}`]
                        : [`${dataTableBlockAccess.sortBy} ${dataTableBlockAccess.sort.toLowerCase()}`]
                    }
                    onSelect={(index) => {
                      dispatch(blockCheckoutSlice.actions.handleTableTabs(index));
                    }}
                    canCreateNewView={false}
                    filters={tableTabs ? BlockCheckoutFilter().filters : BlackListFilter().filters}
                    appliedFilters={tableTabs ? BlockCheckoutFilter().appliedFilters : BlackListFilter().appliedFilters}
                    onClearAll={handleClearAllFilter}
                    cancelAction={{ onAction: () => {} }}
                    mode={mode}
                    setMode={setMode}
                    onSort={(value: string[]) => {
                      const data = value[0].split(' ');
                      if (tableTabs) {
                        dispatch(
                          blockCheckoutSlice.actions.handleDataTable({
                            ...dataTableBlockFields,
                            sortBy: data[0],
                            sort: data[1] as SortType,
                          }),
                        );
                      } else {
                        dispatch(
                          blockCheckoutAccessSlice.actions.handleBlackListTable({
                            ...dataTableBlockAccess,
                            sortBy: data[0],
                            sort: data[1] as SortType,
                          }),
                        );
                      }
                    }}
                  />
                  {tabsConfig[tableTabs].children}
                </Card>
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="card-table">
            {mode === IndexFiltersMode.Filtering ? null : (
              <div className="btn-delete">
                <div>
                  <Tooltip content={t('common:delete_all')}>
                    <CustomButton icon={DeleteIcon} onClick={handleOpenModalDeleteAll} />
                  </Tooltip>
                </div>
                {!tableTabs ? (
                  <div className="btn-sync ml-8">
                    <Tooltip content={t('block_checkout:sync_rules')}>
                      <CustomButton icon={RefreshIcon} onClick={handleSync} loading={autoSyncStatus.isLoading} />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            )}
            <Card padding={'0'}>
              <IndexFilters
                queryValue={tableTabs ? inputSearchBlockFields : inputSearchBlockAccess}
                queryPlaceholder={
                  tableTabs
                    ? t('block_checkout:searching_by_email_phone_number_name_country_address')
                    : t('common:searching_by_ip_and_location')
                }
                onQueryChange={handleInputChangeTable}
                onQueryClear={() => {
                  handleInputChangeTable('');
                }}
                tabs={tabsConfig}
                selected={tableTabs}
                sortOptions={sortOptions}
                sortSelected={
                  tableTabs
                    ? [`${dataTableBlockFields.sortBy} ${dataTableBlockFields.sort.toLowerCase()}`]
                    : [`${dataTableBlockAccess.sortBy} ${dataTableBlockAccess.sort.toLowerCase()}`]
                }
                onSelect={(index) => {
                  dispatch(blockCheckoutSlice.actions.handleTableTabs(index));
                }}
                canCreateNewView={false}
                filters={tableTabs ? BlockCheckoutFilter().filters : BlackListFilter().filters}
                appliedFilters={tableTabs ? BlockCheckoutFilter().appliedFilters : BlackListFilter().appliedFilters}
                onClearAll={handleClearAllFilter}
                cancelAction={{ onAction: () => {} }}
                mode={mode}
                setMode={setMode}
                onSort={(value: string[]) => {
                  const data = value[0].split(' ');
                  if (tableTabs) {
                    dispatch(
                      blockCheckoutSlice.actions.handleDataTable({
                        ...dataTableBlockFields,
                        sortBy: data[0],
                        sort: data[1] as SortType,
                      }),
                    );
                  } else {
                    dispatch(
                      blockCheckoutAccessSlice.actions.handleBlackListTable({
                        ...dataTableBlockAccess,
                        sortBy: data[0],
                        sort: data[1] as SortType,
                      }),
                    );
                  }
                }}
              />
              {tabsConfig[tableTabs]?.children}
            </Card>
          </div>
        )}

        <Modal
          open={isOpenModalDelete}
          onClose={handleCloseModalDelete}
          title={t('common:delete_all')}
          primaryAction={{
            destructive: true,
            content: t('common:delete'),
            onAction: handleDeleteAllBlockCheckout,
            loading: deleteAllBlockCheckoutStatus.isLoading || deleteAllBlockAccessStatus.isLoading,
          }}
          secondaryActions={[
            {
              content: t('common:cancel'),
              onAction: handleCloseModalDelete,
            },
          ]}
        >
          <Modal.Section>
            <RegularText>{t('common:deleted_rules_cannot_revert')}</RegularText>
          </Modal.Section>
        </Modal>
      </BlockCheckoutStyled>
    </Layout>
  );
};

export default memo(BlockCheckout);

import React, { useMemo, useRef, useState } from 'react';
import { ModalSearchStyled, RenderItemSearchStyled } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { handleIdScrollIntoView, handleShowModalSearch, showModalSearchSelector } from '@/redux/slice/home.slice';
import { BlockStack, Icon, Text, TextField } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { extractWords } from '@/helpers';
import { useEventListener, useOnClickOutside } from 'usehooks-ts';
import { images } from '@/asset';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/constants';
import { handleTabTotalVisitors } from '@/redux/slice/visitorAnalytics.slice';
import { ActionType, CriteriaType, IDScrollIntoView, ListType, OptionSettings, TabTotalVisitors } from '@/constants/enum';
import blockListSlice, { settingBockListInitial } from '@/redux/slice/blockList.slice';
import slice from '@/redux/slice';
import { useTranslation } from 'react-i18next';

type SettingsListType = {
  onClick: () => void;
  steps: string[];
};

const ModalSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('modal_search');

  const showModalSearch = useSelector(showModalSearchSelector);

  const settingsList: SettingsListType[] = useMemo(
    () => [
      {
        steps: [t('visitor_analytics'), t('allowed_visitors')],
        onClick: () => {
          dispatch(handleTabTotalVisitors(TabTotalVisitors.AllowedVisitor));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Allowed_block_visitor));
          navigate(PATH.ANALYTICS);
        },
      },
      {
        steps: [t('visitor_analytics'), t('blocked_visitors')],
        onClick: () => {
          dispatch(handleTabTotalVisitors(TabTotalVisitors.BlockedVisitor));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Allowed_block_visitor));
          navigate(PATH.ANALYTICS);
        },
      },
      {
        steps: [t('visitor_analytics'), t('daily_visit_trends')],
        onClick: () => {
          navigate(PATH.ANALYTICS);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Daily_visit_trends));
        },
      },
      {
        steps: [t('block_and_redirector'), t('block_ip_address')],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          const payload = {
            ...settingBockListInitial,
            criteria: CriteriaType.IpAddress,
            type: ActionType.Block,
            priority: ListType.BlackList,
          };
          dispatch(blockListSlice.actions.handleSetting(payload));
          dispatch(blockListSlice.actions.handleSettingBackup(payload));
        },
      },
      {
        steps: [t('block_and_redirector'), t('block_location')],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          const payload = {
            ...settingBockListInitial,
            criteria: CriteriaType.Country,
            type: ActionType.Block,
            priority: ListType.BlackList,
          };
          dispatch(blockListSlice.actions.handleSetting(payload));
          dispatch(blockListSlice.actions.handleSettingBackup(payload));
        },
      },
      {
        steps: [t('block_and_redirector'), t('whitelist_ip_address')],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          const payload = {
            ...settingBockListInitial,
            criteria: CriteriaType.IpAddress,
            type: ActionType.WhiteList,
            priority: ListType.WhiteList,
          };
          dispatch(blockListSlice.actions.handleSetting(payload));
          dispatch(blockListSlice.actions.handleSettingBackup(payload));
        },
      },
      {
        steps: [t('block_and_redirector'), t('whitelist_location')],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          const payload = {
            ...settingBockListInitial,
            criteria: CriteriaType.Country,
            type: ActionType.WhiteList,
            priority: ListType.WhiteList,
          };
          dispatch(blockListSlice.actions.handleSetting(payload));
          dispatch(blockListSlice.actions.handleSettingBackup(payload));
        },
      },
      {
        steps: [t('block_and_redirector'), t('redirect_location')],
        onClick: () => {
          navigate(PATH.BLOCK_PAGE);
          const payload = {
            ...settingBockListInitial,
            criteria: CriteriaType.Country,
            type: ActionType.Redirect,
            priority: ListType.BlackList,
          };
          dispatch(blockListSlice.actions.handleSetting(payload));
          dispatch(blockListSlice.actions.handleSettingBackup(payload));
        },
      },
      {
        steps: [t('fraud_order_analytics'), t('auto_block_visitor_placing_fraud_orders')],
        onClick: () => {
          navigate(PATH.FRAUD_ORDER);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Auto_block_visitors_placing_fraud_orders));
        },
      },
      {
        steps: [t('fraud_order_analytics'), t('auto_cancel_high_risk_orders')],
        onClick: () => {
          navigate(PATH.FRAUD_ORDER);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Auto_cancel_high_risk_orders));
        },
      },
      {
        steps: [t('settings'), t('bot_killer'), t('proxy_and_vpn_blocker')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Proxy_and_vpn_blocker));
        },
      },
      {
        steps: [t('settings'), t('bot_killer'), t('auto_block_visitors')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Auto_block_visitors));
        },
      },
      {
        steps: [t('settings'), t('bot_killer'), t('tor_blocker')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Tor_blocker));
        },
      },
      {
        steps: [t('settings'), t('bot_killer'), t('allowed_bot_list')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Allowed_bot_list));
        },
      },
      {
        steps: [t('settings'), t('content_protection'), t('protect_content')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Protect_content));
        },
      },
      {
        steps: [t('settings'), t('content_protection'), t('deactivate_right_click')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Deactivate_right_click));
        },
      },
      {
        steps: [t('settings'), t('content_protection'), t('deactivate_copy_shortcut')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Deactivate_copy_shortcut));
        },
      },
      {
        steps: [t('settings'), t('content_protection'), t('deactivate_inspect')],
        onClick: () => {
          navigate(PATH.SETTINGS);
          dispatch(slice.settingsSlice.actions.handleSelected([OptionSettings.ContentProtection]));
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Deactivate_inspect));
        },
      },
      {
        steps: [t('settings'), t('blocking_template'), t('edit_template')],
        onClick: () => {
          navigate(PATH.CUSTOMTEMPLATE);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Edit_template));
        },
      },
    ],
    [dispatch, navigate, t],
  );

  const [settingsListForSearch, setSettingsListForSearch] = useState(settingsList);
  const [searchValue, setSearchValue] = useState<string>('');

  const modalSearchRef = useRef(null);

  const onCloseModalSearch = () => {
    dispatch(handleShowModalSearch(false));
    setSearchValue('');
    setSettingsListForSearch(settingsList);
  };

  useOnClickOutside(modalSearchRef, () => {
    if (!showModalSearch) return;
    onCloseModalSearch();
  });

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape' && showModalSearch) {
      onCloseModalSearch();
    }
  });

  const handleOnChangeSearch = (value: string) => {
    setSearchValue(value);

    const words = extractWords(value);

    if (words.length === 0) {
      setSettingsListForSearch(settingsList);
      return;
    }

    const newListForSearch = settingsList
      .filter((setting) => setting.steps.some((step) => words.some((word) => step.toLowerCase().includes(word.toLowerCase()))))
      .sort((setting) => (setting.steps.some((step) => step.toLowerCase() === value.toLowerCase().trim()) ? -1 : 0));

    setSettingsListForSearch(newListForSearch);
  };

  const RenderItemSearch = ({ item, searchValue }: { item: SettingsListType; searchValue: string[] }) => {
    const highlightText = (step: string, highlights: string[]) => {
      if (!highlights || highlights.length === 0) return step;

      const regex = new RegExp(`(${highlights.join('|')})`, 'gi');
      const parts = step.split(regex);

      return parts.map((part, index) =>
        highlights.some((highlight) => part.toLowerCase() === highlight.toLowerCase()) ? (
          <Text id="text-highlight" as="strong" key={index} variant="headingMd">
            {part}
          </Text>
        ) : (
          part
        ),
      );
    };

    const handleOnclick = (item: SettingsListType) => () => {
      item.onClick();
      onCloseModalSearch();
    };

    return (
      <RenderItemSearchStyled onClick={handleOnclick(item)}>
        {item.steps.map((step, index) => (
          <React.Fragment key={index}>
            <Text as="p">{highlightText(step, searchValue)}</Text>
            {index !== item.steps.length - 1 ? '>' : ''}
          </React.Fragment>
        ))}
      </RenderItemSearchStyled>
    );
  };

  if (!showModalSearch) return null;

  return (
    <ModalSearchStyled>
      <div className="card-content" ref={modalSearchRef}>
        <TextField
          autoComplete=""
          label
          placeholder={t('access_settings_shortcut_ctrl_shift_f')}
          prefix={<Icon source={SearchIcon} />}
          autoFocus
          value={searchValue}
          onChange={handleOnChangeSearch}
        />

        <div className="search-result">
          {settingsListForSearch.length === 0 ? (
            <BlockStack inlineAlign="center" gap="500">
              <img width="140px" src={images.illustration} alt="logo-check"></img>
              <Text as="h3" variant="headingLg">
                {t('sorry_we_couldnt_find_any_matches')}
              </Text>
              <Text as="p" tone="subdued" variant="bodyMd">
                {t('please_adjust_your_search_terms_and_try_again')}
              </Text>
            </BlockStack>
          ) : (
            settingsListForSearch.map((setting) => (
              <RenderItemSearch key={JSON.stringify(setting.steps)} item={setting} searchValue={extractWords(searchValue)} />
            ))
          )}
        </div>
      </div>
    </ModalSearchStyled>
  );
};

export default ModalSearch;

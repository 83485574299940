import BlockPageStoreFront from '@/components/BlockPageStoreFront';
import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import SettingSwitch from '@/components/SettingSwitch';
import { config } from '@/config';
import { PATH } from '@/constants';
import { UserPlan } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { Card, Link } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditTemplateBtn = () => {
  const { t } = useTranslation(['settings']);
  const navigate = useNavigate();

  return (
    <CustomButton
      variant="primary"
      onClick={() => {
        navigate(PATH.CUSTOMTEMPLATE);
      }}
    >
      {t('settings:edit_template')}
    </CustomButton>
  );
};

const EditTemplateBtnAppBridge = () => {
  const { t } = useTranslation(['settings']);
  const navigate = useNavigate();
  return (
    <CustomButton
      variant="primary"
      onClick={() => {
        navigate(PATH.CUSTOMTEMPLATE);
      }}
    >
      {t('settings:edit_template')}
    </CustomButton>
  );
};

const CustomTemplate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'settings']);
  const dataSettings = useSelector(dataSettingsSelector);
  const [overlay, overlayStatus] = apiCaller.useActiveSettingOverlayMutation();

  const handleChangeStatusOverlay = useCallback(() => {
    overlay({
      showOverlayByPass: !dataSettings?.settings.user.showOverlayByPass,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
  }, [overlay, dataSettings?.settings.user.showOverlayByPass, dispatch, t]);

  return (
    <div style={{ height: 'fit-content' }}>
      <SettingSwitch
        trackAction="setting_loading_page_effect"
        title={t('settings:loading_page_effect')}
        minActivePlan={UserPlan.FREE}
        settingToggleProps={{
          action: { onAction: handleChangeStatusOverlay, loading: overlayStatus.isLoading },
          enabled: dataSettings?.settings.user.showOverlayByPass,
        }}
      >
        <RegularText>
          {t('settings:turn_off_overlay_effect')}{' '}
          <Link url="https://docs.ipblocker.io/getting-started/loading-effect-of-blocking-page" target="_blank" removeUnderline>
            {t('common:learn_more')}.
          </Link>
        </RegularText>
      </SettingSwitch>
      <div className="mt-16">
        <Card>
          <BlockPageStoreFront smallScreen={true} focusId={''} />
          <div className="d-flex flex-end mt-8">
            {config.embedded === '1' ? <EditTemplateBtnAppBridge /> : <EditTemplateBtn />}
          </div>
        </Card>
      </div>
    </div>
  );
};
export default memo(CustomTemplate);

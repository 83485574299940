import { BlockCheckoutType } from '@/constants/enum';
import { perPageOptions } from '@/constants/options';
import blockCheckoutSlice, { dataTableSelector } from '@/redux/slice/blockCheckout.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const BlockCheckoutFilter = () => {
  const { t } = useTranslation(['block_checkout', 'common']);
  const dispatch = useDispatch();

  const dataTable = useSelector(dataTableSelector);

  const optionsCriteria = [
    {
      value: BlockCheckoutType.Email,
      label: t('block_checkout:email'),
    },
    {
      value: BlockCheckoutType.EmailDomain,
      label: t('block_checkout:email_contain'),
    },
    {
      value: BlockCheckoutType.PhoneNumber,
      label: t('block_checkout:phone_number'),
    },
    {
      value: BlockCheckoutType.Name,
      label: t('block_checkout:name'),
    },
    {
      value: BlockCheckoutType.Country,
      label: t('block_checkout:country'),
    },
    {
      value: BlockCheckoutType.Address,
      label: t('block_checkout:address'),
    },
    {
      value: BlockCheckoutType.ZipCode,
      label: t('block_checkout:zip_code'),
    },
  ];

  const filters = [
    {
      key: 'criteria',
      label: t('common:criteria'),
      filter: (
        <ChoiceList
          title={t('common:criteria')}
          titleHidden
          choices={[{ label: t('common:all'), value: '' }, ...optionsCriteria]}
          selected={[dataTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              blockCheckoutSlice.actions.handleDataTable({
                ...dataTable,
                criteria: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: t('common:per_page'),
      filter: (
        <ChoiceList
          title={t('common:per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[dataTable.perPage]}
          onChange={(value) =>
            dispatch(
              blockCheckoutSlice.actions.handleDataTable({
                ...dataTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockCheckoutSlice.actions.handleDataTable({
            ...dataTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `${t('common:criteria')}: ${
        optionsCriteria.find((option) => option.value === dataTable.criteria)?.label || t('common:all')
      }`,
      hidden: !dataTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          blockCheckoutSlice.actions.handleDataTable({
            ...dataTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: t('common:record_per_page', { perPage: dataTable.perPage }),
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};

import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import { capitalizeFirstLetter } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import UserPlans from '@/hooks/userPlans';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { Select } from '@shopify/polaris';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const Agents = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const useError = useErrorRule();
  const blockRuleSelected = useSelector(settingSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { userPlanFree } = UserPlans();
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });
  const dataUserAgent = apiCaller.useGetUserAgentQuery(undefined, { skip: isSkip });
  const deviceOptions = dataUserAgent.data?.userAgents.devices.map((item) => ({
    value: item,
    label: capitalizeFirstLetter(item),
  }));

  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | string[]) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };
  return (
    <div>
      <AutoCompleteWithTag
        requiredIndicator
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label={t('common:select_country')}
        selectedOptions={blockRuleSelected?.country || []}
        placeholder={`-${t('common:country')}-`}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('country')(value);
        }}
        allowMultiple={false}
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          }
        }}
        disable={userPlanFree}
      />
      <div className="mt-8" />

      <AutoCompleteWithTag
        requiredIndicator
        helpText={!blockRuleSelected?.id ? t('common:select_multiple_browsers') : undefined}
        allowMultiple={!blockRuleSelected?.id}
        label={t('common:select_browsers')}
        options={dataUserAgent.data?.userAgents.browsers.map((item) => ({ value: item, label: item })) || []}
        selectedOptions={blockRuleSelected?.browserName || []}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule(t('common:browser_must_be_added'));
          } else if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('browserName')(value);
        }}
        placeholder={`-${t('common:browsers')}-`}
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.browserName?.length === 0) {
            useError.handleErrorRule(t('common:browser_must_be_added'));
          }
        }}
        disable={userPlanFree}
      />
      <div className="mt-8" />

      <Select
        label={t('common:select_device_option')}
        options={deviceOptions}
        onChange={handleChange('deviceType')}
        value={blockRuleSelected.deviceType}
        placeholder={`-${t('common:device')}-`}
        disabled={userPlanFree}
      />
      <div className="mt-8" />

      <Select
        label={t('common:select_os_option')}
        options={dataUserAgent.data?.userAgents.os}
        onChange={handleChange('osName')}
        value={blockRuleSelected.osName}
        placeholder={`-${t('common:os')}-`}
        disabled={userPlanFree}
      />
    </div>
  );
};

export default memo(Agents);

// import bookCall from '@/asset/images/bookCall.png';
import CustomButton from '@/components/CustomButton';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { Enum, PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType, IpRangeType, ListType, SubKeyHelpCenter } from '@/constants/enum';
import options from '@/constants/options';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import blockListSlice, {
  blackListTableSelector,
  openGuideSelector,
  settingBockListInitial,
  settingSelector,
  tabSelectedModalSelector,
  tabSelectedSelector,
  whiteListTableSelector,
} from '@/redux/slice/blockList.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  Card,
  Divider,
  IndexFilters,
  IndexFiltersMode,
  InlineGrid,
  LegacyCard,
  Modal,
  Tabs,
  Text,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';

import { images } from '@/asset';
import BannerLimitRules from '@/components/BannerLimitRules';
import BannerReviewApp from '@/components/BannerReviewApp';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import helpCenterSlice from '@/redux/slice/helpCenter.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { DeleteIcon } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';

import { TFunction } from 'i18next';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { BlackListFilter } from './components/Filters/Filter.blacklist';
import { WhiteListFilter } from './components/Filters/Filter.whitelist';
import TableBlackList from './components/TableBlackList';
import TableWhiteList from './components/TableWhiteList';
import { BlockListStyled } from './styled';

const selectedRuleConfig = (t: TFunction) => [
  {
    value: Enum.ActionType.Block,
    label: t('common:block'),
  },
  {
    value: Enum.ActionType.Redirect,
    label: t('common:redirect'),
  },
  {
    value: '0',
    label: t('common:whitelist'),
  },
];

const tabsConfig = (t: TFunction) => [
  {
    id: 'black-list',
    content: t('common:blacklist'),
    panelID: 'black-list',
    children: <TableBlackList />,
  },
  {
    id: 'white-list',
    panelID: 'white-list',
    content: t('common:whitelist'),
    children: <TableWhiteList />,
  },
];

const BlockList = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { userPlanFree } = userPlans();
  const { t } = useTranslation(['common', 'block_redirect']);

  const tabSelected = useSelector(tabSelectedSelector);
  const tabSelectedModal = useSelector(tabSelectedModalSelector);
  const settings = useSelector(settingSelector);
  const isOpenGuide = useSelector(openGuideSelector);
  const banner = useSelector(bannerSelector);
  const blacklistTable = useSelector(blackListTableSelector);
  const whitelistTable = useSelector(whiteListTableSelector);

  // Start table
  const { mode, setMode } = useSetIndexFiltersMode();
  const { filters: filtersBlackList, appliedFilters: appliedFiltersBlackList } = BlackListFilter();
  const { filters: filtersWhiteList, appliedFilters: appliedFiltersWhiteList } = WhiteListFilter();
  const filters = !tabSelected ? filtersBlackList : filtersWhiteList;
  const appliedFilters = !tabSelected ? appliedFiltersBlackList : appliedFiltersWhiteList;
  const [inputSearch, setInputSearch] = useState('');
  // End table

  const [state, setState] = useState({
    isOpenModalDelete: false,
  });
  const [clearSelectedChildFn, setClearSelectedChildFn] = useState<(() => void) | null>(null);

  const [trackAction] = apiCaller.useTrackActionMutation();
  const [deleteAllBlacklistItem, deleteAllBlacklistItemStatus] = apiCaller.useDeleteAllBlackListSettingMutation();
  const [deleteAllWhitelistItem, deleteAllWhitelistItemStatus] = apiCaller.useDeleteAllWhiteListSettingMutation();

  const listRuleOptions = options
    .configRules(t)
    .filter(
      (rule) =>
        rule.id !== CriteriaType.IpAddressStartWith &&
        rule.id !== CriteriaType.IpRanges &&
        rule.id !== CriteriaType.Province &&
        rule.id !== CriteriaType.Collection,
    );

  const getDisputeTemplate = () => {
    trackAction('get_dispute_template');
    const sendText =
      'I am using the Blockify app. I saw they introduced their partner Synctrack, I want to get Synctrack Dispute Management Ebook';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSupportInstalledCustomer = () => {
    window.open(`https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp`);
  };

  const handleOpenModalDeleteAll = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isOpenModalDelete: true,
    }));
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: false,
    });
  }, [state]);

  const onParentAction = useCallback((action: () => void) => {
    setClearSelectedChildFn(() => action);
  }, []);

  const handleDeleteAllBlacklist = useCallback(() => {
    deleteAllBlacklistItem({
      ids: undefined,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        clearSelectedChildFn?.();
        handleCloseModalDelete();
      }
    });
  }, [clearSelectedChildFn, deleteAllBlacklistItem, dispatch, handleCloseModalDelete, t]);

  const handleDeleteAllWhitelist = useCallback(() => {
    deleteAllWhitelistItem({
      ids: undefined,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        clearSelectedChildFn?.();
        handleCloseModalDelete();
      }
    });
  }, [clearSelectedChildFn, deleteAllWhitelistItem, dispatch, handleCloseModalDelete, t]);

  const handleModalClick = useCallback(
    (id: string, subKey?: SubKeyHelpCenter) => {
      const type = options.listTypeOptions(t)[tabSelectedModal].id;
      if (type === ActionType.Redirect) {
        dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.Redirect));
      } else if (type === ActionType.WhiteList) {
        if (id === CriteriaType.Country) {
          dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.WhiteListLocation));
        } else {
          dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.WhiteList));
        }
      } else if (subKey) {
        dispatch(helpCenterSlice.actions.handleSubKey(subKey));
      }
      dispatch(slice.blockListSlice.actions.handleErrorRule([]));
      if (id === CriteriaType.ISP) {
        dispatch(blockListSlice.actions.handleInputIsp(''));
      }
      if (id === CriteriaType.Product) {
        dispatch(blockListSlice.actions.handleInputProduct(''));
        dispatch(blockListSlice.actions.handleInputCollection(''));
      }

      navigate(PATH.BLOCK_PAGE);

      const payload = {
        ...settingBockListInitial,
        criteria: id,
        type: options.listTypeOptions(t)[tabSelectedModal].id,
        priority:
          options.listTypeOptions(t)[tabSelectedModal].id === ActionType.WhiteList ? ListType.WhiteList : ListType.BlackList,
        ipRangeType: IpRangeType.CIDR,
      };

      dispatch(slice.blockListSlice.actions.handleSetting(payload));
      dispatch(slice.blockListSlice.actions.handleSettingBackup(payload));
    },
    [dispatch, navigate, t, tabSelectedModal],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchBlack = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleBlackListTable(value));
    }, 1000),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchWhite = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleWhiteListTable(value));
    }, 1000),
    [],
  );

  const handleInputChangeTable = useCallback(
    (search: string) => {
      setInputSearch(search);
      if (!tabSelected) {
        debounceSetSearchBlack({
          ...blacklistTable,
          page: search ? 1 : blacklistTable.page,
          search,
        });
      } else {
        debounceSetSearchWhite({
          ...whitelistTable,
          page: search ? 1 : blacklistTable.page,
          search,
        });
      }
    },
    [blacklistTable, debounceSetSearchBlack, debounceSetSearchWhite, tabSelected, whitelistTable],
  );

  const handleSelectListType = useCallback(
    (selected: number) => {
      dispatch(blockListSlice.actions.handleTabSelectedModal(selected));
      const type = options.listTypeOptions(t)[selected].id;
      dispatch(
        blockListSlice.actions.handleSetting({
          ...settings,
          type: type,
          priority: type === ActionType.WhiteList ? ListType.WhiteList : ListType.BlackList,
        }),
      );
      handleInputChangeTable('');
    },
    [dispatch, handleInputChangeTable, settings, t],
  );

  const handleClearAllFilter = useCallback(() => {
    if (!tabSelected) {
      dispatch(
        slice.blockListSlice.actions.handleBlackListTable({
          ...blacklistTable,
          perPage: '10',
          criteria: '',
          type: '',
        }),
      );
    } else {
      dispatch(
        slice.blockListSlice.actions.handleWhiteListTable({
          ...whitelistTable,
          perPage: '10',
          criteria: '',
        }),
      );
    }
  }, [blacklistTable, dispatch, tabSelected, whitelistTable]);

  return (
    <Layout
      layoutProps={{
        title: t('block_redirect:block_redirect'),
      }}
    >
      <BannerReviewApp />

      <BlockListStyled isOpenGuide={isOpenGuide}>
        {banner.bannerLimitRuleBlockAndRedirect && userPlanFree && (
          <div className="mb-16">
            <BannerLimitRules
              onDismiss={() => {
                dispatch(
                  handleVisibleBanner({
                    key: 'bannerLimitRuleBlockAndRedirect',
                    value: false,
                  }),
                );
              }}
              mixpanelName="Setting_rule_increase_limit"
            />
          </div>
        )}
        <div
          className="blocklist-wrapper"
          onClick={() => {
            dispatch(blockListSlice.actions.handleOpenGuide(false));
          }}
        />
        <div className="add-rule-wrapper">
          <Card padding="200">
            <Tabs tabs={options.listTypeOptions(t)} selected={tabSelectedModal} onSelect={handleSelectListType}>
              <Divider />
              {tabSelectedModal !== 3 && tabSelectedModal !== 4 ? (
                <div style={{ padding: isMobile ? '0.5rem' : '1rem' }}>
                  <InlineGrid columns={{ xs: 4, lg: 7 }} gap={{ xs: '100', md: '200' }}>
                    {listRuleOptions.map((item, index) => {
                      return (
                        <Card key={index} padding={'0'}>
                          <div
                            key={index}
                            className="add-rule-container"
                            onClick={() => handleModalClick(item.id, item.subKeyHelpCenter)}
                          >
                            <img className="add-rule-img" src={item.src} alt={item.description} />
                            <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                              {`${
                                selectedRuleConfig(t).find(
                                  (item) => item.value === options.listTypeOptions(t)[tabSelectedModal].id,
                                )?.label
                              } ${item.description}`}
                            </Text>
                          </div>
                        </Card>
                      );
                    })}
                  </InlineGrid>
                </div>
              ) : null}
              {tabSelectedModal === 3 && (
                <div className="pd-8">
                  <BannerTemplate
                    src={images.hp_banner}
                    isOpen={banner.hidePaymentMethod}
                    onDismiss={() => {
                      dispatch(
                        handleVisibleBanner({
                          key: 'hidePaymentMethod',
                          value: false,
                        }),
                      );
                    }}
                  >
                    <BoldText>{t('block_redirect:hide_payment_methods_unsupported_countries')}</BoldText>
                    <div className="mt-8">
                      <RegularText>{t('block_redirect:blockify_checkout_app')}</RegularText>
                    </div>
                    <div className="mb-4 mt-8" onClick={handleSupportInstalledCustomer}>
                      <CustomButton>{t('block_redirect:set_up_now')}</CustomButton>
                    </div>
                  </BannerTemplate>
                </div>
              )}
              {tabSelectedModal === 4 && (
                <div className="pd-8">
                  <BannerTemplate
                    src={images.hs_banner}
                    isOpen={banner.hideShippingMethod}
                    onDismiss={() => {
                      dispatch(
                        handleVisibleBanner({
                          key: 'hideShippingMethod',
                          value: false,
                        }),
                      );
                    }}
                  >
                    <BoldText>{t('block_redirect:hide_shipping_methods_based_on_location')}</BoldText>
                    <div className="mt-8">
                      <RegularText>{t('block_redirect:checkout_control_app')}</RegularText>
                    </div>
                    <div className="mb-4 mt-8" onClick={handleSupportInstalledCustomer}>
                      <CustomButton>{t('block_redirect:set_up_now')}</CustomButton>
                    </div>
                  </BannerTemplate>
                </div>
              )}
            </Tabs>
          </Card>
        </div>

        <div className="card-table">
          {mode === IndexFiltersMode.Filtering ? null : (
            <div className="btn-delete">
              <Tooltip content={t('common:delete_all')}>
                <CustomButton icon={DeleteIcon} onClick={handleOpenModalDeleteAll} />
              </Tooltip>
            </div>
          )}

          <LegacyCard>
            <IndexFilters
              queryValue={inputSearch}
              queryPlaceholder={t('common:searching_by_ip_and_location')}
              onQueryChange={handleInputChangeTable}
              onQueryClear={() => {
                handleInputChangeTable('');
              }}
              tabs={tabsConfig(t)}
              selected={tabSelected}
              onSelect={(tabIndex) => {
                dispatch(slice.blockListSlice.actions.handleTabSelected(tabIndex));
              }}
              canCreateNewView={false}
              filters={filters}
              appliedFilters={appliedFilters}
              onClearAll={handleClearAllFilter}
              cancelAction={{ onAction: () => {} }}
              mode={mode}
              setMode={setMode}
            />
            {!tabSelected ? (
              <TableBlackList onParentAction={onParentAction} />
            ) : (
              <TableWhiteList onParentAction={onParentAction} />
            )}
          </LegacyCard>
        </div>

        <div className="mt-16">
          <BannerTemplate
            src={images.disputeImg}
            isOpen={banner.bannerDispute}
            onDismiss={() =>
              dispatch(
                handleVisibleBanner({
                  key: 'bannerDispute',
                  value: false,
                }),
              )
            }
          >
            <BoldText>{t('block_redirect:win_dispute_guidelines')}</BoldText>
            <div className="mt-8">
              <RegularText>{t('block_redirect:dispute_management_ebook')}</RegularText>
            </div>
            <div className="mt-16">
              <CustomButton onClick={getDisputeTemplate}>{t('block_redirect:contact_get_free_ebook')}</CustomButton>
            </div>
          </BannerTemplate>
        </div>

        <Modal
          open={state.isOpenModalDelete}
          onClose={handleCloseModalDelete}
          title={t('common:delete_all')}
          primaryAction={{
            destructive: true,
            content: t('common:delete'),
            onAction: tabSelected === Enum.SelectedTab.Blacklist ? handleDeleteAllBlacklist : handleDeleteAllWhitelist,
            loading: deleteAllBlacklistItemStatus.isLoading || deleteAllWhitelistItemStatus.isLoading,
          }}
          secondaryActions={[
            {
              content: t('common:cancel'),
              onAction: handleCloseModalDelete,
            },
          ]}
        >
          <Modal.Section>
            <RegularText>{t('common:deleted_rules_cannot_revert')}</RegularText>
          </Modal.Section>
        </Modal>
      </BlockListStyled>
    </Layout>
  );
};
export default memo(BlockList);

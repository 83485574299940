import BoldText from '@/components/BoldText';
import CustomDatePicker from '@/components/datePicker';
import { PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import homeSlice, { datePickerSelector, titleBtnDatePickerSelector } from '@/redux/slice/home.slice';
import { Badge, BlockStack, Button, Card, InlineGrid, InlineStack, Text } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OverviewStyled } from './styled';
import { useTranslation } from 'react-i18next';

function Overview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(["dashboard", "common"]);
  const { userPlanFree } = userPlans();

  const datePicker = useSelector(datePickerSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const [trackAction] = apiCaller.useTrackActionMutation();
  const getOverview = apiCaller.useGetOverviewQuery(
    {
      startTime: dateToTimeStamp(datePicker.startDate).toString(),
      endTime: dateToTimeStamp(datePicker.endDate).toString(),
    },
    { skip: isSkip },
  );
  const {
    fraudOrderChange = 0,
    fraudOrderCount = 0,
    fraudVisitorChange = 0,
    fraudVisitorCount = 0,
  } = getOverview.data?.overview?.overview ?? {};

  const handleSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(
      homeSlice.actions.handleDatePicker({
        ...datePicker,
        startDate,
        endDate,
      }),
    );
  };

  const handleSaveTitleBtnDatePicker = (title: string) => {
    dispatch(homeSlice.actions.handleTitleBtnDatePicker(title));
  };

  const dataBlockBotList = [
    {
      label: t('proxy_and_vpn'),
      value: getOverview.data?.overview.settings.vpn,
    },
    {
      label: t('tor'),
      value: getOverview.data?.overview.settings.tor,
    },
    {
      label: t('protect_content'),
      value: getOverview.data?.overview.settings.protectContent,
    },
    {
      label: t('deactivate_right_click'),
      value: getOverview.data?.overview.settings?.rightClick,
    },
    {
      label: t('deactivate_inspect'),
      value: getOverview.data?.overview.settings.inspect,
    },
  ];

  return (
    <OverviewStyled>
      <Card>
        <InlineStack align="space-between">
          <BlockStack gap="150">
            <BoldText>{t('overview')}</BoldText>
            <Text as="p" tone="subdued" variant="headingMd">
              {t('tracked_by_blockify')}
            </Text>
          </BlockStack>

          <CustomDatePicker
            titleButton={titleBtn}
            setTitleButton={handleSaveTitleBtnDatePicker}
            startDate={datePicker.startDate}
            endDate={datePicker.endDate}
            onSave={handleSaveDatePicker}
            isShowSelectedTime={true}
            disabled={userPlanFree}
            conditions
          />
        </InlineStack>

        <div className="mt-16">
          <InlineGrid gap="300" columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
            <BlockStack gap="300">
              <Card>
                <BlockStack gap="300">
                  <Text as="h3">{t('total_fraud_visitors_detected')}</Text>
                  <InlineStack blockAlign="end" gap="300">
                    <Text as="h4" variant="heading2xl">
                      {fraudVisitorCount}
                    </Text>
                    <Text as="h4" tone={fraudVisitorChange > 0 ? 'critical' : fraudVisitorChange < 0 ? 'success' : 'disabled'}>
                      {fraudVisitorChange > 0 ? '+' : ''}
                      {fraudVisitorChange}%
                    </Text>
                  </InlineStack>
                  <InlineStack align="end" blockAlign="end">
                    <Button onClick={() => navigate(PATH.ANALYTICS)} variant="plain">
                      {t('view_full_analytics')}
                    </Button>
                  </InlineStack>
                </BlockStack>
              </Card>
              <Card>
                <BlockStack gap="300">
                  <Text as="h3">{t('total_fraud_order_detected')}</Text>
                  <InlineStack blockAlign="end" gap="300">
                    <Text as="h4" variant="heading2xl">
                      {fraudOrderCount}
                    </Text>
                    <Text as="h4" tone={fraudOrderChange > 0 ? 'critical' : fraudOrderChange < 0 ? 'success' : 'disabled'}>
                      {fraudOrderChange > 0 ? '+' : ''}
                      {fraudOrderChange}%
                    </Text>
                  </InlineStack>
                  <InlineStack align="end" blockAlign="end">
                    <Button onClick={() => navigate(PATH.FRAUD_ORDER)} variant="plain">
                      {t('view_full_analytics')}
                    </Button>
                  </InlineStack>
                </BlockStack>
              </Card>
            </BlockStack>

            <Card>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  {t('block_bot_content_protection')}
                </Text>
                {dataBlockBotList.map((data) => (
                  <InlineStack key={data.label} align="space-between" blockAlign="center">
                    <Text as="p">{data.label}</Text>

                    <Badge tone={data.value ? 'success' : 'attention'}>{data.value ? t('common:on') : t('common:off')}</Badge>
                  </InlineStack>
                ))}
                <div style={{ marginTop: '16px' }}>
                  <InlineStack align="end" blockAlign="end">
                    <Button
                      onClick={() => {
                        navigate(PATH.SETTINGS);
                        trackAction('block_bot_protect_content');
                      }}
                      variant="plain"
                    >
                      {t('view_settings')}
                    </Button>
                  </InlineStack>
                </div>
              </BlockStack>
            </Card>
          </InlineGrid>
        </div>
      </Card>
    </OverviewStyled>
  );
}

export default Overview;

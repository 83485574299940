import BannerGrantPermission from '@/components/BannerGrantPermission';
import BannerLimitRules from '@/components/BannerLimitRules';
import BoldText from '@/components/BoldText';
import CustomDatePicker from '@/components/datePicker';
import HelpCenter from '@/components/HelpCenter';
import Layout from '@/components/layout';
import ProcessOldOrders from '@/components/ProcessOldOrders';
import RegularText from '@/components/RegularText';
import ScrollInToView from '@/components/ScrollInToView';
import SettingToggle from '@/components/settingToggle';
import { config } from '@/config';
import { Enum } from '@/constants';
import { BREAKPOINT, FraudOrderAction, IDScrollIntoView, ScopeGroups, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, dateToTimeStamp, disablePlan, handleToastMutation } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector, titleBtnDatePickerSelector } from '@/redux/slice/fraudOrders';
import toastSlice from '@/redux/slice/toast.slice';
import {
  BlockStack,
  Box,
  Button,
  Checkbox,
  Collapsible,
  Icon,
  InlineGrid,
  InlineStack,
  Link,
  RadioButton,
  RangeSlider,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon, InfoIcon, OrderIcon, RefreshIcon } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Analytics from './Components/Analytics';
import Table from './Components/Table';
import { FraudOrdersStyled } from './styled';
import { IParamsApi } from '@/types/api/params.api';
import { useTranslation } from 'react-i18next';

const PrimaryAction = (): JSX.Element => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const getListOrder = apiCaller.useGetListOrderQuery(
    {
      endDate: dateToTimeStamp(fraudOrdersTable.endDate),
      startDate: dateToTimeStamp(fraudOrdersTable.startDate),
      ip: fraudOrdersTable.search,
      isVpn: fraudOrdersTable.vpn,
      page: fraudOrdersTable.page,
      perPage: Number(fraudOrdersTable.perPage),
      sortBy: fraudOrdersTable.sortBy,
      sortDirection: fraudOrdersTable.sort.toUpperCase(),
    },
    { skip: isSkip },
  );
  const getOrderSummary = apiCaller.useFraudOrdersSummaryQuery(
    {
      identifierId: config.shop,
      startDate: dateToTimeStamp(fraudOrdersTable.startDate),
      endDate: dateToTimeStamp(fraudOrdersTable.endDate),
    },
    { skip: isSkip },
  );
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(
      fraudOrdersSlice.actions.handleFraudOrdersTable({
        ...fraudOrdersTable,
        startDate,
        endDate,
      }),
    );
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(fraudOrdersSlice.actions.handleTitleBtnDatePicker(title));
  };
  const handleRefresh = () => {
    Promise.all([getListOrder.refetch(), getOrderSummary.refetch]);
  };
  return (
    <div className="d-flex mr-8">
      <Button loading={getListOrder.isFetching || getOrderSummary.isLoading} onClick={handleRefresh} icon={RefreshIcon}>
        {t('common:refresh')}
      </Button>
      <div className="ml-8">
        <CustomDatePicker
          titleButton={titleBtn}
          setTitleButton={onSaveTitleBtnDatePicker}
          startDate={fraudOrdersTable.startDate}
          endDate={fraudOrdersTable.endDate}
          onSave={onSaveDatePicker}
          isShowSelectedTime={true}
          conditions={false}
        />
      </div>
      {dataSettings?.settings.user.orderScope ? (
        <div className="ml-8">
          <Button
            onClick={() => {
              setIsOpenModal(true);
            }}
            icon={<Icon source={OrderIcon} tone="base" />}
          >
            {t('fraud_orders:analyze_old_orders')}
          </Button>
          <ProcessOldOrders isOpen={isOpenModal} handleIsOpen={(value) => setIsOpenModal(value)} />
        </div>
      ) : null}
    </div>
  );
};

const FraudOrders = () => {
  const { t } = useTranslation(['fraud_orders', 'common']);
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const banner = useSelector(bannerSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { userPlanFree, userPlanPremium, shopifyPlanPlus, userPlanEnterprise, userPlanShopifyPlus } = userPlans();
  const [activeFraudOrder, activeFraudOrderStatus] = apiCaller.useUpdateAutoCancelHighRiskOrderMutation();
  const [updateScope] = apiCaller.useLazyGetUrlUpdatePaymentScopeQuery();
  const { data } = apiCaller.useGetInfoAutoCancelHighRiskOrderQuery(undefined, { skip: isSkip });
  const [buttonClick, setButtonClick] = useState('');
  const [openFilter, setOpenFilter] = useState<boolean>(true);
  const [state, setState] = useState({
    enabledAutoBlockFraudOrder: false,
    enabledAutoCancelFraudOrder: false,
    enabledRestock: true,
    enabledSendBuyerNotice: true,
    enabledSendMerchantNotice: true,
    enabledAutoRefundCancelledOrder: false,
    orderRiskScore: 0,
    cancelHighRisk: true,
    cancelMediumRisk: false,
    deleteImmediately: false,
    orderAction: Enum.FraudOrderAction.CANCEL,
    tagHighRisk: true,
    tagMediumRisk: false,
    tagMerchantNotice: true,
  });

  const preState = useRef(state);

  // eslint-disable-next-line
  const debouncedUpdateOrderRiskScore = useCallback(
    _debounce((value) => {
      handleSave({ orderRiskScore: value });
    }, 500),
    [],
  );

  const handleRangeSliderChange = useCallback(
    (value: number) => {
      setState((prevState) => ({
        ...prevState,
        orderRiskScore: value,
      }));
      debouncedUpdateOrderRiskScore(value);
    },
    [debouncedUpdateOrderRiskScore],
  );

  // GRAND PERMISSION FOR BOTH READ & WRITE ORDERS
  const handleUpdateScope = () => {
    if (dataSettings) {
      updateScope({
        scopeGroups: ScopeGroups.processOrders,
      }).then((res) => {
        if (res.data?.url) {
          window.open(res.data.url, '_blank');
        }
      });
    }
  };

  const handleSave = useCallback(
    async (update: {
      enabledAutoBlockFraudOrder?: boolean;
      enabledAutoCancelFraudOrder?: boolean;
      enabledRestock?: boolean;
      enabledSendBuyerNotice?: boolean;
      enabledSendMerchantNotice?: boolean;
      enabledAutoRefundCancelledOrder?: boolean;
      orderRiskScore?: number;
      cancelHighRisk?: boolean;
      cancelMediumRisk?: boolean;
      deleteImmediately?: boolean;
      orderAction?: FraudOrderAction;
      tagHighRisk?: boolean;
      tagMediumRisk?: boolean;
      tagMerchantNotice?: boolean;
    }) => {
      try {
        let updateData: IParamsApi.IAutoCancelHighRiskOrder = {
          urlParams: config.urlParams,
          ...update,
        };
        const res = await activeFraudOrder(updateData);
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        }
        setButtonClick('3');
      } catch (err) {
        console.log(err);
      }
    },
    [activeFraudOrder, dispatch, t],
  );

  const handleAutoBlockFraudOrders = useCallback(() => {
    setButtonClick('1');
    handleSave({ enabledAutoBlockFraudOrder: !state.enabledAutoBlockFraudOrder });
  }, [handleSave, state.enabledAutoBlockFraudOrder]);

  const handleAutoCancelFraudOrders = useCallback(() => {
    setButtonClick('2');
    handleSave({ enabledAutoCancelFraudOrder: !state.enabledAutoCancelFraudOrder });
  }, [handleSave, state.enabledAutoCancelFraudOrder]);

  const handleChangeAutoCancelOrder = useCallback(
    (field: string) => (newChecked: boolean) => {
      const updatedData = {
        ...state,
        [field]: newChecked,
      };
      setState(updatedData);
      handleSave({ [field]: newChecked });
    },
    [handleSave, state],
  );

  const handleChangeFraudOrderAction = useCallback(
    (_: boolean, newValue: FraudOrderAction) => {
      setState((pre) => ({ ...pre, orderAction: newValue }));
      handleSave({ orderAction: newValue });
    },
    [handleSave],
  );

  useEffect(() => {
    if (data && data.data) {
      const temp = {
        enabledAutoBlockFraudOrder: data.data.enabledAutoBlockFraudOrder,
        enabledAutoCancelFraudOrder: data.data.enabledAutoCancelFraudOrder,
        enabledRestock: data.data.enabledRestock,
        enabledSendBuyerNotice: data.data.enabledSendBuyerNotice,
        enabledSendMerchantNotice: data.data.enabledSendMerchantNotice,
        orderRiskScore: Number(data.data.orderRiskScore) || 0,
        enabledAutoRefundCancelledOrder: data.data.enabledAutoRefundCancelledOrder,
        cancelHighRisk: data.data.cancelHighRisk,
        cancelMediumRisk: data.data.cancelMediumRisk,
        deleteImmediately: data.data.deleteImmediately,
        orderAction: data.data.orderAction,
        tagHighRisk: data.data.tagHighRisk,
        tagMediumRisk: data.data.tagMediumRisk,
        tagMerchantNotice: data.data.tagMerchantNotice,
      };
      setState({ ...temp });
      preState.current = { ...temp };
    }
  }, [data]);

  return (
    <Layout
      layoutProps={{
        title: t('fraud_orders:fraud_orders_analytics'),
        fullWidth: true,
        primaryAction: (
          <div className="d-flex">
            <HelpCenter />
          </div>
        ),
      }}
    >
      <FraudOrdersStyled>
        <BannerGrantPermission
          title={t('fraud_orders:important_step_to_use_feature')}
          isVisible={
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) ||
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
          }
          enum={ScopeGroups.processOrders}
          children={`${t('fraud_orders:permission_required')} ${
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) &&
            !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
              ? t('fraud_orders:view_and_edit')
              : t('fraud_orders:edit')
          } ${t('fraud_orders:your_store_orders_data')}.`}
          variant="primary"
        />
        {banner.fraudOrderBanner && userPlanFree && (
          <div className="mb-16">
            <BannerLimitRules
              onDismiss={() => {
                dispatch(
                  handleVisibleBanner({
                    key: 'fraudOrderBanner',
                    value: false,
                  }),
                );
              }}
              mixpanelName="Fraud_order_increase_limit"
            />
          </div>
        )}
        <div className="mb-16">
          <PrimaryAction />
        </div>

        <ScrollInToView idScrollToBlock={IDScrollIntoView.Auto_block_visitors_placing_fraud_orders}>
          <SettingToggle
            trackAction="fraud_order_auto_block_visitors"
            minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
            title={
              isMobile ? (
                <div className="d-flex">
                  {t('fraud_orders:auto_block_visitors')}
                  <Tooltip content={t('fraud_orders:auto_block_fraud_orders')}>
                    <div className="ml-4">
                      <Icon source={InfoIcon}></Icon>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                t('fraud_orders:auto_block_fraud_orders')
              )
            }
            settingToggleProps={{
              enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.enabledAutoBlockFraudOrder,
              action: {
                onAction: handleAutoBlockFraudOrders,
                loading: (activeFraudOrderStatus.isLoading || isLoading) && buttonClick === '1',
              },
            }}
            mixpanelKey="Fraud_order_button"
            contentTooltipWithLink={
              dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
                ? undefined
                : t('fraud_orders:grant_permission_feature')
            }
            onClick={handleUpdateScope}
            disabled={
              !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) &&
              !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)
            }
          >
            <RegularText>
              {t('fraud_orders:turn_on_fraud_feature')}{' '}
              <Link target="_blank" url="https://docs.ipblocker.io/getting-started/fraud-orders">
                {t('fraud_orders:learn_about_fraud_orders')}.
              </Link>
              {state.enabledAutoBlockFraudOrder &&
              (userPlanEnterprise || userPlanShopifyPlus) &&
              dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders) ? (
                <div className="mt-16 mb-16 range">
                  <RangeSlider
                    output
                    label={<RegularText>{t('fraud_orders:fraud_order_score_starts_at')}</RegularText>}
                    value={state.orderRiskScore}
                    suffix={
                      <p
                        style={{
                          minWidth: '24px',
                          textAlign: 'right',
                        }}
                      >
                        {state.orderRiskScore}
                      </p>
                    }
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={handleRangeSliderChange}
                  />
                  <div className="risk-score">
                    (
                    {state.orderRiskScore < 0.4
                      ? t('fraud_orders:low_risk')
                      : state.orderRiskScore < 0.7
                      ? t('fraud_orders:medium_risk')
                      : t('fraud_orders:high_risk')}
                    )
                  </div>
                </div>
              ) : null}
            </RegularText>
          </SettingToggle>
        </ScrollInToView>

        <ScrollInToView idScrollToBlock={IDScrollIntoView.Auto_cancel_high_risk_orders} className="mt-16">
          <SettingToggle
            trackAction="fraud_order_auto_cancel_high_risk_button"
            minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
            title={
              <BoldText>
                {isMobile ? (
                  <div className="d-flex">
                    {t('fraud_orders:fraud_filter')}
                    <Tooltip content={t('fraud_orders:fraud_order_filter')}>
                      <div className="ml-4">
                        <Icon source={InfoIcon}></Icon>
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  `${t('fraud_orders:fraud_filter')} (${t('fraud_orders:fraud_order_filter')})`
                )}
              </BoldText>
            }
            settingToggleProps={{
              enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.enabledAutoCancelFraudOrder,
              action: {
                onAction: handleAutoCancelFraudOrders,
                loading: (activeFraudOrderStatus.isLoading || isLoading) && buttonClick === '2',
              },
            }}
            mixpanelKey="Auto_cancel_high_risk_button"
            contentTooltipWithLink={
              dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders)
                ? undefined
                : t('fraud_orders:grant_permission_turn_on_feature')
            }
            onClick={handleUpdateScope}
            disabled={!dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders)}
            children={
              <RegularText>
                {t('fraud_orders:turn_on_fraud_filter')}{' '}
                <Link url={'https://docs.ipblocker.io/getting-started/fraud-orders/auto-cancel-high-risk-orders'} target="_blank">
                  {t('common:learn_more')}.
                </Link>
              </RegularText>
            }
            content={
              <Collapsible
                id="fraud-order"
                open={!disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.enabledAutoCancelFraudOrder}
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
              >
                <BlockStack gap={'300'}>
                  <div>
                    <Text variant="headingMd" as="h1">
                      {t('common:action')}
                    </Text>
                    <Text variant="bodyMd" as="p">
                      {t('fraud_orders:select_action_fraud_filter')}.
                    </Text>
                    <Box paddingInlineStart={'200'} paddingBlockStart={'100'}>
                      <RadioButton
                        label={t('fraud_orders:cancel_order')}
                        checked={state.orderAction === Enum.FraudOrderAction.CANCEL}
                        id={Enum.FraudOrderAction.CANCEL}
                        value={Enum.FraudOrderAction.CANCEL}
                        onChange={handleChangeFraudOrderAction}
                        helpText={t('fraud_orders:immediate_cancel_order')}
                      />
                      <RadioButton
                        label={t('fraud_orders:flag_order')}
                        id={Enum.FraudOrderAction.FLAG}
                        checked={state.orderAction === Enum.FraudOrderAction.FLAG}
                        value={Enum.FraudOrderAction.FLAG}
                        onChange={handleChangeFraudOrderAction}
                        helpText={t('fraud_orders:mark_order_as_fraud')}
                      />
                    </Box>
                  </div>
                  <InlineStack align="start" gap={'200'}>
                    <Text variant="headingMd" as="h1">
                      {t('fraud_orders:manage_filter')}
                    </Text>
                    {!openFilter ? (
                      <div style={{ cursor: 'pointer' }} onClick={() => setOpenFilter(true)}>
                        <Icon source={ChevronDownIcon} tone="base" />
                      </div>
                    ) : (
                      <div style={{ cursor: 'pointer' }} onClick={() => setOpenFilter(false)}>
                        <Icon source={ChevronUpIcon} tone="base" />
                      </div>
                    )}
                  </InlineStack>
                  <Collapsible
                    id="manage-filter"
                    open={openFilter}
                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                  >
                    {state.orderAction === Enum.FraudOrderAction.CANCEL ? (
                      <InlineGrid columns={!isMobile ? 2 : 1}>
                        <BlockStack gap={'200'}>
                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:order_type')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:high_risk_orders')}
                                checked={state.cancelHighRisk}
                                onChange={handleChangeAutoCancelOrder('cancelHighRisk')}
                              />
                              <Checkbox
                                label={t('fraud_orders:medium_risk_orders')}
                                checked={state.cancelMediumRisk}
                                onChange={handleChangeAutoCancelOrder('cancelMediumRisk')}
                              />
                            </BlockStack>
                          </div>
                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:inventory')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:re_stock_inventory_after_cancelling_orders')}
                                checked={state.enabledRestock}
                                onChange={handleChangeAutoCancelOrder('enabledRestock')}
                              />
                            </BlockStack>
                          </div>

                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:order_management')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:delete_order_immediately')}
                                checked={state.deleteImmediately}
                                onChange={handleChangeAutoCancelOrder('deleteImmediately')}
                              />
                            </BlockStack>
                          </div>
                        </BlockStack>
                        <BlockStack gap={'200'}>
                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:refund')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:auto_refund_on_order_cancellation')}
                                checked={state.enabledAutoRefundCancelledOrder}
                                onChange={handleChangeAutoCancelOrder('enabledAutoRefundCancelledOrder')}
                              />
                            </BlockStack>
                          </div>
                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:notification')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:send_cancellation_confirmation_email')}
                                checked={state.enabledSendBuyerNotice}
                                onChange={handleChangeAutoCancelOrder('enabledSendBuyerNotice')}
                              />
                              <Checkbox
                                label={t('fraud_orders:send_cancellation_notice')}
                                checked={state.enabledSendMerchantNotice}
                                onChange={handleChangeAutoCancelOrder('enabledSendMerchantNotice')}
                              />
                            </BlockStack>
                          </div>
                        </BlockStack>
                      </InlineGrid>
                    ) : (
                      <InlineGrid columns={!isMobile ? 2 : 1}>
                        <BlockStack gap={'200'}>
                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:order_type')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:high_risk_orders')}
                                checked={state.tagHighRisk}
                                onChange={handleChangeAutoCancelOrder('tagHighRisk')}
                              />
                              <Checkbox
                                label={t('fraud_orders:medium_risk_orders')}
                                checked={state.tagMediumRisk}
                                onChange={handleChangeAutoCancelOrder('tagMediumRisk')}
                              />
                            </BlockStack>
                          </div>
                        </BlockStack>
                        <BlockStack gap={'200'}>
                          <div className="cancel-order-checkbox">
                            <Text variant="headingXs" as="h2">
                              {t('fraud_orders:notification')}
                            </Text>
                            <BlockStack>
                              <Checkbox
                                label={t('fraud_orders:send_notification_to_store_owners')}
                                checked={state.tagMerchantNotice}
                                onChange={handleChangeAutoCancelOrder('tagMerchantNotice')}
                              />
                            </BlockStack>
                          </div>
                        </BlockStack>
                      </InlineGrid>
                    )}
                  </Collapsible>
                </BlockStack>
              </Collapsible>
            }
          />
        </ScrollInToView>

        <div className="mt-16">
          <div>
            <Analytics />
          </div>
        </div>

        <div className="mt-16">
          <div>
            <Table />
          </div>
        </div>
      </FraudOrdersStyled>
    </Layout>
  );
};

export default memo(FraudOrders);

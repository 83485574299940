import { sendMessageCrisp } from '@/helpers';
import { subKeySelector } from '@/redux/slice/helpCenter.slice';
import { Button, Card, Modal } from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import BoldText from '../BoldText';
import RegularText from '../RegularText';
import { Key, config } from './config';
import { useTranslation } from 'react-i18next';

const HelpCenter = () => {
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState(false);
  const subKey = useSelector(subKeySelector);
  const { pathname } = useLocation();
  const type = pathname.split('/')[1] as Key;
  const settings = {
    dots: config(t, type, subKey).length > 2,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(2, config(t, type, subKey).length),
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Button
        icon={QuestionCircleIcon}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('common:help_center')}
      </Button>
      <Modal
        open={isOpen}
        title={t('common:help_center')}
        onClose={() => {
          setIsOpen(false);
        }}
        footer
        secondaryActions={[
          {
            content: t('common:contact_support'),
            onAction: () => {
              sendMessageCrisp('Hi, I need to help set up Blockify');
              setIsOpen(false);
            },
          },
        ]}
        primaryAction={{
          content: t('common:done'),
          onAction: () => {
            setIsOpen(false);
          },
        }}
      >
        <div className={'help-center-content'}>
          <Slider {...settings}>
            {config(t, type, subKey).map((item) => {
              return (
                <Card padding={'0'} key={item.title}>
                  <div className="help-center-slide-container">
                    <div className="help-center-text">
                      <BoldText>{item.title}</BoldText>
                      <div>
                        <RegularText>{item.content}</RegularText>
                      </div>
                    </div>
                    <div className="help-center-btn">
                      <Button url={item.url} target="_blank">
                        {t('common:see_detail')}
                      </Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Slider>
        </div>
      </Modal>
    </>
  );
};

export default memo(HelpCenter);

import { BlockCheckoutType } from '@/constants/enum';
import { TFunction } from 'i18next';

export const conditionConfig = (t: TFunction) => [
  {
    value: [BlockCheckoutType.EmailDomain, BlockCheckoutType.Email],
    label: t('block_checkout:email'),
    labelFieldValue: t('block_checkout:enter_email_address'),
  },
  {
    value: [BlockCheckoutType.PhoneNumber],
    label: t('block_checkout:phone_number'),
    labelFieldValue: t('block_checkout:enter_phone_number'),
  },
  {
    value: [BlockCheckoutType.Name],
    label: t('block_checkout:custom_name'),
    labelFieldValue: t('block_checkout:enter_name'),
  },
  {
    value: [BlockCheckoutType.Country],
    label: t('block_checkout:specific_country'),
    labelFieldValue: t('block_checkout:select_country'),
  },

  {
    value: [BlockCheckoutType.Address],
    label: t('block_checkout:custom_address'),
    labelFieldValue: t('block_checkout:enter_address'),
  },
  {
    value: [BlockCheckoutType.ZipCode],
    label: t('block_checkout:zip_code'),
    labelFieldValue: t('block_checkout:enter_zip_code'),
  },
];

export const conditionOptions = (t: TFunction) => [
  {
    value: BlockCheckoutType.Email,
    label: t('block_checkout:email'),
  },
  {
    value: BlockCheckoutType.PhoneNumber,
    label: t('block_checkout:phone_number'),
  },
  {
    value: BlockCheckoutType.Name,
    label: t('block_checkout:name'),
  },
  {
    value: BlockCheckoutType.Country,
    label: t('block_checkout:country'),
  },

  {
    value: BlockCheckoutType.Address,
    label: t('block_checkout:address'),
  },
  {
    value: BlockCheckoutType.ZipCode,
    label: t('block_checkout:zip_code'),
  },
];

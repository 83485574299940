import options, { perPageOptions } from '@/constants/options';
import blockListSlice, { whiteListTableSelector } from '@/redux/slice/blockList.slice';
import { ChoiceList, ChoiceListProps } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const WhiteListFilter = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const whiteListTable = useSelector(whiteListTableSelector);
  const filters = [
    {
      key: 'criteria',
      label: t('common:criteria'),
      filter: (
        <ChoiceList
          title={t('common:criteria')}
          titleHidden
          choices={[{ label: t('common:all'), value: '' }, ...options.criteriaFilters(t)] as ChoiceListProps['choices']}
          selected={[whiteListTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleWhiteListTable({
                ...whiteListTable,
                criteria: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: t('common:per_page'),
      filter: (
        <ChoiceList
          title={t('common:per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[whiteListTable.perPage]}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleWhiteListTable({
                ...whiteListTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleWhiteListTable({
            ...whiteListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `${t('common:criteria')}: ${
        options.criteriaFilters(t).find((option) => option.value === whiteListTable.criteria)?.label || t('common:all')
      }`,
      hidden: !whiteListTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleWhiteListTable({
            ...whiteListTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: t('common:record_per_page', { perPage: whiteListTable.perPage }),
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};

import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import blockedVisitorSlice, { modeFilterBlockTableSelector } from '@/redux/slice/blockedVisitor.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import visitorLogSlice, {
  allAccessSelector,
  modeVisitorLogTableSelector,
  tabsTableSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import { Banner, Card, IndexFilters, IndexFiltersMode, IndexFiltersProps, Link, Tooltip } from '@shopify/polaris';
import { ExportIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableBlocked from './BlockedTable';
import useConfigTable from './ConfigTable';
import TableVisitorLog from './VisitorAnalytics';
import { useTranslation } from 'react-i18next';

const TableAnalytics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const configTable = useConfigTable();
  const { t } = useTranslation(['visitor_analytics']);
  const modeAllowedTable = useSelector(modeVisitorLogTableSelector);
  const modeBlockedTable = useSelector(modeFilterBlockTableSelector);
  const banner = useSelector(bannerSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const selected = useSelector(tabsTableSelector);
  const allAccess = useSelector(allAccessSelector);
  const getVisitorQuota = apiCaller.useGetCheckQuotaQuery(undefined, { skip: !userPlanFree || isSkip });
  const totalVisitor = getVisitorQuota.data?.totalVisitor ?? 0;
  const dataSettings = useSelector(dataSettingsSelector);
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(slice.visitorAnalyticsSlice.actions.handleTableTab(selectedTabIndex)),
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = [
    {
      id: 'visitorLog',
      content: t('visitor_analytics:allowed_visitors'),
      component: <TableVisitorLog />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/export?shop=${config.shop}&urlParams=${JSON.stringify(
        config.urlParams,
      )}&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
      badge: userPlanFree && !dataSettings?.settings.enableVisitorAnalytics && selected === 0 ? t('visitor_analytics:demo') : '',
    },
    {
      id: 'blocked',
      content: t('visitor_analytics:blocked_visitors'),
      component: <TableBlocked />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/block/export?shop=${config.shop}&urlParams=${JSON.stringify(
        config.urlParams,
      )}&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
      badge: userPlanFree && !dataSettings?.settings.enableVisitorAnalytics && selected === 1 ? t('visitor_analytics:demo') : '',
    },
  ];

  const configFilter = useMemo(() => {
    const key = tabs[selected].id;
    const result = {
      ...configTable[key as keyof typeof configTable],
    };
    return result;
  }, [configTable, tabs, selected]);

  return (
    <div className="table-container">
      <Card padding={'100'}>
        <IndexFilters
          {...configFilter}
          sortOptions={configFilter.sortOptions as IndexFiltersProps['sortOptions']}
          cancelAction={{
            onAction: () => {},
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          mode={selected === 0 ? modeAllowedTable : modeBlockedTable}
          setMode={(mode) =>
            selected === 0
              ? dispatch(visitorLogSlice.actions.handleMode(mode))
              : dispatch(blockedVisitorSlice.actions.handleMode(mode))
          }
          canCreateNewView={false}
        />
        {userPlanFree &&
          banner.visitorAnalyticWarningTable &&
          dataSettings &&
          totalVisitor >= dataSettings.settings.user.numberVisitorLimit && (
            <Banner
              tone="warning"
              title=""
              onDismiss={() => {
                dispatch(
                  handleVisibleBanner({
                    key: 'visitorAnalyticWarningTable',
                    value: false,
                  }),
                );
              }}
            >
              <RegularText>
                {t('visitor_analytics:visitor_data_paused_free_limit', {
                  total: totalVisitor,
                  limit: dataSettings.settings.user.numberVisitorLimit,
                })}
                . <Link onClick={() => navigate(PATH.PRICING_PLAN)}>{t('visitor_analytics:increase_limit')}</Link>{' '}
                {t('visitor_analytics:continue_recording_visitor_data')}
              </RegularText>
            </Banner>
          )}
        <div
          className="export-btn"
          style={{
            display:
              modeAllowedTable === IndexFiltersMode.Default || modeBlockedTable === IndexFiltersMode.Default ? 'block' : 'none',
          }}
        >
          <Tooltip content={t('visitor_analytics:export_csv')}>
            <CustomButton icon={ExportIcon} url={tabs[selected].exportUrl} target="_blank" />
          </Tooltip>
        </div>
        {tabs[selected].component}
      </Card>
    </div>
  );
};

export default memo(TableAnalytics);

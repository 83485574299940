import BoldText from '@/components/BoldText';
import { config } from '@/config';
import { OrderRisk } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector } from '@/redux/slice/fraudOrders';
import { Card, Icon, InlineGrid, Text, Tooltip } from '@shopify/polaris';
import { AlertCircleIcon, AlertDiamondIcon, OrderIcon } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Analytics = () => {
  const { t } = useTranslation(['fraud_orders']);
  const dispatch = useDispatch();

  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const { data } = apiCaller.useFraudOrdersSummaryQuery(
    {
      identifierId: config.shop,
      startDate: dateToTimeStamp(fraudOrdersTable.startDate),
      endDate: dateToTimeStamp(fraudOrdersTable.endDate),
    },
    { skip: isSkip },
  );

  const dataChart = useMemo(() => {
    const result = [
      {
        title: t('fraud_orders:total_order'),
        value: undefined,
        total: data?.data.total || 0,
        icon: OrderIcon,
      },
      {
        title: t('fraud_orders:total_low_risk_order'),
        value: OrderRisk.Low,
        total: data?.data.lowRisk || 0,
        icon: OrderIcon,
      },

      {
        title: t('fraud_orders:total_medium_risk_order'),
        value: OrderRisk.Medium,
        total: data?.data.mediumRisk || 0,
        icon: AlertDiamondIcon,
      },
      {
        title: t('fraud_orders:total_high_risk_order'),
        value: OrderRisk.High,
        total: data?.data.highRisk || 0,
        icon: AlertCircleIcon,
      },
    ];
    return result;
  }, [data?.data?.highRisk, data?.data?.lowRisk, data?.data?.mediumRisk, data?.data?.total, t]);

  const handleChangeFilter = (value: any) => {
    dispatch(fraudOrdersSlice.actions.handleFraudOrdersTable({ ...fraudOrdersTable, risk: value, page: 1 }));
  };

  return (
    <Card>
      <BoldText>{t('fraud_orders:overview')}</BoldText>
      <div className="analytics-container mt-16">
        <InlineGrid columns={{ xl: 4, lg: 4, md: 4, sm: 2, xs: 2 }} gap={'400'}>
          {dataChart.map((item, index) => {
            const isClickable = item.total > 0;
            return (
              <Card key={index}>
                <div
                  className={isClickable ? 'analytics-items-title' : 'analytics-items-title-default'}
                  onClick={
                    isClickable
                      ? () => {
                          dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(index));
                          handleChangeFilter(item.value);
                        }
                      : undefined
                  }
                >
                  <Icon source={item.icon} tone="base" />
                  {isClickable ? (
                    <Tooltip content={t('fraud_orders:view_list_detail')}>
                      <Text fontWeight="medium" as="h5" variant="headingSm">
                        {item.title}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text fontWeight="medium" as="h5" variant="headingSm">
                      {item.title}
                    </Text>
                  )}
                </div>
                <div className="mt-8 ml-4">
                  <Text as="h5" variant="heading2xl" fontWeight="regular">
                    {item.total}
                  </Text>
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      </div>
    </Card>
  );
};

export default memo(Analytics);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { config } from './config';

const isProduction = process.env.NODE_ENV === 'production';
const i18nKey = 'i18nextLng-' + config.shop;

export enum Language {
  English = 'en',
  Vietnamese = 'vi',
  Hindi = 'hi',
  Portuguese = 'pt',
  Spanish = 'es',
  French = 'fr',
  Italian = 'it',
}

export const supportLanguages = [
  {
    label: 'English (default)',
    value: Language.English,
  },
  // {
  //   label: 'Vietnamese',
  //   value: Language.Vietnamese,
  // },
  {
    label: 'हिन्दी (Hindi)',
    value: Language.Hindi,
  },
  {
    label: 'Português',
    value: Language.Portuguese,
  },
  {
    label: 'Español',
    value: Language.Spanish,
  },
  {
    label: 'Français',
    value: Language.French,
  },
  {
    label: 'Italiano',
    value: Language.Italian,
  },
];

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'common',
    ns: [
      'common',
      'dashboard',
      'visitor_analytics',
      'block_redirect',
      'fraud_orders',
      'block_checkout',
      'settings',
      'pricing',
      'modal_search',
    ],
    fallbackNS: 'common',
    supportedLngs: [
      Language.English,
      // Language.Vietnamese,
      Language.Hindi,
      Language.Portuguese,
      Language.Spanish,
      Language.French,
      Language.Italian,
    ],
    fallbackLng: Language.English,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: i18nKey,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_END_POINT}locales/{{lng}}/{{ns}}.json`,
      allowMultiLoading: true,
      crossDomain: true,
    },
    saveMissing: !isProduction,
    preload: [Language.English],
  });

const savedLang = localStorage.getItem(i18nKey);

if (!savedLang) {
  i18n.changeLanguage(Language.English);
  localStorage.setItem(i18nKey, Language.English);
}

export default i18n;

import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { Enum, PATH } from '@/constants';
import { urlRickScore } from '@/constants/link';
import { dateToTimeStamp, findIPRange, formatDate } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockedVisitorSlice, { blockedDetailSelector, blockHistorySelector } from '@/redux/slice/blockedVisitor.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessSelector, perPageSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Badge, BadgeProps, Icon, IndexTable, Link, Pagination, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import { DuplicateIcon } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TableBlocked = (): JSX.Element => {
  const { t } = useTranslation(['common', 'visitor_analytics']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCountry = useCountry();
  const blockHistoryTable = useSelector(blockHistorySelector);
  const blockedDetail = useSelector(blockedDetailSelector);
  const allAccess = useSelector(allAccessSelector);
  const perPage = useSelector(perPageSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const { userPlanFree } = userPlans();

  const { data, isFetching } = apiCaller.useFetchVisitorBlockListQuery(
    {
      ...blockHistoryTable,
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      perPage,
    },
    { skip: isSkip },
  );

  const resourceName = {
    singular: 'blocked',
    plural: 'blocked',
  };

  const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = useMemo(
    () => [
      {
        score: [-1, Enum.RiskLevelScore.Safe],
        tone: 'success',
        tooltip: t('visitor_analytics:low_risk_ip'),
      },
      {
        score: [Enum.RiskLevelScore.Safe, Enum.RiskLevelScore.Risky],
        tone: 'warning',
        tooltip: t('visitor_analytics:high_risk_ip'),
      },
      {
        score: [Enum.RiskLevelScore.Risky, Enum.RiskLevelScore.Dangerous],
        tone: 'critical',
        tooltip: t('visitor_analytics:dangerous_ip'),
      },
    ],
    [t],
  );

  const items = useMemo(() => {
    return data?.listIp?.map((blockedItem) => {
      const handleCopyIPAdress = () => {
        navigator.clipboard.writeText(blockedItem.ip || '').then(() => {
          dispatch(
            slice.toastSlice.actions.handleToast({
              isOpen: true,
              content: t('common:copied'),
              error: false,
            }),
          );
        });
      };
      const riskScore = blockedItem.risk;
      const isVpn: boolean = blockedItem.isVpn;

      let badgeRisk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }
      return {
        id: blockedItem.id,
        ipBlocked: (
          <span className="d-flex address-container">
            <RegularText>
              {blockedItem.ip}
              <br />
              {blockedItem.ipv4 ? (
                <>
                  <Text variant="bodySm" as="span">
                    IPv4: {blockedItem.ipv4}
                  </Text>
                  <br />
                </>
              ) : null}
              {findIPRange(blockedItem.ip) !== null ? (
                <Text variant="bodySm" as="span">
                  {`${t('common:ip_range')}: ${findIPRange(blockedItem.ip)}`}
                </Text>
              ) : null}
            </RegularText>
            <span className="ml-8 pointer">
              <Tooltip content={t('common:copy')}>
                <span
                  className="btn-copy"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyIPAdress();
                  }}
                >
                  <Icon source={DuplicateIcon} />
                </span>
              </Tooltip>
            </span>
          </span>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(blockedItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={blockedItem.countryCode} />
          </Tooltip>
        ),
        city: <RegularText>{[blockedItem.regionName, blockedItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>,
        totalAccess: (
          <p style={{ width: '4rem' }}>
            <RegularText>
              <Link
                removeUnderline
                onClick={() => {
                  dispatch(
                    slice.blockedVisitorSlice.actions.handleBlockedDetail({
                      ...blockedDetail,
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                  navigate({
                    ...PATH.BLOCKED_DETAIL,
                    pathname: `/analytics/blocked/${blockedItem.ip}`,
                  });
                }}
              >
                {blockedItem.totalAccess}
              </Link>
            </RegularText>
          </p>
        ),
        lastSeenOn: <RegularText>{formatDate(blockedItem.lastSeenOn)}</RegularText>,
        ispName:
          userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
            '---'
          ) : (
            <RegularText>{blockedItem.provider || '---'}</RegularText>
          ),
        risk: (
          <RegularText>
            {userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
              <div className="pointer text-decoration" onClick={() => navigate(PATH.PRICING_PLAN)}>
                <Badge tone="warning">{t('common:needs_upgrade')}</Badge>
              </div>
            ) : (
              <Tooltip content={badgeRisk?.tooltip}>
                <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
              </Tooltip>
            )}
          </RegularText>
        ),
        vpnStatus:
          userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
            <Tooltip content={t('common:available_on_higher_plan')}>---</Tooltip>
          ) : (
            <Badge tone={isVpn ? 'warning' : 'success'}>{isVpn ? t('common:yes') : t('common:no')}</Badge>
          ),
      };
    });
  }, [
    data?.listIp,
    handleCountry,
    userPlanFree,
    dataSettings?.settings.enableVisitorAnalytics,
    t,
    dispatch,
    badgeRiskConfig,
    blockedDetail,
    navigate,
  ]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, ipBlocked, city, country, lastSeenOn, totalAccess, ispName, risk, vpnStatus }, index) => (
      <IndexTable.Row id={id.toString()} key={id} position={index}>
        <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
        <IndexTable.Cell>{country}</IndexTable.Cell>
        <IndexTable.Cell>{city}</IndexTable.Cell>
        <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
        <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
        <IndexTable.Cell>{ispName}</IndexTable.Cell>
        <IndexTable.Cell id="risk-score">{risk}</IndexTable.Cell>
        <IndexTable.Cell id="vpn-status">{vpnStatus}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>{t('visitor_analytics:ip_blocked')}</RegularText>, id: '1' },
          { title: <RegularText>{t('visitor_analytics:country')}</RegularText>, id: '2' },
          { title: <RegularText>{t('visitor_analytics:state_province_city')}</RegularText>, id: '3' },
          { title: <RegularText>{t('visitor_analytics:total_access')}</RegularText>, id: '4' },
          {
            title: <RegularText>{t('visitor_analytics:last_seen_on')}</RegularText>,
            id: '5',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>{t('visitor_analytics:internet_provider')}</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '6',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>{t('visitor_analytics:risk_score')}</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: '8',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>{t('visitor_analytics:vpn_proxy')}</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: '9',
          },
        ]}
        selectable={false}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>

      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? t('common:showing_visitors_range', {
                    from: (blockHistoryTable.page - 1) * Number(perPage) + 1,
                    to: Math.min(blockHistoryTable.page * Number(perPage), data?.meta.totalItems),
                    total: data?.meta.totalItems,
                  })
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                blockedVisitorSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching}
            onNext={() => {
              dispatch(
                blockedVisitorSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(TableBlocked);

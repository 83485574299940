import blockCheckoutSlice from '@/redux/slice/blockCheckout.slice';
import blockListSlice from '@/redux/slice/blockList.slice';
import { BlockStack, Button, DropZone, InlineStack, Thumbnail } from '@shopify/polaris';
import { NoteIcon, XIcon } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import RegularText from '../RegularText';
import { DropZoneCustomStyled } from './styled';
import { useTranslation } from 'react-i18next';
interface DropZoneCustomProps {
  onChangeFile: (file: File[], value: string) => void;
  label: string;
  onRemove: () => void;
  fileDefault: string;
}

const DropZoneCustom: React.FC<DropZoneCustomProps> = ({ onChangeFile, label, onRemove, fileDefault }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validImageTypes = ['text/csv'];
  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (validImageTypes.includes(file.type)) {
          // Kiểm tra kích thước tệp (2MB)
          if (file.size > 2 * 1024 * 1024) {
            dispatch(blockListSlice.actions.handleErrorCSV(t('common:file_too_large_please_select_a_file_smaller_than_2mb')));
            dispatch(blockCheckoutSlice.actions.handleErrorCSV(t('common:file_too_large_please_select_a_file_smaller_than_2mb')));
            return;
          }
          dispatch(blockListSlice.actions.handleErrorCSV(''));
          dispatch(blockCheckoutSlice.actions.handleErrorCSV(''));
          const url = window.URL.createObjectURL(file);
          onChangeFile(acceptedFiles, url);
        } else {
          dispatch(blockListSlice.actions.handleErrorCSV(t('common:invalid_csv_file')));
          dispatch(blockCheckoutSlice.actions.handleErrorCSV(t('common:invalid_csv_file')));
        }
      }
    },
    [validImageTypes, dispatch, onChangeFile, t],
  );

  return (
    <DropZoneCustomStyled>
      <BlockStack gap="100">
        <div className="dropZone-container">
          <DropZone label={label} allowMultiple={false} type="file" onDrop={handleDropZoneDrop}>
            {!fileDefault ? (
              <DropZone.FileUpload
                actionTitle={t('common:upload_csv')}
                actionHint={t('common:accepts_csv_files_up_to_2mb_or_2000_data_lines')}
              />
            ) : null}
          </DropZone>

          {fileDefault && (
            <>
              <div className="file-preview">
                <InlineStack gap="400" align="center" blockAlign="center">
                  <div className="file-content">
                    <Thumbnail size="small" alt="preview-file" source={NoteIcon} />
                    <RegularText>{fileDefault}</RegularText>
                  </div>
                  <Button variant="tertiary" icon={XIcon} onClick={onRemove} />
                </InlineStack>
              </div>
            </>
          )}
        </div>
      </BlockStack>
    </DropZoneCustomStyled>
  );
};

export default DropZoneCustom;

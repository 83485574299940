import { config } from '@/config';
import { shouldShowBannerReview } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import { dataTableSelector } from '@/redux/slice/blockCheckout.slice';
import { blackListTableSelector } from '@/redux/slice/blockCheckoutAccess';
import { whiteListTableSelector } from '@/redux/slice/blockList.slice';
import { Banner, Button, Collapsible } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import RegularText from '../RegularText';
import { useTranslation } from 'react-i18next';

function BannerReviewApp() {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const banner = useSelector(bannerSelector);
  const blackListTable = useSelector(blackListTableSelector);
  const whiteListTable = useSelector(whiteListTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const whitelist = apiCaller.useFetchSettingListQuery(
    {
      ...whiteListTable,
      priority: whiteListTable.priority || undefined,
    },
    { skip: isSkip },
  );
  const blacklist = apiCaller.useFetchSettingListQuery(
    {
      ...blackListTable,
      priority: blackListTable.priority || undefined,
    },
    { skip: isSkip },
  );
  const dataTable = useSelector(dataTableSelector);
  const blockCheckout = apiCaller.useGetBlockCheckoutRulesQuery(
    {
      identifierId: config.shop || '',
      criteria: dataTable.criteria,
      page: 1,
      perPage: Number(dataTable.perPage),
      sortDirection: dataTable.sort,
    },
    { skip: isSkip },
  );
  const sendMessageReviewApp = () => {
    const sendText = 'I would like to request a review of my Blockify setup.';
    try {
      $crisp.push(['set', 'session:event', ['ReviewAppBlockify']]);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={banner.bannerReviewSetUpApp ? 'mb-16' : ''}>
      <Collapsible
        id="banner-check-review-app"
        open={
          banner.bannerReviewSetUpApp &&
          shouldShowBannerReview() &&
          (whitelist.data?.settingList.length !== 0 ||
            blacklist.data?.settingList.length !== 0 ||
            blockCheckout.data?.rules.length !== 0)
        }
        transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
      >
        <div className="mt-16">
          <Banner
            onDismiss={() => {
              dispatch(
                handleVisibleBanner({
                  key: 'bannerReviewSetUpApp',
                  value: false,
                }),
              );
            }}
            tone="info"
            title={t('ensure_rule_works')}
          >
            <div className="d-flex flex-column">
              <RegularText>{t('store_assistance_bots_issues')}</RegularText>
              <div className="mt-8">
                <Button target="_blank" onClick={sendMessageReviewApp}>
                  {t('review_set_up')}
                </Button>
              </div>
            </div>
          </Banner>
        </div>
      </Collapsible>
    </div>
  );
}

export default BannerReviewApp;

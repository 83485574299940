import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import { Ga4Event, IDScrollIntoView, Subscription, UserPlan } from '@/constants/enum';
import { capitalizeFirstLetter, downgradePlan } from '@/helpers';
import { useGa4 } from '@/hooks/useGa4';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { subscriptionSelector } from '@/redux/slice/account.slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import { IResponseApi } from '@/types/api/response.api';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  Divider,
  ExceptionList,
  Icon,
  InlineGrid,
  InlineStack,
  List,
  Modal,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris';
import { MagicIcon, CheckSmallIcon } from '@shopify/polaris-icons';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccountPlanStyled } from '../styled';
import ComparePlan from './ComparePlan';
import { DiscountCode } from './DiscountCode';
import GroupButtonSubscription from './GroupButtonSubscription';
import PricingFAQs from './PricingFAQs';
import ScrollInToView from '@/components/ScrollInToView';
import { useTranslation } from 'react-i18next';

interface PlanFeatures {
  title: string | null;
  subTitle: string;
  featureList: string[];
  subTitleService: string;
  serviceDescription: string;
  idScroll?: IDScrollIntoView;
}

interface Plans {
  free: PlanFeatures;
  premium: PlanFeatures;
  enterprise: PlanFeatures;
  'shopify plus'?: PlanFeatures;
}

function TablePricingPlan() {
  const {
    currentPlan,
    userPlanFree,
    userPlanPremium,
    userPlanEnterprise,
    userPlanShopifyPlus,
    shopifyPlanPlus,
    shopifyPlanPlusPartnerSandbox,
    newUser,
  } = userPlans();
  const { t } = useTranslation(['pricing']);
  const { handleGa4 } = useGa4();

  const isOldUserAndShopifyPlus = (shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && !newUser;
  const isNewUserAndShopifyPlus = (shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser;

  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const selectedSubscription = useSelector(subscriptionSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;

  const freeContent: PlanFeatures = useMemo(
    () => ({
      title: null,
      subTitle: t('pricing:standout_features'),
      featureList: [
        t('pricing:block_ip_country_rules', { maxLimitRules }),
        t('pricing:redirect_ip_country_rules', { maxLimitRules }),
        t('pricing:standard_visitor_analytics_quota'),
        t('pricing:standard_analyze_fraud_order'),
      ],
      subTitleService: t('pricing:service'),
      serviceDescription: t('pricing:live_chat_support_24_7'),
    }),
    [maxLimitRules, t],
  );

  const premiumContent: PlanFeatures = useMemo(
    () => ({
      title: t('pricing:all_includes_in_free'),
      subTitle: t('pricing:standout_features'),
      featureList: [
        t('pricing:unlimited_block_rules'),
        t('pricing:unlimited_geolocation'),
        t('pricing:unlimited_visitor_analytics_quota'),
        t('pricing:auto_block_high_risk_visitors'),
        t('pricing:auto_analyze_fraud_order'),
      ],
      subTitleService: t('pricing:service'),
      serviceDescription: t('pricing:live_chat_support_vip'),
      idScroll: IDScrollIntoView.Pricing_premium,
    }),
    [t],
  );

  const enterpriseContent: PlanFeatures = useMemo(
    () => ({
      title: t('pricing:all_includes_in_premium'),
      subTitle: t('pricing:standout_features'),
      featureList: [
        t('pricing:block_tor_connection'),
        t('pricing:unlimited_block_isp_referer_url'),
        t('pricing:auto_block_fraud_orders'),
        t('pricing:auto_cancel_high_risk_order'),
        t('pricing:prevent_right_click_keyboard_shortcuts'),
        t('pricing:prevent_inspect'),
      ],
      subTitleService: t('pricing:service'),
      serviceDescription: t('pricing:live_chat_support_vip'),
      idScroll: IDScrollIntoView.Pricing_enterprise,
    }),
    [t],
  );

  const shopifyPlusContent: PlanFeatures = useMemo(
    () => ({
      title: t('pricing:all_included_in_premium_enterprise'),
      subTitle: t('pricing:standout_features'),
      featureList: [
        t('pricing:unlimited_rules'),
        t('pricing:block_vpn_proxy'),
        t('pricing:block_tor_connection'),
        t('pricing:fraud_order_analytics'),
        t('pricing:unlimited_visitor_analytics_quota'),
        t('pricing:unlimited_block_isp_referrer_url'),
        t('pricing:auto_block_visitors_placing_fraud_orders'),
        t('pricing:auto_cancel_high_risk_order'),
        t('pricing:block_checkout'),
      ],
      subTitleService: 'Service',
      serviceDescription: 'Live chat support 24/7 (VIP priority)',
      idScroll: IDScrollIntoView.Pricing_shopify_plus,
    }),
    [t],
  );

  const trialDaysRemaining = dataSettings?.settings.trialDaysRemaining;

  const listPricing = apiCaller.useListPricingQuery(undefined, { skip: isSkip });
  const [activePlanMutation, activePlanMutationStatus] = apiCaller.useGetLinkAccountPlanMutation();

  const [expanded, setExpanded] = useState(false);
  const [isOpenModalDiscount, setIsOpenModalDiscount] = useState(false);
  const [isOpenModalDowngradePlan, setIsOpenModalDowngradePlan] = useState(false);
  const [state, setState] = useState({
    plan: UserPlan.FREE,
    subscription: Subscription.Yearly,
    discount: 0,
  });

  useEffect(() => {
    if (selectedSubscription || dataSettings?.settings) {
      setState({
        ...state,
        plan: dataSettings?.settings.user.plan || UserPlan.FREE,
        discount: dataSettings?.settings.discount || 0,
        subscription: selectedSubscription,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscription, dataSettings?.settings]);

  const renderLayoutGrid = useMemo(() => {
    let columns;
    if (!shopifyPlanPlus || !shopifyPlanPlusPartnerSandbox) {
      columns = { xl: 3, lg: 3, md: 1, sm: 1, xs: 1 };
    }
    if (
      (newUser && (shopifyPlanPlus || shopifyPlanPlusPartnerSandbox)) ||
      ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && userPlanFree)
    ) {
      columns = { xl: 2, lg: 2, md: 1, sm: 1, xs: 1 };
    }
    if ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && !newUser) {
      columns = { xl: 4, lg: 4, md: 2, sm: 2, xs: 2 };
    }

    return columns;
  }, [newUser, shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, userPlanFree]);

  const pricingTableData = useMemo(() => {
    let general: Array<IResponseApi.Pricing> = [];
    let free: IResponseApi.Pricing;
    let premium: IResponseApi.Pricing;
    let enterprise: IResponseApi.Pricing;
    let shopifyPlus: IResponseApi.Pricing;

    if (listPricing.data) {
      free = {
        id: 0,
        plan: UserPlan.FREE,
        price: 0,
        annuallyDiscount: 0,
        annuallyDiscountedPrice: 0,
        tag: null,
        campaignPrice: 0,
      };
      premium = listPricing.data.data.find((item) => item.plan === UserPlan.PREMIUM) || free;
      enterprise = listPricing.data.data.find((item) => item.plan === UserPlan.ENTERPRISE) || free;
      shopifyPlus = listPricing.data.data.find((item) => item.plan === UserPlan.SHOPIFYPLUS) || free;

      if (!shopifyPlanPlus || !shopifyPlanPlusPartnerSandbox) general = [free, premium, enterprise];
      if (
        ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser) ||
        ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && userPlanFree)
      )
        general = [free, shopifyPlus];
      if ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && !newUser) general = [free, premium, enterprise, shopifyPlus];
    }

    let content: Partial<Plans> = {};
    if (!shopifyPlanPlus || !shopifyPlanPlusPartnerSandbox) {
      content = {
        free: freeContent,
        premium: premiumContent,
        enterprise: enterpriseContent,
      };
    }

    if (
      ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser) ||
      ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && userPlanFree)
    ) {
      content = {
        free: freeContent,
        // eslint-disable-next-line
        ['shopify plus']: shopifyPlusContent,
      };
    }

    if ((shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && !newUser) {
      content = {
        free: freeContent,
        premium: premiumContent,
        enterprise: enterpriseContent,
        // eslint-disable-next-line
        ['shopify plus']: shopifyPlusContent,
      };
    }

    return {
      general,
      content,
    };
  }, [
    enterpriseContent,
    freeContent,
    listPricing.data,
    newUser,
    premiumContent,
    shopifyPlanPlus,
    shopifyPlanPlusPartnerSandbox,
    shopifyPlusContent,
    userPlanFree,
  ]);

  const downgradePlanShopifyPlus = useMemo(() => {
    return {
      [UserPlan.ENTERPRISE]: enterpriseContent.featureList,
      [UserPlan.PREMIUM]: premiumContent.featureList,
      [UserPlan.FREE]: freeContent.featureList,
    };
  }, [enterpriseContent.featureList, freeContent.featureList, premiumContent.featureList]);

  const selectPlanHandle = useCallback(
    (plan: UserPlan) => {
      activePlanMutation({
        subscription: state.subscription,
        plan,
      })
        .then((res) => {
          if ('data' in res && res.data.confirmationUrl) {
            window.open(res.data.confirmationUrl, '_top');
          }
        })
        .catch((error) => console.log(error));
    },
    [activePlanMutation, state.subscription],
  );

  const handleChangePlan = useCallback(() => {
    activePlanMutation({ plan: state.plan, subscription: state.subscription }).then((res) => {
      if ('data' in res && res.data.confirmationUrl) {
        window.open(res.data.confirmationUrl, '_top');
      }
      setIsOpenModalDowngradePlan(false);
    });
  }, [activePlanMutation, state.plan, state.subscription]);

  const handleClickSelect = (plan: IResponseApi.Pricing) => {
    handleGa4(Ga4Event.SelectPlan, {
      plan: plan.plan,
      type: state.subscription,
    });
    setState({ ...state, plan: plan.plan });
    if (dataSettings && downgradePlan(currentPlan || UserPlan.FREE, plan.plan)) {
      setIsOpenModalDowngradePlan(true);
    } else {
      selectPlanHandle(plan.plan);
    }
  };

  return (
    <AccountPlanStyled isNewUserAndShopifyPlus={isNewUserAndShopifyPlus} isOldUserAndShopifyPlus={isOldUserAndShopifyPlus}>
      <GroupButtonSubscription listPricing={listPricing.data?.data || []} />
      <div className="mt-16 discount-btn">
        {state.discount > 0 ? (
          <div className="d-flex" style={{ flexDirection: 'column' }}>
            <RegularText>
              {t('pricing:enjoy_your')} {state.discount}% {t('pricing:discount_for_all_monthly_plans')}
            </RegularText>
            <CustomButton
              onClick={() => {
                setIsOpenModalDiscount(true);
              }}
              variant="plain"
            >
              {t('pricing:replace_your_discount_code')}
            </CustomButton>
          </div>
        ) : (
          <CustomButton
            onClick={() => {
              setIsOpenModalDiscount(true);
            }}
            variant="plain"
          >
            {t('pricing:apply_your_discount_code')}
          </CustomButton>
        )}
      </div>

      <div className="plan-table">
        <InlineGrid columns={renderLayoutGrid} gap="400">
          {pricingTableData.general.map((plan, idx) => {
            const planItem = pricingTableData.content[plan.plan]!;

            return (
              <ScrollInToView
                idScrollToBlock={planItem.idScroll || IDScrollIntoView.None}
                key={plan.id}
                className="plan-table-item"
              >
                {plan.plan === UserPlan.FREE ? (
                  <div className="free_plan_price">
                    <Text as="h4" variant="heading2xl">
                      Free
                    </Text>
                  </div>
                ) : (
                  <div className="remaining_plan_price">
                    <InlineStack align="space-between">
                      <p className="pricing-title">{plan.plan.toUpperCase()}</p>
                      {plan.tag && idx !== 3 ? <Badge tone="info">{t('pricing:most_popular')}</Badge> : null}
                    </InlineStack>

                    <InlineStack gap="200" blockAlign="start" wrap={false}>
                      <p className="h6 pricing-plan">
                        {state.subscription === Subscription.Monthly
                          ? `$${(plan.campaignPrice || state.discount
                              ? (1 - state.discount / 100) * plan.price
                              : plan.price
                            ).toFixed(2)}`
                          : `$${plan.annuallyDiscountedPrice}`}
                      </p>

                      <BlockStack gap="050">
                        <Text as="span" variant="bodyMd">
                          USD
                        </Text>

                        {plan.campaignPrice && state.subscription === Subscription.Monthly ? (
                          <>
                            <Text as="span" variant="headingSm">
                              {t('pricing:first_month_then')}
                              <Text as="span" tone="subdued">
                                {state.subscription === Subscription.Monthly
                                  ? ` $${plan.price}`
                                  : ` $${plan.annuallyDiscountedPrice}`}
                              </Text>
                              <Text as="span" variant="bodyMd">
                                {plan.campaignEndsAt && ` (valid until ${new Date(plan.campaignEndsAt).toDateString()})`}
                              </Text>
                            </Text>
                          </>
                        ) : (
                          <Text as="span" variant="headingMd">
                            /{t('pricing:month')}
                          </Text>
                        )}
                      </BlockStack>
                    </InlineStack>

                    {state.subscription === Subscription.Yearly ? (
                      <p>
                        ({plan.annuallyDiscount}% {t('pricing:off')} <span className="pricing-plan-strike">${plan.price}</span>)
                      </p>
                    ) : null}
                    {state.subscription === Subscription.Monthly && state.discount ? (
                      <p>
                        ({state.discount}% {t('pricing:off')} <span className="pricing-plan-strike">${plan.price}</span>)
                      </p>
                    ) : null}
                  </div>
                )}

                <Divider />

                <div className="content-plan">
                  <BlockStack gap="050" inlineAlign="start">
                    {planItem?.title ? (
                      <InlineStack gap="050" blockAlign="center" wrap={false}>
                        <div>
                          <Icon source={MagicIcon} tone="base" />
                        </div>
                        <Text as="span" variant="headingMd">
                          {planItem.title}
                        </Text>
                      </InlineStack>
                    ) : (
                      <div className="mt-16"></div>
                    )}

                    <BlockStack gap="150">
                      <div className="mb-8">
                        <Text as="p" variant="headingSm">
                          {planItem?.subTitle}
                        </Text>
                      </div>

                      <ExceptionList
                        items={planItem.featureList.map((item) => ({
                          icon: CheckSmallIcon,
                          description: item,
                        }))}
                      />
                    </BlockStack>

                    <BlockStack gap="150">
                      <Text as="span" variant="headingMd">
                        {planItem.subTitleService}
                      </Text>
                      <ExceptionList items={[{ icon: CheckSmallIcon, description: planItem.serviceDescription }]} />
                    </BlockStack>
                  </BlockStack>
                </div>

                {isLoading ? (
                  <SkeletonDisplayText maxWidth="100%" size="extraLarge" />
                ) : (
                  <div className="plan-table-layout-content-btn">
                    {(state.subscription === dataSettings?.settings.user.subscription && currentPlan === plan.plan) ||
                    (currentPlan === plan.plan && userPlanFree) ? (
                      <Button fullWidth disabled>
                        {t('pricing:your_current_plan')}
                      </Button>
                    ) : (
                      <CustomButton
                        fullWidth
                        loading={activePlanMutationStatus.isLoading && state.plan === plan.plan && !isOpenModalDowngradePlan}
                        variant="primary"
                        onClick={() => handleClickSelect(plan)}
                      >
                        {plan.campaignPrice && state.subscription === Subscription.Monthly
                          ? t('pricing:get_the_deal')
                          : trialDaysRemaining && plan.plan !== UserPlan.FREE
                          ? t('pricing:start_trial_days_free', { trialDaysRemaining })
                          : t('pricing:select')}
                      </CustomButton>
                    )}
                  </div>
                )}
              </ScrollInToView>
            );
          })}
        </InlineGrid>
      </div>

      {!newUser && (shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) ? null : (
        <div style={{ width: '100%' }}>
          <Card>
            <InlineStack align="space-between" blockAlign="center">
              <BlockStack gap="300">
                <Text variant="headingMd" as="h3">
                  {t('pricing:compare_plan_features')}
                </Text>
                <Text variant="bodyLg" as="h4" tone="subdued">
                  {t('pricing:all_details_of_the_blockify_plans')}
                </Text>
              </BlockStack>
              <Button
                size="large"
                textAlign="left"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                {expanded ? t('pricing:hide_detailed_plans') : t('pricing:show_detailed_plans')}
              </Button>
            </InlineStack>
          </Card>

          {expanded && <ComparePlan />}
        </div>
      )}

      <PricingFAQs />

      <Modal
        open={isOpenModalDowngradePlan}
        onClose={() => setIsOpenModalDowngradePlan(false)}
        title={t('pricing:downgrade_plan')}
        primaryAction={{
          content: t('pricing:stay_on_current_plan'),
          onAction: () => setIsOpenModalDowngradePlan(false),
        }}
        secondaryActions={[
          {
            loading: activePlanMutationStatus.isLoading,
            content: t('pricing:downgrade'),
            onAction: handleChangePlan,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>
            {`${t('pricing:downgrading_loses_features')} `}
            <b>{capitalizeFirstLetter(currentPlan || '')}</b> {t('pricing:plan')}
          </RegularText>

          <List type="bullet">
            {userPlanFree ? freeContent.featureList.map((item) => <List.Item key={item}>{item}</List.Item>) : null}

            {userPlanPremium ? premiumContent.featureList.map((item) => <List.Item key={item}>{item}</List.Item>) : null}
            {userPlanEnterprise ? enterpriseContent.featureList.map((item) => <List.Item key={item}>{item}</List.Item>) : null}
            {userPlanShopifyPlus
              ? downgradePlanShopifyPlus[state.plan as keyof typeof downgradePlanShopifyPlus]?.map((str, index) => {
                  return <List.Item key={index}>{str}</List.Item>;
                })
              : null}
            <List.Item>
              {t('pricing:live_chat_support_24_7')} {!userPlanFree ? `'${t('pricing:vip_priority')}'` : ''}{' '}
            </List.Item>
          </List>

          <RegularText>{t('pricing:store_vulnerability_warning')}</RegularText>
        </Modal.Section>
      </Modal>
      <DiscountCode isOpen={isOpenModalDiscount} handleClose={() => setIsOpenModalDiscount(false)} />
    </AccountPlanStyled>
  );
}

export default memo(TablePricingPlan);

import { memo, useState } from 'react';

import { ISettings } from '@/types/components';
import { DropZone, InlineError } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const maxSize = 2000000;

function CustomImage({ ...props }: ISettings.CustomImage) {
  const { t } = useTranslation(['settings', 'common']);
  const [error, setError] = useState('');
  const handleDropZoneDrop = async (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    if (acceptedFiles.length > 0 && validImageTypes.includes(acceptedFiles[0].type)) {
      setError('');
      props.handleUpload(acceptedFiles[0], window.URL.createObjectURL(acceptedFiles[0]));
    }
  };

  const validateFile = (file: any) => {
    props.handleSaved();
    if (file.size > maxSize) {
      setError(t('settings:file_size_too_large'));
    }
    if (!validImageTypes.includes(file.type)) {
      setError(t('settings:unsupported_file_format'));
    }
    return file.size < maxSize && validImageTypes.includes(file.type);
  };

  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const actionHint = t('settings:supported_formats_jpg_jpeg_png_2mb');
  const fileUpload = <DropZone.FileUpload actionHint={actionHint} actionTitle={t('settings:choose_file')} />;

  return (
    <div>
      <DropZone id={props.id} customValidator={validateFile} label="" labelHidden onDrop={handleDropZoneDrop} variableHeight>
        {fileUpload}
      </DropZone>
      {!!error && !props.isSaved ? <InlineError message={error} fieldID={props.id} /> : null}
    </div>
  );
}

export default memo(CustomImage);

import { Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { SwitchStyled } from './styled';
import { useTranslation } from 'react-i18next';
interface Props {
  isActive?: boolean;
  onSwitch: (checked: boolean) => void;
  isLoading?: boolean;
  hasTooltip?: boolean;
}

const Switch = ({ isLoading, hasTooltip = true, ...props }: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <SwitchStyled>
      {hasTooltip ? (
        <Tooltip content={props.isActive ? t('common:turn_off') : t('common:turn_on')}>
          <div
            className={`rule-toggle ${props.isActive && 'active'} ${isLoading ? 'disable' : ''}`}
            onClick={() => {
              if (!isLoading) props.onSwitch(!props.isActive);
            }}
          />
        </Tooltip>
      ) : (
        <div
          className={`rule-toggle ${props.isActive && 'active'} ${isLoading ? 'disable' : ''}`}
          onClick={() => {
            if (!isLoading) props.onSwitch(!props.isActive);
          }}
        />
      )}
    </SwitchStyled>
  );
};

export default memo(Switch);

import { Enum } from '@/constants';
import { TFunction } from 'i18next';

const statusOptions = (t: TFunction) => [
  {
    label: t('common:allow'),
    value: Enum.StatusVisitorsLogType.Allow,
  },
  {
    label: t('common:block'),
    value: Enum.StatusVisitorsLogType.Block,
  },
  {
    label: t('common:all'),
    value: '',
  },
];

const riskOptions = (t: TFunction) => [
  {
    label: t('visitor_analytics:low'),
    value: Enum.RiskType.Safe,
  },
  {
    label: t('visitor_analytics:high'),
    value: Enum.RiskType.Risky,
  },
  {
    label: t('visitor_analytics:dangerous'),
    value: Enum.RiskType.Dangerous,
  },
  {
    label: t('common:all'),
    value: '',
  },
];
const typeOptions = (t: TFunction) => [
  {
    label: t('visitor_analytics:other'),
    value: Enum.TypeVisitorsLogType.Other,
  },
  {
    label: t('visitor_analytics:residential'),
    value: Enum.TypeVisitorsLogType.Residential,
  },
  {
    label: t('visitor_analytics:wireless'),
    value: Enum.TypeVisitorsLogType.Wireless,
  },
  {
    label: t('visitor_analytics:business'),
    value: Enum.TypeVisitorsLogType.Business,
  },
  {
    label: t('visitor_analytics:vpn'),
    value: Enum.TypeVisitorsLogType.VPN,
  },
  {
    label: t('visitor_analytics:tor'),
    value: Enum.TypeVisitorsLogType.TOR,
  },
  {
    label: t('common:all'),
    value: '',
  },
];

export default {
  statusOptions,
  riskOptions,
  typeOptions,
};

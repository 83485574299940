import RegularText from '@/components/RegularText';
import Switch from '@/components/Switch';
import { config } from '@/config';
import { Enum, PATH } from '@/constants';
import { CriteriaType, ListType } from '@/constants/enum';
import options from '@/constants/options';
import { capitalizeFirstLetter, checkShowErrorInline, formatCreatedAt, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockCheckoutAccessSlice, { blackListTableSelector } from '@/redux/slice/blockCheckoutAccess';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IResponseApi } from '@/types/api/response.api';
import {
  Badge,
  Button,
  EmptyState,
  IndexTable,
  Link,
  Modal,
  Pagination,
  SkeletonBodyText,
  Text,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

interface TableBlackListProps {
  onParentAction?: (action: () => void) => void;
  onGuide: () => void;
}

const TableBlackList = ({ onParentAction, onGuide }: TableBlackListProps): JSX.Element => {
  const isTinyMobile = useMediaQuery({ maxWidth: 500 });
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();
  const { userPlanFree, userPlanPremium } = userPlans();
  const scope = useScope();
  const dispatch = useDispatch();
  const handleCountry = useCountry();
  const blackListTable = useSelector(blackListTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery(
    {
      type: Enum.ActionType.Block,
      priority: Enum.ListType.BlackList,
      criteria: Enum.CriteriaType.IpAddress,
    },
    { skip: isSkip },
  );
  const [trackAction] = apiCaller.useTrackActionMutation();

  const [state, setState] = useState({
    itemSelected: -1,
    isOpenModalDelete: false,
  });

  const { data, isFetching, isLoading, refetch } = apiCaller.useFetchSettingListQuery(
    {
      ...blackListTable,
      priority: blackListTable.priority || undefined,
      pathName: 'checkout-page',
    },
    { skip: isSkip },
  );
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();
  const [activeRule, activeRuleStatus] = apiCaller.useActiveRuleMutation();
  const [deleteAllItem, deleteAllItemStatus] = apiCaller.useDeleteAllBlackListSettingMutation();
  const listCollection = apiCaller.useListCollectionQuery(config.shop, { skip: isSkip });

  const handleCloseModalDelete = useCallback(() => {
    setState({
      itemSelected: -1,
      isOpenModalDelete: false,
    });
  }, []);

  const handleOpenModalDelete = useCallback(
    (id: number) => () => {
      setState({
        itemSelected: id,
        isOpenModalDelete: true,
      });
    },
    [],
  );
  const handleEdit = useCallback(
    (item: IResponseApi.SettingItem) => () => {
      const rule = {
        ...item,
        type: item.type,
        country: item.country ? [item.country] : undefined,
        state: item.state ? [item.state] : undefined,
        city: item.city ? [item.city] : undefined,
        ispName: item.ispName ? [item.ispName] : undefined,
        ispCode: item.ispCode ? [item.ispCode] : undefined,
        collectionId: item.collectionId ? [item.collectionId] : [],
        collectionName: item.collectionName ? [item.collectionName] : [],
        productId: item.productId ? [item.productId] : [],
        pageId: item.pageId ? [item.pageId] : [],
        deviceType: item.deviceType || '',
        osName: item.osName || '',
        browserName: item.browserName ? [item.browserName] : [],
        isActive: item.isActive === undefined ? true : item.isActive,
        productName: item.productName ? [item.productName] : undefined,
        pageTitle: item.pageTitle ? [item.pageTitle] : undefined,
      };
      if (item.criteria === CriteriaType.ISP) {
        dispatch(blockCheckoutAccessSlice.actions.handleInputIsp(item.ispName || ''));
      }
      if (item.criteria === CriteriaType.Product) {
        dispatch(blockCheckoutAccessSlice.actions.handleInputProduct(item.productName || ''));
      }
      dispatch(blockCheckoutAccessSlice.actions.handleErrorRule([]));
      dispatch(blockCheckoutAccessSlice.actions.handleSetting(rule));
      dispatch(blockCheckoutAccessSlice.actions.handleSettingBackup(rule));
      navigate(PATH.BLOCK_CHECKOUT_ACCESS_PAGE);
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    dispatch(blockCheckoutAccessSlice.actions.handleOpenGuide(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleRule = useCallback(
    (id: number) => (status: boolean) => {
      activeRule({
        ids: [id],
        priority: Enum.ListType.BlackList,
        status,
        pathName: 'checkout-page',
      });
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: status ? t('common:turn_on') : t('common:turn_off'),
          error: false,
        }),
      );
    },
    [activeRule, dispatch, t],
  );

  const items = useMemo(() => {
    return data?.settingList.map((blockItem) => {
      const renderDescription = () => {
        const data = [
          blockItem.ipAddress,
          blockItem.city,
          blockItem.state,
          blockItem.ispName,
          blockItem.productName,
          blockItem.browserName,
          blockItem.osName,
          blockItem.pageTitle,
          capitalizeFirstLetter(blockItem.deviceType || ''),
          listCollection.data?.listCollections.find((item) => item.id.toString() === blockItem.collectionId)?.title,
          blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined,
        ];
        const description = data.filter((item) => !!item).join(' - ');
        if (blockItem.type === Enum.ActionType.Redirect) {
          const handleOpenURL = () => {
            window.open(blockItem.linkRedirect, '_blank');
          };
          const handleOpenReferralURL = () => {
            window.open(blockItem.referUrl, '_blank');
          };
          if (blockItem.shortUrl) {
            if (blockItem.referUrl) {
              if (blockItem.shortReferUrl) {
                return (
                  <Text as="span" variant="bodyMd" breakWord>
                    {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                    <Link onClick={handleOpenReferralURL} removeUnderline>
                      {blockItem.shortReferUrl}
                    </Link>{' '}
                    to{' '}
                    <Link onClick={handleOpenURL} removeUnderline>
                      {blockItem.shortUrl}
                    </Link>
                  </Text>
                );
              } else {
                return (
                  <Text as="span" variant="bodyMd" breakWord>
                    {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                    <Link onClick={handleOpenReferralURL} removeUnderline>
                      {blockItem.referUrl}
                    </Link>{' '}
                    to{' '}
                    <Link onClick={handleOpenURL} removeUnderline>
                      {blockItem.shortUrl}
                    </Link>
                  </Text>
                );
              }
            } else {
              return (
                <Text as="span" variant="bodyMd" breakWord>
                  {description} to{' '}
                  <Link onClick={handleOpenURL} removeUnderline>
                    {blockItem.shortUrl}
                  </Link>
                </Text>
              );
            }
          } else {
            if (blockItem.referUrl) {
              if (blockItem.shortReferUrl) {
                return (
                  <Text as="span" variant="bodyMd" breakWord>
                    {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                    <Link removeUnderline onClick={handleOpenReferralURL}>
                      {blockItem.shortReferUrl}
                    </Link>{' '}
                    to{' '}
                    <Link onClick={handleOpenURL} removeUnderline>
                      {blockItem.linkRedirect}
                    </Link>
                  </Text>
                );
              }
              return (
                <Text as="span" variant="bodyMd" breakWord>
                  {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                  <Link removeUnderline onClick={handleOpenReferralURL}>
                    {blockItem.referUrl}
                  </Link>{' '}
                  to{' '}
                  <Link onClick={handleOpenURL} removeUnderline>
                    {blockItem.linkRedirect}
                  </Link>
                </Text>
              );
            }
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {description} to{' '}
                <Link onClick={handleOpenURL} removeUnderline>
                  {blockItem.linkRedirect}
                </Link>
              </Text>
            );
          }
        } else if (blockItem.referUrl) {
          const handleOpenReferralURL = () => {
            window.open(blockItem.referUrl, '_blank');
          };
          if (blockItem.shortReferUrl) {
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                <Link onClick={handleOpenReferralURL} removeUnderline>
                  {blockItem.shortReferUrl}
                </Link>
              </Text>
            );
          } else {
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                <Link onClick={handleOpenReferralURL} removeUnderline>
                  {blockItem.referUrl}
                </Link>
              </Text>
            );
          }
        } else {
          return description;
        }
      };
      return {
        id: blockItem.id.toString(),
        blockOrRedirect: (
          <RegularText>{blockItem.priority === ListType.BlackList ? t('common:block') : t('common:whitelist')}</RegularText>
        ),
        criteria: (
          <RegularText>
            {options.criteriaFiltersBlockCheckout.find((item) => item.value === blockItem.criteria)?.label}
          </RegularText>
        ),
        description: <RegularText>{renderDescription()}</RegularText>,
        createdAt: <RegularText>{formatCreatedAt(blockItem.createdAt, t)}</RegularText>,
        lastUpdatedAt: (
          <RegularText>{blockItem.lastUpdatedAt ? formatCreatedAt(blockItem.lastUpdatedAt * 1000, t) : ''}</RegularText>
        ),
        action: (
          <>
            {((userPlanFree &&
              ((blockItem.criteria !== Enum.CriteriaType.IpAddress &&
                blockItem.criteria !== Enum.CriteriaType.IpAddressStartWith &&
                blockItem.criteria !== Enum.CriteriaType.Country) ||
                ((ruleBlockSummary?.data?.totalRulesActivated || 0) >= maxLimitRules && blockItem.isActive === false))) ||
              (userPlanPremium &&
                (blockItem.criteria === Enum.CriteriaType.ISP ||
                  blockItem.criteria === Enum.CriteriaType.ReferralLink ||
                  blockItem.criteria === Enum.CriteriaType.IpRanges))) &&
            blockItem.isActive === false ? (
              <div
                className="pointer text-decoration"
                onClick={() => {
                  navigate(PATH.PRICING_PLAN);
                  trackAction('block_checkout_action_rule');
                }}
              >
                <Tooltip
                  content={
                    blockItem.criteria === Enum.CriteriaType.ISP ||
                    blockItem.criteria === Enum.CriteriaType.ReferralLink ||
                    blockItem.criteria === Enum.CriteriaType.IpRanges
                      ? t('common:available_enterprise_plan')
                      : t('common:available_for_premium_or_higher')
                  }
                >
                  <Badge tone="warning">{t('common:needs_upgrade')}</Badge>
                </Tooltip>
              </div>
            ) : (
              <div className={scope.isViewOnly ? 'btn-container disable' : 'btn-container'}>
                <div className="control-btn control-btn-toggle">
                  <Switch
                    onSwitch={(checked) => toggleRule(blockItem.id)(checked)}
                    isActive={blockItem.isActive}
                    isLoading={activeRuleStatus.isLoading || scope.isViewOnly}
                  />
                </div>
                <div className="absolute d-flex">
                  <div className="control-btn edit-btn">
                    <Tooltip content={t('common:edit')}>
                      <Button icon={EditIcon} onClick={handleEdit(blockItem)} variant="plain" />
                    </Tooltip>
                  </div>
                  <div className="control-btn remove-btn">
                    <Tooltip content={t('common:delete')}>
                      <Button icon={DeleteIcon} onClick={handleOpenModalDelete(blockItem.id)} variant="plain" />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </>
        ),
      };
    });
  }, [
    data?.settingList,
    t,
    userPlanFree,
    ruleBlockSummary?.data?.totalRulesActivated,
    maxLimitRules,
    userPlanPremium,
    scope.isViewOnly,
    activeRuleStatus.isLoading,
    handleEdit,
    handleOpenModalDelete,
    listCollection.data?.listCollections,
    handleCountry,
    navigate,
    trackAction,
    toggleRule,
  ]);

  useEffect(() => {
    refetch();
    ruleBlockSummary.refetch();
    // eslint-disable-next-line
  }, [refetch]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  onParentAction?.(clearSelection);

  const handleDelete = useCallback(
    (id: number) => () => {
      deleteItem({
        id,
        pathName: 'checkout-page',
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));

          if (data && data.meta.totalItems % Number(blackListTable.perPage) === 1) {
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                page: blackListTable.page - 1 || 1,
              }),
            );
          }
          clearSelection();
          handleCloseModalDelete();
        }
      });
    },

    [blackListTable, data, deleteItem, dispatch, handleCloseModalDelete, clearSelection, t],
  );

  const handleDeleteSelected = useCallback(async () => {
    try {
      const res = await deleteAllItem({
        ids: JSON.stringify(selectedResources),
        pathName: 'checkout-page',
      });

      const condition = checkShowErrorInline(res);

      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        if (data?.meta.itemCount === selectedResources.length) {
          dispatch(
            blockCheckoutAccessSlice.actions.handleBlackListTable({
              ...blackListTable,
              page: blackListTable.page - 1 || 1,
            }),
          );
        }

        handleCloseModalDelete();
        clearSelection();
      }
    } catch (error) {
      console.error('Error while deleting selected resources:', error);
    }
  }, [
    blackListTable,
    clearSelection,
    data?.meta.itemCount,
    deleteAllItem,
    dispatch,
    handleCloseModalDelete,
    selectedResources,
    t,
  ]);

  const handleToggleSelected = useCallback(
    (status: boolean) => async () => {
      try {
        const res = await activeRule({
          ids: selectedResources.map((item) => +item),
          priority: Enum.ListType.BlackList,
          status,
          pathName: 'checkout-page',
        });

        const condition = checkShowErrorInline(res);
        clearSelection();
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [activeRule, dispatch, selectedResources, clearSelection, t],
  );

  const promotedBulkActions = [
    {
      content: t('common:cancel'),
      onAction: () => clearSelection(),
    },
    {
      content: t('common:turn_on'),
      onAction: handleToggleSelected(true),
    },
    {
      content: t('common:turn_off'),
      onAction: handleToggleSelected(false),
    },

    {
      content: t('common:delete'),
      onAction: handleOpenModalDelete(-1),
    },
  ];

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, blockOrRedirect, criteria, description, action, createdAt, lastUpdatedAt }, index) => (
      <IndexTable.Row onClick={() => {}} id={id} key={id} position={index} selected={selectedResources.includes(id)}>
        <IndexTable.Cell>{blockOrRedirect}</IndexTable.Cell>
        <IndexTable.Cell>{criteria}</IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>{lastUpdatedAt}</IndexTable.Cell>
        <IndexTable.Cell>{action}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items, selectedResources]);

  const resourceName = {
    singular: 'rule',
    plural: 'rules',
  };

  return (
    <div className="pd-16">
      <div
        className={
          selectedResources.length ? 'table-block table-block-blacklist selected-resource' : 'table-block table-block-blacklist'
        }
      >
        <IndexTable
          emptyState={
            isLoading ? (
              <SkeletonBodyText lines={16} />
            ) : (
              <EmptyState
                heading={t('common:take_control_website_security')}
                // action={{
                //   content: 'Start with new rule',
                //   onAction: () => {
                //     onGuide();
                //     dispatch(blockCheckoutSlice.actions.handleTabs(0));
                //     dispatch(
                //       blockCheckoutAccessSlice.actions.handleSetting({
                //         ...settings,
                //         type: ActionType.Block,
                //       }),
                //     );
                //     window.scrollTo({
                //       top: 200,
                //       behavior: 'smooth',
                //     });
                //   },
                // }}
                // secondaryAction={{
                //   content: 'Learn more',
                //   url: 'https://docs.ipblocker.io/',
                //   target: '_blank',
                // }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              >
                <RegularText>{t('common:setup_blacklist_now')}</RegularText>
              </EmptyState>
            )
          }
          onSelectionChange={handleSelectionChange}
          resourceName={resourceName}
          itemCount={items?.length || 0}
          headings={[
            { title: t('common:block_or_whitelist') },
            { title: t('common:criteria') },
            { title: t('common:description') },
            { title: t('common:created_at') },
            { title: t('common:last_updated') },
            { title: t('common:action') },
          ]}
          bulkActions={isTinyMobile ? promotedBulkActions : undefined}
          promotedBulkActions={!isTinyMobile ? promotedBulkActions : undefined}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        >
          {isLoading ? <SkeletonBodyText lines={16} /> : rowMarkup}
        </IndexTable>

        <div className="mt-16 pb-16">
          {data && data?.meta.totalItems ? (
            <Pagination
              label={
                data?.meta.totalItems
                  ? t('common:showing_items_range', {
                      from: (data.meta.currentPage - 1) * Number(data.meta.perPage) + 1,
                      to: Math.min(data.meta.currentPage * Number(data.meta.perPage), data.meta.totalItems),
                      total: data.meta.totalItems,
                    })
                  : null
              }
              hasPrevious={!isFetching && data && data.meta.currentPage > 1}
              onPrevious={() => {
                if (data) {
                  dispatch(
                    blockCheckoutAccessSlice.actions.handleBlackListTable({
                      ...blackListTable,
                      page: data && data.meta.currentPage - 1,
                    }),
                  );
                }
              }}
              hasNext={!isFetching && data && data.meta.currentPage < Math.ceil(data.meta.totalItems / Number(data.meta.perPage))}
              onNext={() => {
                if (data) {
                  dispatch(
                    blockCheckoutAccessSlice.actions.handleBlackListTable({
                      ...blackListTable,
                      page: data.meta.currentPage + 1,
                    }),
                  );
                }
              }}
            />
          ) : null}
        </div>
      </div>

      <Modal
        open={state.isOpenModalDelete}
        onClose={handleCloseModalDelete}
        title={state.itemSelected === -1 ? t('common:delete_all_selected_rules') : t('common:delete_rule')}
        primaryAction={{
          destructive: true,
          content: t('common:delete'),
          onAction: state.itemSelected === -1 ? handleDeleteSelected : handleDelete(state.itemSelected),
          loading: deleteItemStatus.isLoading || deleteAllItemStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: t('common:cancel'),
            onAction: handleCloseModalDelete,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>{t('common:delete_rule_no_revert')}</RegularText>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default memo(TableBlackList);

import { CustomTooltip } from '@/components/CustomTooltip';
import { Enum, PATH } from '@/constants';
import { BREAKPOINT, ChartAnalyticType, CriteriaType, OtherCountries, RiskLevel } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockListSlice from '@/redux/slice/blockList.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import { IResponseApi } from '@/types/api/response.api';
import { Badge, BlockStack, Button, ButtonGroup, Card, InlineGrid } from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { TopFiveListStyled } from './styled';
import TopFiveItem from './TopFiveItem';
import { useTranslation } from 'react-i18next';

type TopFiveTypes = 'Browser' | 'OS' | 'ISP';

const TopFiveList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['visitor_analytics', 'common']);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.LG });
  const allAccess = useSelector(allAccessSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [browserState, setBrowserState] = useState(RiskLevel.All);
  const [osState, setOsState] = useState(RiskLevel.All);
  const [ispState, setIspState] = useState(RiskLevel.All);

  const topHighRiskBrowsers = apiCaller.useGetTopHighRiskQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      riskLevel: browserState,
      chartType: ChartAnalyticType.Browser,
    },
    { skip: isSkip },
  );

  const topHighRiskOS = apiCaller.useGetTopHighRiskQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      riskLevel: osState,
      chartType: ChartAnalyticType.OS,
    },
    { skip: isSkip },
  );

  const topHighRiskProviders = apiCaller.useGetTopHighRiskQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      riskLevel: ispState,
      chartType: ChartAnalyticType.Provider,
    },
    { skip: isSkip },
  );
  const { userPlanFree } = UserPlans();
  const [activeButtonIndices, setActiveButtonIndices] = useState({
    Browser: 0,
    OS: 0,
    ISP: 0,
  });
  const handleButtonClick = useCallback((index: number, type: TopFiveTypes) => {
    if (type === 'Browser') {
      setBrowserState(index === 1 ? RiskLevel.Risky : RiskLevel.All);
    } else if (type === 'OS') {
      setOsState(index === 1 ? RiskLevel.Risky : RiskLevel.All);
    } else if (type === 'ISP') {
      setIspState(index === 1 ? RiskLevel.Risky : RiskLevel.All);
    }
    setActiveButtonIndices((prevState) => ({
      ...prevState,
      [type]: index,
    }));
  }, []);

  const handleItemClick = (criteria: CriteriaType, data: IResponseApi.ITopFive, type: TopFiveTypes) => {
    const payload = {
      isActive: true,
      priority: Enum.ListType.BlackList,
      type: Enum.ActionType.Block,
      criteria,
      country: [OtherCountries.All],
      referUrl: '',
      shortReferUrl: '',
      collectionId: [],
      state: [],
      city: [],
      ipAddress: '',
      ispName: type === 'ISP' ? [data.type] : [],
      ispCode: type === 'ISP' ? [data?.code || ''] : [],
      productId: [],
      pageId: [],
      deviceType: '',
      osName: type === 'OS' ? data.type.split(' ')[0] : '',
      browserName: type === 'Browser' ? [data.type] : [],
    };

    dispatch(blockListSlice.actions.handleSettingEdit(payload));
    navigate(PATH.BLOCK_PAGE, {
      state: {
        prePath: PATH.ANALYTICS,
      },
    });
  };

  const handleRedirectPricingPlan = () => {
    navigate(PATH.PRICING_PLAN);
  };

  const sections: {
    title: string | React.ReactElement;
    data: IResponseApi.ITopFive[];
    criteria: CriteriaType;
    type: TopFiveTypes;
  }[] = [
    {
      title: t('visitor_analytics:top_5_browsers'),
      data: topHighRiskBrowsers.data?.data?.topBrowser || [],
      criteria: CriteriaType.UserAgent,
      type: 'Browser',
    },
    {
      title: isMobile ? (
        <div className="d-flex">
          {t('visitor_analytics:top_5_os')}
          <div className="ml-4">
            <CustomTooltip activator={<Button icon={InfoIcon} variant="plain" />}>
              {t('visitor_analytics:top_5_operating_systems')}
            </CustomTooltip>
          </div>
        </div>
      ) : (
        t('visitor_analytics:top_5_operating_systems')
      ),
      data: topHighRiskOS.data?.data?.topOS || [],
      criteria: CriteriaType.UserAgent,
      type: 'OS',
    },
    {
      title: isMobile ? (
        <div className="d-flex">
          {t('visitor_analytics:top_5_isp')}
          <div className="ml-4">
            <CustomTooltip activator={<Button icon={InfoIcon} variant="plain" />}>
              {t('visitor_analytics:top_5_internet_service_providers')}
            </CustomTooltip>
          </div>
        </div>
      ) : (
        t('visitor_analytics:top_5_internet_service_providers')
      ),
      data: topHighRiskProviders.data?.data?.topProvider || [],
      criteria: CriteriaType.ISP,
      type: 'ISP',
    },
  ];

  return (
    <TopFiveListStyled>
      <Card>
        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }} gap="400">
          {sections.map(({ title, data, type, criteria }) =>
            userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
              <div key={type} className="hover-card">
                <Card>
                  <div className="va-top-high-risk-title">
                    <div className="d-flex">
                      <div>{title}</div>
                      <div className="ml-4">
                        <Badge tone="info">{t('visitor_analytics:paid_plan')}</Badge>
                      </div>
                    </div>
                    <div className="group-btn" style={{ maxHeight: '30px' }}>
                      <ButtonGroup variant="segmented">
                        <Button pressed={activeButtonIndices[type] === 0}>{t('visitor_analytics:all')}</Button>
                        <Button pressed={activeButtonIndices[type] === 1}>{t('visitor_analytics:high_risk')}</Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <BlockStack gap="200">
                    {data.map((item) => (
                      <TopFiveItem key={item.type} data={item} onClick={() => handleItemClick(criteria, item, type)} />
                    ))}
                  </BlockStack>
                </Card>
                <div className="overlay">
                  <Button onClick={handleRedirectPricingPlan}>{t('common:upgrade')}</Button>
                </div>
              </div>
            ) : (
              <div className="hover-card" key={type}>
                <Card>
                  <div className="va-top-high-risk-title">
                    <div className="d-flex">
                      <div>{title}</div>
                      {userPlanFree && !dataSettings?.settings.enableVisitorAnalytics ? (
                        <div className="ml-4">
                          <Badge tone="enabled">{t('visitor_analytics:demo')}</Badge>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <ButtonGroup variant="segmented">
                        <Button pressed={activeButtonIndices[type] === 0} onClick={() => handleButtonClick(0, type)}>
                          {t('visitor_analytics:all')}
                        </Button>
                        <Button pressed={activeButtonIndices[type] === 1} onClick={() => handleButtonClick(1, type)}>
                          {t('visitor_analytics:high_risk')}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <BlockStack gap="200">
                    {data.map((item) => (
                      <TopFiveItem key={item.type} data={item} onClick={() => handleItemClick(criteria, item, type)} />
                    ))}
                  </BlockStack>
                </Card>
              </div>
            ),
          )}
        </InlineGrid>
      </Card>
    </TopFiveListStyled>
  );
};

export default TopFiveList;

import { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  IconProps,
  InlineGrid,
  InlineStack,
  Text,
  TextProps,
} from '@shopify/polaris';
import { AlertTriangleIcon, CheckIcon, ShieldCheckMarkIcon, XIcon } from '@shopify/polaris-icons';
import { BadgeProps } from '@shopify/polaris/build/ts/src/components/Badge';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PromotionsCardStyled } from './styled';
import { useTranslation } from 'react-i18next';

interface promotionsItemDataProps {
  title: {
    text: string;
    tone: TextProps['tone'];
  };
  iconTitle: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tone: IconProps['tone'];
  };
  content: string;
  subContent: {
    items: string[];
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tone: IconProps['tone'];
  };
  badge?: {
    text: string;
    tone: BadgeProps['tone'];
  };
  additionalText: string;
  button?: {
    text: string;
    link: string;
  };
}

const PromotionsCard = () => {
  const banner = useSelector(bannerSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard']);

  const promotionsData: promotionsItemDataProps[] = [
    {
      title: {
        text: t('dashboard:you_might_not_know_this'),
        tone: 'success',
      },
      iconTitle: {
        icon: AlertTriangleIcon,
        tone: 'warning',
      },
      content: t('dashboard:did_you_know_paypal_stripe_funds_limit'),
      subContent: {
        items: [
          t('dashboard:they_can_hold_funds_21_days'),
          t('dashboard:limit_your_accounts'),
          t('dashboard:ban_your_account_permanently'),
        ],
        icon: AlertTriangleIcon,
        tone: 'warning',
      },
      additionalText: t('dashboard:paypal_or_stripe_order_check'),
    },
    {
      title: {
        text: t('dashboard:strong_account'),
        tone: 'success',
      },
      iconTitle: {
        icon: ShieldCheckMarkIcon,
        tone: 'success',
      },
      badge: {
        text: t('dashboard:verified_by_paypal'),
        tone: 'success',
      },
      content: t('dashboard:strong_paypal_or_stripe_account'),
      subContent: {
        items: [
          t('dashboard:no_random_holds_or_bans'),
          t('dashboard:fewer_chargebacks_and_holds'),
          t('dashboard:better_relationship_paypal_stripe'),
        ],
        icon: CheckIcon,
        tone: 'success',
      },
      additionalText: t('dashboard:automate_delivery_paypal'),
      button: {
        text: t('dashboard:yes_help_strengthen_account'),
        link: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
      },
    },
  ];

  const handleDismissBanner = () => {
    dispatch(
      handleVisibleBanner({
        key: 'bannerPromotionSynctrack',
        value: false,
      }),
    );
  };

  return (
    <Collapsible
      id=""
      open={banner.bannerPromotionSynctrack}
      expandOnPrint
      transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
    >
      <PromotionsCardStyled>
        <div className="dismiss-promotion">
          <Button icon={XIcon} variant="tertiary" onClick={handleDismissBanner} />
        </div>
        <Card>
          <div className="mb-16">
            <Text as="h3" variant="headingMd">
              {t('dashboard:suggested_by_experts')}
            </Text>
          </div>

          <InlineGrid gap="400" columns={{ xs: 1, md: 2 }}>
            {promotionsData.map((item, idx) => (
              <Card key={item.title.text}>
                <BlockStack gap="300">
                  <InlineStack align="space-between" blockAlign="center" gap="050">
                    <InlineStack gap="050">
                      <Icon source={item.iconTitle.icon} tone={item.iconTitle.tone} />

                      <InlineStack align="center" gap="050" blockAlign="center">
                        <Text id={idx === 0 ? 'warning' : ''} as="h4" variant="headingMd" tone={item.title.tone}>
                          {item.title.text}
                        </Text>
                      </InlineStack>
                    </InlineStack>
                    {item?.badge && <Badge tone={item.badge.tone}>{item.badge.text}</Badge>}
                  </InlineStack>

                  <Box paddingInlineStart="500">
                    <BlockStack gap="200">
                      <Text as="h6" variant="headingMd">
                        {item.content}
                      </Text>
                      {item.subContent.items.map((sub) => (
                        <div key={sub} style={{ display: 'flex', gap: '5px' }}>
                          <Icon source={item.subContent.icon} tone={item.subContent.tone} />
                          <div style={{ flex: 1 }}>
                            <Text as="span">{sub}</Text>
                          </div>
                        </div>
                      ))}

                      <Text as="span">{item.additionalText}</Text>
                    </BlockStack>
                  </Box>
                  {item?.button && (
                    <Button onClick={() => window.open(item.button?.link, '_blank')} size="large">
                      {item.button?.text}
                    </Button>
                  )}
                </BlockStack>
              </Card>
            ))}
          </InlineGrid>
        </Card>
      </PromotionsCardStyled>
    </Collapsible>
  );
};

export default PromotionsCard;

import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { IDScrollIntoView, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardContentProtectionStyled } from '../../styled';
import userPlans from '@/hooks/userPlans';
import ScrollInToView from '@/components/ScrollInToView';
import SettingSwitch from '@/components/SettingSwitch';
import { useTranslation } from 'react-i18next';

const ContentProtection = () => {
  const { t } = useTranslation(['settings', 'common']);
  const dispatch = useDispatch();
  const { shopifyPlanPlus, userPlanPremium } = userPlans();

  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);

  const [activeContentProtection, contentProtectionStatus] = apiCaller.useActiveContentProtectionMutation();
  const [activeBehavior, activeBehaviorStatus] = apiCaller.useActiveBehaviorMutation();

  const [buttonClick, setButtonClick] = useState('');

  const handleProtectText = useCallback(() => {
    const textProtection: IParamsApi.IContentProtection = {
      protectContents: !dataSettings?.settings.protectContents,
    };
    activeContentProtection(textProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('1');
  }, [activeContentProtection, dataSettings?.settings.protectContents, dispatch, t]);

  const disableRightClick = useCallback(() => {
    const rightClickProtection: IParamsApi.IContentProtection = {
      disableRightClick: !dataSettings?.settings.disableRightClick,
    };

    activeBehavior(rightClickProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('2');
  }, [activeBehavior, dataSettings?.settings.disableRightClick, dispatch, t]);

  const disableKeyboardShortcut = useCallback(() => {
    const keywordShotcutProtection: IParamsApi.IContentProtection = {
      disableKeyboardShortcut: !dataSettings?.settings.disableKeyboardShortcut,
    };

    activeBehavior(keywordShotcutProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('3');
  }, [activeBehavior, dataSettings?.settings.disableKeyboardShortcut, dispatch, t]);

  const handleDisableInspect = useCallback(() => {
    const disableInspectProtection: IParamsApi.IContentProtection = {
      disableInspect: !dataSettings?.settings.disableInspect,
    };

    activeBehavior(disableInspectProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
      }
    });
    setButtonClick('4');
  }, [activeBehavior, dataSettings?.settings.disableInspect, dispatch, t]);

  return dataSettings ? (
    <CardContentProtectionStyled>
      <ScrollInToView idScrollToBlock={IDScrollIntoView.Protect_content}>
        <SettingSwitch
          trackAction="setting_protect_content"
          title={t('settings:protect_content')}
          minActivePlan={shopifyPlanPlus ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.PREMIUM}
          settingToggleProps={{
            action: {
              onAction: handleProtectText,
              loading: contentProtectionStatus.isLoading || (isLoading && buttonClick === '1'),
            },
            enabled: disablePlan([UserPlan.FREE]) ? false : dataSettings.settings.protectContents,
          }}
        >
          {t('settings:prevent_competitors_select_content')}
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Deactivate_right_click} className="mt-16">
        <SettingSwitch
          trackAction="setting_deactivate_right_click"
          title={t('settings:deactivate_right_click')}
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: {
              onAction: disableRightClick,
              loading: (activeBehaviorStatus.isLoading || isLoading) && buttonClick === '2',
            },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings.settings.disableRightClick,
          }}
        >
          {t('settings:prevent_competitors_copying')}
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Deactivate_copy_shortcut} className="mt-16">
        <SettingSwitch
          trackAction="setting_deactivate_shortcut"
          title={t('settings:deactivate_shortcut')}
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: {
              onAction: disableKeyboardShortcut,
              loading: (activeBehaviorStatus.isLoading || isLoading) && buttonClick === '3',
            },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings.settings.disableKeyboardShortcut,
          }}
        >
          <RegularText>
            {t('settings:prevent_competitors_copying_keyboard_shortcut')}
            <br />
            (Ctrl + S, Ctrl + X, Ctrl + A, Ctrl + C, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + Shift + C, Ctrl + U, F12).
          </RegularText>
        </SettingSwitch>
      </ScrollInToView>

      <ScrollInToView idScrollToBlock={IDScrollIntoView.Deactivate_inspect} className="mt-16">
        <SettingSwitch
          trackAction="setting_deactivate_inspect"
          title={t('settings:deactivate_inspect')}
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: {
              onAction: handleDisableInspect,
              loading: (activeBehaviorStatus.isLoading || isLoading) && buttonClick === '4',
            },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings.settings.disableInspect,
          }}
        >
          {t('settings:prevent_competitors_inspecting_store')}
        </SettingSwitch>
      </ScrollInToView>
    </CardContentProtectionStyled>
  ) : null;
};
export default memo(ContentProtection);

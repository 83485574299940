import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { Modal, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
interface Props {
  isOpen: boolean;
  handleClose: () => void;
}
export const DiscountCode = ({ ...props }: Props) => {
  const { t } = useTranslation(['pricing', 'common']);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    discountCode: '',
    errorDiscount: '',
  });
  const [discountMutation, { isLoading }] = apiCaller.useApplyDiscountCodeMutation();
  const applyDiscountCode = () => {
    discountMutation(state.discountCode).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setState({ ...state, errorDiscount: t(condition.msg) });
      } else {
        dispatch(slice.toastSlice.actions.handleToast(handleToastMutation(res, t)));
        setState({ errorDiscount: '', discountCode: '' });
        props.handleClose();
      }
    });
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      title={t('pricing:apply_discount_code')}
      primaryAction={{
        content: t('pricing:apply'),
        onAction: applyDiscountCode,
        disabled: state.discountCode === '',
        loading: isLoading,
      }}
    >
      <Modal.Section>
        <TextField
          autoComplete="off"
          label={`${t('pricing:discount_code')}:`}
          helpText={
            <span>
              <b>{t('pricing:note')}: </b>
              <p style={{ display: 'inline' }}>{t('pricing:discount_code_exclusion_yearly_plans')}</p>
            </span>
          }
          value={state.discountCode}
          onChange={(value) => {
            setState({
              ...state,
              discountCode: value,
              errorDiscount: '',
            });
          }}
          onBlur={() => {
            setState({
              ...state,
              errorDiscount: state.discountCode === '' ? t('pricing:not_entered_discount_code') : '',
            });
          }}
          error={state.errorDiscount}
        />
      </Modal.Section>
    </Modal>
  );
};

import { EmptyState } from '@shopify/polaris';
import RegularText from '../RegularText';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
const CustomEmptyState = () => {
  const { t } = useTranslation(['common']);
  return (
    <EmptyState heading={t('common:no_item')} image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
      <RegularText>{t('common:try_changing_the_filters_or_search_term')}</RegularText>
    </EmptyState>
  );
};

export default memo(CustomEmptyState);

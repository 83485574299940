import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { Enum, PATH } from '@/constants';
import { urlRickScore } from '@/constants/link';
import { dateToTimeStamp, findIPRange, formatDate, secondToTime } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import visitorLogSlice, {
  allAccessSelector,
  perPageSelector,
  visitorDetailSelector,
  visitorLogSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import {
  Badge,
  BadgeProps,
  Icon,
  IndexTable,
  Link,
  Modal,
  Pagination,
  SkeletonBodyText,
  Text,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { DisabledIcon, DuplicateIcon } from '@shopify/polaris-icons';
import { memo, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TableVisitorLog = (): JSX.Element => {
  const { t } = useTranslation(['common', 'visitor_analytics']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCountry = useCountry();

  const visitorLogTable = useSelector(visitorLogSelector);
  const visitorDetail = useSelector(visitorDetailSelector);
  const perPage = useSelector(perPageSelector);
  const allAccess = useSelector(allAccessSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);

  const { userPlanFree } = userPlans();

  const [confirmBlock, setConfirmBlock] = useState({
    status: false,
    ip: '',
  });
  const [state, setState] = useState({
    copied: false,
  });

  const [trackAction] = apiCaller.useTrackActionMutation();
  const [addBlackList, { isLoading }] = apiCaller.useUpsertRuleMutation();
  const { data, isFetching } = apiCaller.useFetchVisitorListQuery(
    {
      ...visitorLogTable,
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      perPage,
    },
    { skip: isSkip },
  );

  const resourceName = {
    singular: 'visitor',
    plural: 'visitors',
  };

  const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = useMemo(
    () => [
      {
        score: [-1, Enum.RiskLevelScore.Safe],
        tone: 'success',
        tooltip: t('visitor_analytics:low_risk_ip'),
      },
      {
        score: [Enum.RiskLevelScore.Safe, Enum.RiskLevelScore.Risky],
        tone: 'warning',
        tooltip: t('visitor_analytics:high_risk_ip'),
      },
      {
        score: [Enum.RiskLevelScore.Risky, Enum.RiskLevelScore.Dangerous],
        tone: 'critical',
        tooltip: t('visitor_analytics:dangerous_ip'),
      },
    ],
    [t],
  );

  const items = useMemo(() => {
    return data?.listIp?.map((visitorLogItem) => {
      const handleCopyIPAdress = () => {
        navigator.clipboard.writeText(visitorLogItem.ip || '').then(() => {
          setState({ ...state, copied: true });
          dispatch(
            slice.toastSlice.actions.handleToast({
              isOpen: true,
              content: t('common:copied'),
              error: false,
            }),
          );
        });
      };
      const riskScore = visitorLogItem.risk;
      const isVpn: boolean = visitorLogItem.isVpn;

      let badgeRisk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }

      return {
        id: visitorLogItem.ip,
        ipBlocked: (
          <span className="d-flex address-container">
            <RegularText>
              {visitorLogItem.ip}
              <br />
              {visitorLogItem.ipv4 ? (
                <>
                  <Text variant="bodySm" as="span">
                    IPv4: {visitorLogItem.ipv4}
                  </Text>
                  <br />
                </>
              ) : null}
              {findIPRange(visitorLogItem.ip) !== null ? (
                <Text variant="bodySm" as="span">
                  {`${t('common:ip_range')}: ${findIPRange(visitorLogItem.ip)}`}
                </Text>
              ) : null}
            </RegularText>
            <span className="ml-8 pointer">
              <Tooltip content={t('common:copy')}>
                <span
                  className="btn-copy"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyIPAdress();
                  }}
                >
                  <Icon source={DuplicateIcon} />
                </span>
              </Tooltip>
            </span>
          </span>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(visitorLogItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={visitorLogItem.countryCode} />
          </Tooltip>
        ),
        city: (
          <RegularText>{[visitorLogItem.regionName, visitorLogItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>
        ),
        totalAccess: (
          <p style={{ width: '4rem' }}>
            <RegularText>
              <Link
                removeUnderline
                onClick={() => {
                  dispatch(
                    slice.visitorAnalyticsSlice.actions.handleVisitorsDetailTable({
                      ...visitorDetail,
                      perPage: '10',
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                  navigate({
                    ...PATH.VISITORS_DETAIL,
                    pathname: `/analytics/visitor/${visitorLogItem.ip}`,
                  });
                }}
              >
                {visitorLogItem.totalAccess}
              </Link>
            </RegularText>
          </p>
        ),
        averageDuration: <RegularText>{secondToTime(Math.ceil(visitorLogItem?.averageDuration))}</RegularText>,
        lastSeenOn: <RegularText>{formatDate(visitorLogItem.lastSeenOn)}</RegularText>,
        ispName:
          userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
            '---'
          ) : (
            <RegularText>{visitorLogItem.provider || '---'}</RegularText>
          ),
        zipCode: <RegularText>{visitorLogItem.zipCode || '---'}</RegularText>,
        risk: (
          <RegularText>
            {userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
              <div
                className="pointer text-decoration"
                onClick={() => {
                  navigate(PATH.PRICING_PLAN);
                  trackAction('visitor_analytic_table');
                }}
              >
                <Badge tone="warning">{t('common:needs_upgrade')}</Badge>
              </div>
            ) : (
              <Tooltip content={badgeRisk?.tooltip}>
                <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
              </Tooltip>
            )}
          </RegularText>
        ),
        vpnStatus:
          userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
            <Tooltip content={t('common:available_on_higher_plan')}>---</Tooltip>
          ) : (
            <Badge tone={isVpn ? 'warning' : 'success'}>{isVpn ? t('common:yes') : t('common:no')}</Badge>
          ),
        status:
          visitorLogItem.status === 'allow' ? (
            <Badge tone="success">{t('common:allow')}</Badge>
          ) : (
            <Badge tone="critical">{t('common:block')}</Badge>
          ),
        action:
          visitorLogItem.status === 'block' || !dataSettings?.settings.enableVisitorAnalytics ? (
            <Tooltip dismissOnMouseOut content="Blocked">
              <div className="btn-disable">
                <Icon tone="subdued" source={DisabledIcon} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip dismissOnMouseOut content={t('visitor_analytics:add_to_blacklist')}>
              <div
                className="btn-disable pointer"
                onClick={(e) => {
                  setConfirmBlock({
                    status: true,
                    ip: visitorLogItem.ip,
                  });
                }}
              >
                <Icon tone="critical" source={DisabledIcon} />
              </div>
            </Tooltip>
          ),
      };
    });
  }, [
    data?.listIp,
    handleCountry,
    userPlanFree,
    dataSettings?.settings.enableVisitorAnalytics,
    t,
    state,
    dispatch,
    badgeRiskConfig,
    visitorDetail,
    navigate,
    trackAction,
  ]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  const promotedBulkActions = [
    {
      content: t('common:cancel'),
      onAction: () => clearSelection(),
    },
    {
      content: t('visitor_analytics:add_ip_to_blacklist'),
      onAction: () =>
        setConfirmBlock({
          status: true,
          ip: '',
        }),
    },
  ];

  const rowMarkup = useMemo(() => {
    return items?.map(
      (
        { id, ipBlocked, action, city, country, lastSeenOn, risk, status, totalAccess, ispName, vpnStatus, averageDuration },
        index,
      ) => (
        <IndexTable.Row selected={selectedResources.includes(id)} id={id} key={id} position={index}>
          <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
          <IndexTable.Cell>{country}</IndexTable.Cell>
          <IndexTable.Cell>{city}</IndexTable.Cell>
          <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
          <IndexTable.Cell>{averageDuration}</IndexTable.Cell>
          <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
          <IndexTable.Cell>{ispName}</IndexTable.Cell>
          <IndexTable.Cell id="risk-score">{risk}</IndexTable.Cell>
          <IndexTable.Cell id="vpn-status">{vpnStatus}</IndexTable.Cell>
          <IndexTable.Cell>{status}</IndexTable.Cell>
          <IndexTable.Cell>{action}</IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  }, [items, selectedResources]);

  const handleAddIps = async () => {
    try {
      const res: any = await addBlackList({
        type: Enum.ActionType.Block,
        criteria: Enum.CriteriaType.IpAddress,
        ipAddress: confirmBlock.ip ? confirmBlock.ip : selectedResources,
        priority: Enum.ListType.BlackList,
        isActive: true,
      });

      dispatch(
        slice.toastSlice.actions.handleToast({
          isOpen: true,
          content: (t(res?.data?.msg, res?.data?.key) as string) || t('failed'),
          error: false,
        }),
      );
      clearSelection();
      setConfirmBlock({ ip: '', status: false });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>{t('visitor_analytics:ip_address')}</RegularText>, id: '1' },
          { title: <RegularText>{t('visitor_analytics:country')}</RegularText>, id: '2' },
          { title: <RegularText>{t('visitor_analytics:state_province_city')}</RegularText>, id: '3' },
          { title: <RegularText>{t('visitor_analytics:total_access')}</RegularText>, id: '4' },
          { title: <RegularText>{t('visitor_analytics:average_durations')}</RegularText>, id: '5' },
          {
            title: <RegularText>{t('visitor_analytics:last_seen_on')}</RegularText>,
            id: '6',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>{t('visitor_analytics:internet_provider')}</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '7',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>{t('visitor_analytics:risk_score')}</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: 'risk-score-header',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>{t('visitor_analytics:vpn_proxy')}</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: 'vpn-header',
          },
          { title: <RegularText>{t('visitor_analytics:status')}</RegularText>, id: '8' },
          { title: <RegularText>{t('visitor_analytics:action')}</RegularText>, id: '9' },
        ]}
        selectable={!userPlanFree}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={promotedBulkActions}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>

      <div className="mt-16 mb-16 table-pagination">
        <>
          {data && data?.meta.totalItems > 0 ? (
            <Pagination
              label={
                data?.meta.totalItems
                  ? t('common:showing_visitors_range', {
                      from: (visitorLogTable.page - 1) * Number(perPage) + 1,
                      to: Math.min(visitorLogTable.page * Number(perPage), data?.meta.totalItems),
                      total: data?.meta.totalItems,
                    })
                  : null
              }
              hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
              onPrevious={() => {
                dispatch(
                  visitorLogSlice.actions.handleVisitorLogTable({
                    ...visitorLogTable,
                    page: visitorLogTable.page - 1,
                  }),
                );
              }}
              hasNext={
                data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching
              }
              onNext={() => {
                dispatch(
                  visitorLogSlice.actions.handleVisitorLogTable({
                    ...visitorLogTable,
                    page: visitorLogTable.page + 1,
                  }),
                );
              }}
            />
          ) : null}
        </>
      </div>

      <Modal
        onClose={() => setConfirmBlock({ ip: '', status: false })}
        open={confirmBlock.status}
        title={t('visitor_analytics:block_ip')}
        sectioned
        secondaryActions={[
          {
            content: t('common:cancel'),
            onAction: () => setConfirmBlock({ ip: '', status: false }),
          },
        ]}
        primaryAction={{
          loading: isLoading,
          content: t('common:block'),
          onAction: handleAddIps,
        }}
      >
        <RegularText>{t('visitor_analytics:add_ip_blacklist')}</RegularText>
      </Modal>
    </div>
  );
};

export default memo(TableVisitorLog);

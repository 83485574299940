import { ListType } from '@/constants/enum';
import options, { perPageOptions } from '@/constants/options';
import blockCheckoutAccessSlice, { blackListTableSelector } from '@/redux/slice/blockCheckoutAccess';
import { ChoiceList, ChoiceListProps } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const BlackListFilter = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const blackListTable = useSelector(blackListTableSelector);

  const filters = [
    {
      key: 'priority',
      label: t('common:priority'),
      filter: (
        <ChoiceList
          title={t('common:priority')}
          titleHidden
          choices={[
            { label: t('common:all'), value: '' },
            { label: t('common:block'), value: ListType.BlackList },
            { label: t('common:whitelist'), value: ListType.WhiteList },
          ]}
          selected={[blackListTable?.priority || '']}
          onChange={(value) => {
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                priority: (value[0] as ListType) || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: t('common:criteria'),
      filter: (
        <ChoiceList
          title={t('common:criteria')}
          titleHidden
          choices={
            [
              { label: t('common:all'), value: '' },
              ...options.criteriaFilters(t).filter((item) => !item.hiddenBlockAccess),
            ] as ChoiceListProps['choices']
          }
          selected={[blackListTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                criteria: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: t('common:per_page'),
      filter: (
        <ChoiceList
          title={t('common:per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[blackListTable.perPage]}
          onChange={(value) =>
            dispatch(
              blockCheckoutAccessSlice.actions.handleBlackListTable({
                ...blackListTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockCheckoutAccessSlice.actions.handleBlackListTable({
            ...blackListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `${t('common:criteria')}: ${
        options.criteriaFilters(t).find((option) => option.value === blackListTable.criteria)?.label || t('common:all')
      }`,
      hidden: !blackListTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          blockCheckoutAccessSlice.actions.handleBlackListTable({
            ...blackListTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: t('common:record_per_page', { perPage: blackListTable.perPage }),
    },
    {
      onRemove: () =>
        dispatch(
          blockCheckoutAccessSlice.actions.handleBlackListTable({
            ...blackListTable,
            priority: '',
            page: 1,
          }),
        ),
      key: 'priority',
      label: `Priority: ${
        blackListTable?.priority === ListType.BlackList
          ? t('common:block')
          : blackListTable?.priority === ListType.WhiteList
          ? t('common:whitelist')
          : t('common:all')
      } `,
      hidden: !blackListTable.priority,
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};

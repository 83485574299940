import BoldText from '@/components/BoldText';
import CustomBadge from '@/components/CustomBadge';
import HelpCenter from '@/components/HelpCenter';
import Layout from '@/components/layout';
import ModalConfirm from '@/components/ModalConfirm';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType, IDScrollIntoView, IPTypes, ListType } from '@/constants/enum';
import { checkShowErrorInline, formatDate, handleToastMutation, removeFalsyValues, validateUrl } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import blockListSlice, {
  errorCSVSelector,
  fileDefaultSelector,
  handleErrorCSV,
  handleErrorRule,
  handleSetting,
  settingBackupSelector,
  settingBockListInitial,
  settingSelector,
} from '@/redux/slice/blockList.slice';
import toastSlice from '@/redux/slice/toast.slice';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  InlineGrid,
  LegacyStack,
  Link,
  List,
  RadioButton,
  Text,
  TextField,
} from '@shopify/polaris';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import useCondition from '../../hooks/useCondition';
import useErrorRule from '../../hooks/useErrorRule';
import { BlockPageStyled } from './styled';
import { ContextualBar } from '@/components/ContextualBar';
import { isEqual, toLower } from 'lodash';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import useContextual from '@/components/ContextualBar/HideContextual';
import { useTranslation } from 'react-i18next';
const BlockPage = () => {
  const { t } = useTranslation(['common', 'block_redirect']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useError = useErrorRule();
  const handleCountry = useCountry();
  const conditionHook = useCondition();
  const { state } = useLocation();
  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = UserPlans();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const contextual = useContextual();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [linkRedirectError, setLinkRedirectError] = useState('');
  const blockRuleSelected = useSelector(settingSelector);
  const blockRuleBackupSelected = useSelector(settingBackupSelector);
  const isShowContextualBar = useSelector(isShowSelector);
  const errorCSV = useSelector(errorCSVSelector);
  const fileDefault = useSelector(fileDefaultSelector);
  const [upsertRule, upsertRuleStatus] = apiCaller.useUpsertRuleMutation();
  const [uploadIpCSV, uploadIpCSVStatus] = apiCaller.useUploadIpCSVMutation();
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();

  const condition = conditionHook.condition.find((item) =>
    item.groupCriteria.includes(blockRuleSelected.criteria as CriteriaType),
  );
  const listCriteria = conditionHook.listCriteria.filter((item) => condition?.groupCriteria.includes(item.value));
  const criteriaSelected = listCriteria.find((item) => item.value === blockRuleSelected.criteria);

  useEffect(() => {
    if (!criteriaSelected) {
      navigate(PATH.BLOCKLIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!criteriaSelected]);

  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | boolean | number) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };

  const handleDisabled = useMemo(() => {
    const fieldsRequired = condition?.fieldsRequired || [];

    const disabled = !!fieldsRequired.find((field) => {
      const value = blockRuleSelected[field as keyof typeof blockRuleSelected];
      if (blockRuleSelected.type === ActionType.Redirect) {
        if (fileDefault) {
          return (
            !blockRuleSelected.linkRedirect ||
            (blockRuleSelected.linkRedirect && !validateUrl(blockRuleSelected.linkRedirect)) ||
            useError.listError.length > 0 ||
            !!errorCSV
          );
        }
        return (
          (Array.isArray(value) && value.length === 0) ||
          !value ||
          !blockRuleSelected.linkRedirect ||
          (blockRuleSelected.linkRedirect && !validateUrl(blockRuleSelected.linkRedirect)) ||
          useError.listError.length > 0
        );
      }
      if (
        fileDefault &&
        (blockRuleSelected.criteria === CriteriaType.IpAddress || blockRuleSelected.criteria === CriteriaType.IpAddressStartWith)
      ) {
        return !!errorCSV;
      }
      return (Array.isArray(value) && value.length === 0) || !value || useError.listError.length > 0;
    });

    return disabled || isEqual(blockRuleSelected, blockRuleBackupSelected) || !!errorCSV;
  }, [blockRuleBackupSelected, blockRuleSelected, condition?.fieldsRequired, useError.listError.length, errorCSV, fileDefault]);

  const isDisabledStatus =
    (userPlanFree &&
      [
        CriteriaType.Collection,
        CriteriaType.Product,
        CriteriaType.UserAgent,
        CriteriaType.SpecificPage,
        CriteriaType.ISP,
        CriteriaType.ReferralLink,
      ].includes(blockRuleSelected.criteria as CriteriaType)) ||
    (userPlanPremium && [CriteriaType.ISP, CriteriaType.ReferralLink].includes(blockRuleSelected.criteria as CriteriaType));
  const isDisabledRedirect =
    (userPlanFree &&
      [
        CriteriaType.Collection,
        CriteriaType.Product,
        CriteriaType.UserAgent,
        CriteriaType.IpRanges,
        CriteriaType.Province,
        CriteriaType.SpecificPage,
        CriteriaType.ISP,
        CriteriaType.ReferralLink,
      ].includes(blockRuleSelected.criteria as CriteriaType)) ||
    (userPlanPremium &&
      [CriteriaType.ISP, CriteriaType.ReferralLink, CriteriaType.IpRanges].includes(blockRuleSelected.criteria as CriteriaType));

  const handleSave = async () => {
    if (fileDefault) {
      const formData = new FormData();
      formData.append('csvFile', fileDefault);
      formData.append('criteria', blockRuleSelected.criteria);
      formData.append('priority', blockRuleSelected.priority);
      formData.append('type', blockRuleSelected.type);
      formData.append('identifierId', config.shop);
      formData.append('isActive', String(blockRuleSelected.isActive));
      formData.append('overrideVpn', String(blockRuleSelected.overrideVpn));
      formData.append('linkRedirect', String(blockRuleSelected.linkRedirect));
      formData.append('shortUrl', String(blockRuleSelected.shortUrl));

      try {
        uploadIpCSV(formData).then((res) => {
          if ('data' in res) {
            if (res.data.state === 1) {
              navigate(PATH.BLOCKLIST);
              dispatch(blockListSlice.actions.clearSetting());
            } else {
              let error: string | React.ReactElement | undefined;
              if (res.data?.url) {
                error = (
                  <Text as="span" variant="bodyMd" tone="critical">
                    {t('common:invalid_format_or_existing_values')}
                    <Link url={res.data?.url}>{t('common:here')}</Link>
                  </Text>
                );
              } else if (res.data?.failureRules) {
                error = (
                  <div>
                    {res.data?.failureRules?.map((item) => (
                      <p key={item.errValue}>
                        <b>{item.errValue}: </b>
                        {t(item.errMsg)}
                      </p>
                    ))}
                  </div>
                );
              } else {
                error = <div>{t(res.data?.msg)}</div>;
              }
              dispatch(blockListSlice.actions.handleErrorCSV(error));
            }
          }
          const condition = checkShowErrorInline(res);
          if (!condition.status) {
            dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { isActive, ...data } = blockRuleSelected;
        const dataRemovedFalsyValues = removeFalsyValues(data);
        upsertRule({
          ...dataRemovedFalsyValues,
          type: blockRuleSelected.type,
          priority: blockRuleSelected.priority,
          criteria: blockRuleSelected.criteria as CriteriaType,
          isActive,
          overrideVpn:
            !userPlanFree && blockRuleSelected.priority === ListType.WhiteList ? blockRuleSelected.overrideVpn || 0 : undefined,
          createdAt: undefined,
          ipRangeType: blockRuleSelected.ipRangeType,
        }).then((res) => {
          if ('data' in res) {
            if (res.data.state === 1) {
              navigate(PATH.BLOCKLIST);
              dispatch(blockListSlice.actions.clearSetting());
            } else {
              let error: string | React.ReactElement | undefined;
              if (res.data?.url) {
                error = (
                  <Text as="span" variant="bodyMd" tone="critical">
                    {t('common:invalid_format_or_existing_values')} <Link url={res.data?.url}>{t('common:here')}</Link>
                  </Text>
                );
              } else {
                error = (
                  <div>
                    {res.data?.failureRules?.map((item) => (
                      <p key={item?.errValue}>
                        <b>{item?.errValue}: </b>
                        {t(item?.errMsg)} {t('common:try_to_add_another')} {criteriaSelected?.fieldName}.
                      </p>
                    ))}
                  </div>
                );
              }
              useError.handleErrorRule(error);
            }
          }
          const condition = checkShowErrorInline(res);
          if (!condition.status) {
            dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDiscard = () => {
    dispatch(handleSetting(blockRuleBackupSelected));
    dispatch(handleErrorRule([]));
    dispatch(handleErrorCSV(''));
  };

  const handleDelete = useCallback(() => {
    if (blockRuleSelected.id) {
      deleteItem({ id: blockRuleSelected.id }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
          navigate(PATH.BLOCKLIST);
        }
      });
    }
  }, [blockRuleSelected.id, deleteItem, dispatch, navigate, t]);

  const handleBackAction = () => {
    if (isShowContextualBar && config.embedded === '1') {
      contextual.hide();
    } else {
      navigate(state?.prePath || PATH.BLOCKLIST);
      dispatch(blockListSlice.actions.clearSetting());
    }
  };

  const ruleSummary: Array<{
    title: string;
    value?: string;
    regular?: boolean;
    visible?: CriteriaType[];
  }> = useMemo(() => {
    return [
      {
        title: t('common:status'),
        value: blockRuleSelected.isActive ? t('common:enabled') : t('common:disabled'),
        regular: true,
      },
      {
        title: t('common:condition'),
        value: criteriaSelected?.label,
      },
      {
        title: t('common:ip_address_blocking'),
        value: blockRuleSelected.ipAddress,
        regular: false,
        visible: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
      },
      {
        title: t('common:product_name'),
        value: blockRuleSelected.productName?.join(', '),
        regular: false,
        visible: [CriteriaType.Product],
      },
      {
        title: t('common:collection_name'),
        value: blockRuleSelected.collectionName?.join(', '),
        regular: false,
        visible: [CriteriaType.Collection],
      },

      {
        title: t('common:page'),
        value: blockRuleSelected.pageTitle?.join(', '),
        regular: false,
        visible: [CriteriaType.SpecificPage],
      },

      {
        title: t('common:country'),
        value: blockRuleSelected.country?.map((item) => handleCountry.renderCountry(item)).join(', '),
        regular: false,
        visible: [
          CriteriaType.Collection,
          CriteriaType.Product,
          CriteriaType.Country,
          CriteriaType.Province,
          CriteriaType.ISP,
          CriteriaType.ReferralLink,
          CriteriaType.ReferralLink,
          CriteriaType.SpecificPage,
          CriteriaType.UserAgent,
        ],
      },

      {
        title: t('common:province_selected'),
        value: blockRuleSelected.state?.join(', '),
        regular: false,
        visible: [CriteriaType.Province],
      },

      {
        title: t('common:city_selected'),
        value: blockRuleSelected.city?.join(', '),
        regular: false,
        visible: [CriteriaType.Province],
      },

      {
        title: t('common:browser'),
        value: blockRuleSelected.browserName?.join(', '),
        regular: false,
        visible: [CriteriaType.UserAgent],
      },
      {
        title: t('common:device'),
        value: blockRuleSelected.deviceType,
        regular: false,
        visible: [CriteriaType.UserAgent],
      },
      {
        title: t('common:os'),
        value: blockRuleSelected.osName,
        regular: false,
        visible: [CriteriaType.UserAgent],
      },

      {
        title: t('common:isp'),
        value: blockRuleSelected.ispName?.join(', '),
        regular: false,
        visible: [CriteriaType.ISP],
      },

      {
        title: t('common:referral_url'),
        value: blockRuleSelected.referUrl,
        regular: false,
        visible: [CriteriaType.ReferralLink],
      },

      {
        title: t('common:created_at'),
        value: blockRuleSelected.createdAt ? formatDate(blockRuleSelected.createdAt / 1000, 'D MMM YYYY, h:mm:ss a') : '',
        regular: true,
        visible: blockRuleSelected.id ? undefined : [],
      },

      {
        title: t('common:last_updated_at'),
        value: blockRuleSelected.lastUpdatedAt ? formatDate(blockRuleSelected.lastUpdatedAt, 'D MMM YYYY, h:mm:ss a') : '',
        regular: true,
        visible: blockRuleSelected.id ? undefined : [],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockRuleSelected, criteriaSelected, handleCountry]);

  const checkPaidPlanFromCriteria = (criteria: CriteriaType): boolean => {
    const freePlanFromCriteria = [
      CriteriaType.IpAddress,
      CriteriaType.IpAddressStartWith,
      CriteriaType.Country,
      CriteriaType.Province,
      CriteriaType.IpRanges,
    ];
    return !freePlanFromCriteria.includes(criteria);
  };
  const checkPremiumPlanFromCriteria = (criteria: CriteriaType): boolean => {
    const premiumPlanFromCriteria = [
      CriteriaType.IpAddress,
      CriteriaType.IpAddressStartWith,
      CriteriaType.Country,
      CriteriaType.Province,
      CriteriaType.IpRanges,
      CriteriaType.Product,
      CriteriaType.Collection,
      CriteriaType.UserAgent,
      CriteriaType.SpecificPage,
    ];
    return !premiumPlanFromCriteria.includes(criteria);
  };

  const isDisabledDiscard = useMemo(() => {
    return isEqual(blockRuleSelected, blockRuleBackupSelected);
  }, [blockRuleSelected, blockRuleBackupSelected]);

  return (
    <Layout
      layoutProps={{
        title: `${blockRuleSelected.id ? t('common:edit') : ''} ${
          blockRuleSelected.priority === ListType.WhiteList
            ? t('common:whitelist')
            : blockRuleSelected.type === ActionType.Block
            ? t('common:block')
            : t('common:redirect')
        } ${toLower(condition?.label)}`,
        backAction: {
          onAction: handleBackAction,
        },
        titleMetadata:
          userPlanFree && checkPaidPlanFromCriteria(criteriaSelected?.value || CriteriaType.IpAddress) ? (
            <CustomBadge scrollToID={IDScrollIntoView.None} tone="info">
              {t('common:paid_plan')}
            </CustomBadge>
          ) : userPlanPremium &&
            shopifyPlanPlus &&
            checkPremiumPlanFromCriteria(criteriaSelected?.value || CriteriaType.IpAddress) ? (
            <CustomBadge scrollToID={IDScrollIntoView.Pricing_enterprise} tone="info">
              {t('common:enterprise_plan_or_shopify_plus')}
            </CustomBadge>
          ) : userPlanPremium &&
            !shopifyPlanPlus &&
            checkPremiumPlanFromCriteria(criteriaSelected?.value || CriteriaType.IpAddress) ? (
            <CustomBadge scrollToID={IDScrollIntoView.Pricing_enterprise} tone="info">
              {t('common:enterprise_plan')}
            </CustomBadge>
          ) : null,
        primaryAction: (
          <div className="d-flex">
            <HelpCenter />
          </div>
        ),
      }}
    >
      <ContextualBar
        delay={state?.prePath}
        loadingSave={upsertRuleStatus.isLoading || uploadIpCSVStatus.isLoading}
        value={blockRuleSelected}
        disabledSave={handleDisabled}
        onSave={handleSave}
        disabledDiscard={isDisabledDiscard}
        onDiscard={handleDiscard}
      />

      <BlockPageStyled>
        <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']} gap={'400'}>
          <div>
            <Card padding={'400'}>
              <div className="block-page-status">
                <BoldText>
                  {t('common:rule_status')}{' '}
                  <Badge tone={blockRuleSelected.isActive ? 'success' : undefined}>
                    {!blockRuleSelected.isActive ? t('common:disabled') : t('common:enabled')}
                  </Badge>
                </BoldText>
                <Button
                  onClick={() => handleChange('isActive')(!blockRuleSelected.isActive)}
                  variant={blockRuleSelected.isActive ? undefined : 'primary'}
                  disabled={isDisabledStatus}
                >
                  {blockRuleSelected.isActive ? t('common:disabled') : t('common:enabled')}
                </Button>
              </div>
              <RegularText>{t('common:enable_rules_activation')}</RegularText>
            </Card>
            <div className="mt-16 block-page-content">
              <Card padding={'400'}>
                {listCriteria.length > 1 ? (
                  <>
                    <BoldText>{t('common:select_condition')}</BoldText>
                    <div className="mt-16">
                      <LegacyStack vertical>
                        {listCriteria.map((item) => {
                          return (
                            <RadioButton
                              disabled={
                                !!blockRuleSelected.id ||
                                (userPlanFree &&
                                  (blockRuleSelected.criteria === CriteriaType.Collection ||
                                    blockRuleSelected.criteria === CriteriaType.Product))
                              }
                              key={item.value}
                              label={
                                item.badge ? (
                                  <div className="d-flex">
                                    {item.label}{' '}
                                    <div className="ml-4">
                                      <CustomBadge scrollToID={item.scrollToID || IDScrollIntoView.None} tone="info">
                                        {item.badge}
                                      </CustomBadge>
                                    </div>
                                  </div>
                                ) : (
                                  item.label
                                )
                              }
                              checked={item.value === blockRuleSelected.criteria}
                              id={`block-page-${item.value}`}
                              name={`block-page-${item.value}`}
                              onChange={() => {
                                dispatch(blockListSlice.actions.handleErrorRule([]));
                                dispatch(blockListSlice.actions.handleErrorCSV(''));
                                dispatch(blockListSlice.actions.handleChangeFileDefault(null));
                                dispatch(
                                  blockListSlice.actions.handleSetting({
                                    ...settingBockListInitial,
                                    criteria: item.value,
                                    inputIPMethod: IPTypes.Manual,
                                  }),
                                );
                              }}
                            />
                          );
                        })}
                      </LegacyStack>
                    </div>
                  </>
                ) : (
                  <BoldText>
                    {t('common:condition')}: {criteriaSelected?.label}
                  </BoldText>
                )}

                <div className="mt-8">
                  {blockRuleSelected.type === ActionType.Redirect ? (
                    <>
                      <TextField
                        label={t('common:redirect_to')}
                        value={blockRuleSelected.linkRedirect}
                        onChange={(value) => {
                          handleChange('linkRedirect')(value);
                          if (linkRedirectError) {
                            setLinkRedirectError('');
                          }
                        }}
                        autoComplete="off"
                        prefix="https://"
                        requiredIndicator
                        error={linkRedirectError}
                        onBlur={() => {
                          if (!blockRuleSelected.linkRedirect) {
                            setLinkRedirectError(t('common:redirect_link_required'));
                          } else if (!validateUrl(blockRuleSelected.linkRedirect))
                            setLinkRedirectError(t('common:enter_valid_url'));
                        }}
                        disabled={isDisabledRedirect}
                      />
                      <div className="mt-8" />
                      <TextField
                        label={t('common:displayed_name_optional')}
                        maxLength={32}
                        value={blockRuleSelected.shortUrl}
                        onChange={handleChange('shortUrl')}
                        autoComplete="off"
                        helpText={t('common:displayed_name_instead_of_url')}
                        disabled={isDisabledRedirect}
                      />
                    </>
                  ) : null}
                  {condition && condition.component
                    ? condition?.component({
                        label: `${t('common:enter')} ${criteriaSelected?.label}`,
                        placeholder: criteriaSelected?.placeholder,
                      })
                    : ''}
                </div>
                {blockRuleSelected.priority === ListType.WhiteList && (
                  <div className="d-flex mt-16 align-center">
                    <Checkbox
                      disabled={userPlanFree}
                      label={t('block_redirect:whitelist_vpn_accesses')}
                      checked={userPlanFree ? false : blockRuleSelected.overrideVpn === 0 ? false : true}
                      onChange={() => handleChange('overrideVpn')(blockRuleSelected.overrideVpn === 0 ? 1 : 0)}
                    />
                    {userPlanFree && (
                      <div className="ml-4">
                        <CustomBadge scrollToID={IDScrollIntoView.None} tone="info">
                          {t('common:paid_plan')}
                        </CustomBadge>
                      </div>
                    )}
                  </div>
                )}
              </Card>
            </div>
          </div>

          <div className="rule-summary-container">
            <Card padding={'0'}>
              <div className="block-page-rule-summary">
                <BoldText>{t('common:rule_summary')}</BoldText>
              </div>
              <div className="block-page-rule-summary-content">
                <List type="bullet">
                  {ruleSummary
                    .filter((item) => {
                      if (!item.visible) return true;
                      return item.visible.includes(blockRuleSelected.criteria as CriteriaType);
                    })
                    .map((item) => {
                      return (
                        <>
                          <List.Item>
                            {item.title}:{' '}
                            {!item.value ? (
                              <Text as="span" variant="bodyMd" tone="subdued">
                                {t('common:none')}
                              </Text>
                            ) : item.regular ? (
                              item.value
                            ) : (
                              <b>{item.value}</b>
                            )}
                          </List.Item>
                        </>
                      );
                    })}
                </List>
              </div>
            </Card>
          </div>
        </InlineGrid>
        <div className="block-page-group-button">
          {blockRuleSelected.id ? (
            <Button
              onClick={() => {
                setIsOpenModalDelete(true);
              }}
              tone="critical"
              variant="primary"
            >
              {t('common:delete')}
            </Button>
          ) : null}
          {config.embedded !== '1' && (
            <Button
              disabled={handleDisabled}
              loading={upsertRuleStatus.isLoading || uploadIpCSVStatus.isLoading}
              onClick={handleSave}
              variant="primary"
            >
              {t('common:save')}
            </Button>
          )}
        </div>

        <ModalConfirm
          onClose={() => setIsOpenModalDelete(false)}
          isLoading={deleteItemStatus.isLoading}
          isOpen={isOpenModalDelete}
          title={t('common:delete_rule')}
          onConfirm={handleDelete}
          sectionText={t('common:delete_rule_no_revert')}
        />
      </BlockPageStyled>
    </Layout>
  );
};
export default memo(BlockPage);

/* eslint-disable no-useless-escape */
import { config } from '@/config';
import { Enum } from '@/constants';
import { UserPlan } from '@/constants/enum';
import { IItemBlock } from '@/redux/slice/blockList.slice';
import store from '@/redux/store';
import { IOptions, IToast } from '@/types/components';
import { HSBColor, hexToRgb, rgbToHsb } from '@shopify/polaris';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
// import { Address4, Address6 } from 'ip-address';

export const generateCIDR = (startIp: string, endIp: string) => {
  const ipToBinary = (ip: string) => {
    if (ip.includes(':')) {
      // Expand IPv6 and convert each block to binary
      const [left, right] = ip.split('::');
      const leftParts = left ? left.split(':') : [];
      const rightParts = right ? right.split(':') : [];
      const missingBlocks = 8 - (leftParts.length + rightParts.length);
      const expanded = [...leftParts, ...Array(missingBlocks).fill('0'), ...rightParts].map((part) =>
        parseInt(part || '0', 16)
          .toString(2)
          .padStart(16, '0'),
      );
      return expanded.join('');
    } else {
      // IPv4 to binary
      return ip
        .split('.')
        .map((part) => parseInt(part).toString(2).padStart(8, '0'))
        .join('');
    }
  };

  const binaryToCIDR = (binary: string, prefixLength: number, isV6: boolean) => {
    let ip: string[] | number[];
    if (isV6) {
      ip = binary.match(/.{1,16}/g)!.map((bin) => parseInt(bin, 2).toString(16));
      return `${ip.join(':').replace(/(:0)+$/g, '::')}/${prefixLength}`;
    } else {
      ip = binary.match(/.{1,8}/g)!.map((bin) => parseInt(bin, 2));
      return `${ip.join('.')}/${prefixLength}`;
    }
  };

  const startBinary = ipToBinary(startIp);
  const endBinary = ipToBinary(endIp);

  // Ensure start IP is <= end IP
  if (BigInt(`0b${startBinary}`) > BigInt(`0b${endBinary}`)) {
    throw new Error('Start IP cannot be larger than end IP');
  }
  let prefixLength = 0;
  while (startBinary[prefixLength] === endBinary[prefixLength] && prefixLength < startBinary.length) {
    // eslint-disable-next-line no-plusplus
    prefixLength++;
  }

  // Use only the prefix for the resulting CIDR block
  const commonBinary = startBinary.substring(0, prefixLength).padEnd(startBinary.length, '0');

  return binaryToCIDR(commonBinary, prefixLength, startIp.includes(':'));
};

export const validateIPRange = (startIP: string, endIP: string, t: TFunction): string => {
  startIP = startIP.trim();
  endIP = endIP.trim();

  let error = '';

  // Hàm kiểm tra định dạng IPv4
  const isValidIPv4 = (ip: string): boolean => {
    const parts = ip.split('.');
    if (parts.length !== 4) return false;
    return parts.every((part) => {
      const num = parseInt(part, 10);
      return !isNaN(num) && num >= 0 && num <= 255;
    });
  };

  // Hàm kiểm tra định dạng IPv6
  const isValidIPv6 = (ip: string): boolean => {
    const parts = ip.split(':');
    if (parts.length > 8 || parts.length < 2) return false;
    let emptyBlocks = 0;
    return (
      parts.every((part) => {
        if (part === '') {
          // eslint-disable-next-line no-plusplus
          emptyBlocks++;
          return true;
        }
        return /^[0-9a-fA-F]{1,4}$/.test(part);
      }) && emptyBlocks <= 1
    ); // Chỉ cho phép 1 `::`
  };

  // Hàm chuyển IPv4 sang số nguyên
  const ipv4ToInteger = (ip: string): number => {
    return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);
  };

  // Hàm chuyển IPv6 sang BigInt
  const ipv6ToBigInt = (ip: string): bigint => {
    const parts = ip.split('::');
    const left = parts[0] ? parts[0].split(':') : [];
    const right = parts[1] ? parts[1].split(':') : [];
    const missingBlocks = 8 - (left.length + right.length);
    const fullBlocks = [...left, ...Array(missingBlocks).fill('0'), ...right].map((block) => BigInt(`0x${block}`));
    return fullBlocks.reduce((acc, block) => (acc << BigInt(16)) + block, BigInt(0));
  };

  // Kiểm tra input
  if (!startIP || !endIP) {
    error = t('common:start_ip_end_ip_required');
  } else if (!isValidIPv4(startIP) && !isValidIPv6(startIP)) {
    error = t('common:start_ip_invalid');
  } else if (!isValidIPv4(endIP) && !isValidIPv6(endIP)) {
    error = t('common:end_ip_invalid');
  } else if (isValidIPv4(startIP) && isValidIPv4(endIP)) {
    // So sánh IPv4
    const startIPInt = ipv4ToInteger(startIP);
    const endIPInt = ipv4ToInteger(endIP);

    if (startIPInt >= endIPInt) {
      error = t('common:start_ip_greater_than_end_ip');
    }
  } else if (isValidIPv6(startIP) && isValidIPv6(endIP)) {
    // So sánh IPv6
    const startBigInt = ipv6ToBigInt(startIP);
    const endBigInt = ipv6ToBigInt(endIP);

    if (startBigInt >= endBigInt) {
      error = t('common:start_ip_greater_than_end_ip');
    }
  } else {
    error = t('common:cannot_compare_ipv4_ipv6');
  }

  return error;
};

export const decimalToIP = (decimal: bigint, isIPv6: boolean = false): string => {
  if (!isIPv6) {
    // Convert IPv4
    if (decimal > BigInt(4294967295) || decimal < BigInt(0)) {
      throw new Error('Invalid IPv4 decimal range');
    }
    const octets = [];
    for (let i = 3; i >= 0; i--) {
      const shift = BigInt(i * 8);
      octets.push(Number((decimal >> shift) & BigInt(255)));
    }
    return octets.join('.');
  } else {
    // Convert IPv6
    if (decimal < BigInt(0) || decimal > BigInt('0xffffffffffffffffffffffffffffffff')) {
      throw new Error('Invalid IPv6 decimal range');
    }
    const segments = [];
    for (let i = 0; i < 8; i++) {
      const shift = BigInt((7 - i) * 16);
      segments.push(((decimal >> shift) & BigInt(0xffff)).toString(16));
    }
    return segments
      .join(':')
      .replace(/(^|:)0(:0)*:0(:|$)/, '::')
      .replace(/:{3,}/, '::');
  }
};

// const ipv4Pattern =
// /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
const ipv6Pattern =
  /(?:^|(?<=\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\s|$)/,
  // urlPattern = /^https:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//= ]*)$/,
  ipv4StartPattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){1,4}$/;
const ipv4Pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/;

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};

export const getLastMonth = () => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};
export const getLastSomesMonth = (month: number) => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - month + 1);
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const validateIp = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    );
};

export const validateListIp = (ips: string): boolean => {
  const listIp = ips.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    if (!ipv4Pattern.test(ip) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export const validateIpAddress = (ip: string): boolean => {
  const listIp = ip.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    if (!ipv4Pattern.test(ip) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export const validateIpStartWith = (ip: string): boolean => {
  return ipv4StartPattern.test(ip.replace(/\.+$/, '')) || ipv6Pattern.test(ip);
};

export const validateListIpStartWith = (ips: string): boolean => {
  if (!ips) return false;
  const listIp = ips.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    // bỏ các dấu chấm ở cuối chuỗi
    if (!ipv4StartPattern.test(ip.replace(/\.+$/, '')) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export function validateUrl(url: string) {
  const regex = /[(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
  const result = url.toLowerCase().match(regex);
  if (result === null) return false;
  else return true;
}

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

export function deepObjectEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);

    if ((areObjects && !deepObjectEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export const mergeArrays = (arr1: any, arr2: any) => {
  const merged = arr1.concat(arr2);
  const uniqueValues = merged.reduce((acc: any, item: any) => {
    const existingItem = acc.find((i: any) => i.value === item.value);
    if (!existingItem) {
      acc.push(item);
    }
    return acc;
  }, []);

  return uniqueValues;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hexToHSB = (color: string): HSBColor => {
  const rbgColor = hexToRgb(color);
  const hsbColor = rgbToHsb(rbgColor);
  return hsbColor;
};

export const checkShowErrorInline = (
  res: any,
  t?: TFunction,
): {
  status: boolean;
  msg: string;
} => {
  try {
    if ('data' in res) {
      return {
        status: res.data.state !== 1,
        msg: t ? (t(`${res.data.msg}`, res.data?.key) as string) : 'Failed',
      };
    } else {
      return {
        status: true,
        msg: t ? (t(res.error.data.message.toString()) as string) : 'Something happened',
      };
    }
  } catch (err) {
    return {
      status: true,
      msg: t ? (t('something_happened') as string) : 'Something happened',
    };
  }
};

export const handleToastMutation = (res: any, t: TFunction): IToast => {
  try {
    if ('data' in res) {
      if (res.data.state !== 1) {
        return {
          isOpen: true,
          content: (t(`${res.data.msg}`, res.data?.key) as string) || t('failed'),
          error: true,
        };
      } else {
        return {
          isOpen: true,
          content: (t(`${res.data.msg}`, res.data?.key) as string) || t('saved'),
          error: false,
        };
      }
    } else {
      return {
        isOpen: true,
        content: t(`${res.data.msg}`) || t('something_happened'),
        error: false,
      };
    }
  } catch (err) {
    return {
      isOpen: true,
      content: t('something_happened'),
      error: true,
    };
  }
};

export const convertToCamelCase = (str: string): string =>
  str
    .replace(/"/g, '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/(\s|\b)(\w)/g, (m, space, chr) => chr.toLowerCase());

export const openCrisp = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {
    console.log(error);
  }
};

export const sendMessageCrisp = (text: string, event?: string[]) => {
  try {
    if (event) {
      $crisp.push(['set', 'session:event', event]);
    }
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'message:send', ['text', text]]);
  } catch (err) {
    console.log(err);
  }
};

export const handleExportChart = (typeChart: number, startDate: Date, endDate: Date) => {
  window.open(
    `${process.env.REACT_APP_API_END_POINT
    }visitor/details/stats/export?typeChart=${typeChart}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&embedded=${config.embedded
    }&hmac=${config.hmac}&host=${config.host}&locale=${config.locale}&shop=${config.shop}&session=${config.session}&timestamp=${config.timestamp
    }`,
  );
};

export const timeConvertedByTimezone = (timeWithTimezone: Date, timezone: number) => {
  timeWithTimezone.setUTCHours(timezone);
  return timeWithTimezone;
};

// Check if your browser blocks localStorage
export const isLocalStorageSupported = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

export const disablePlan = (plans: UserPlan[]) => {
  const plan = store.getState().dataSettings.data?.settings.user.plan;
  const index = plans.findIndex((item) => item === plan);
  return index !== -1;
};
export const getStoreNameFromStoreUrl = (storeUrl: string) => storeUrl.match(/^(.*?)\.myshopify\.com/)?.[1];

export const formatDate = (date: number | Date, format?: string) => {
  return dayjs(typeof date === 'number' ? date * 1000 : date).format(format ? format : 'D MMM YYYY, h:mm a');
};

export const dateToTimeStamp = (date: Date, isOrigin?: boolean) => {
  return isOrigin ? +date : dayjs(date).unix() * 1000;
};

export const secondToTime = (second: number) =>
  [
    Math.floor(second / 3600)
      .toString()
      .padStart(2, '0'),
    Math.floor((second % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    (second % 60).toString().padStart(2, '0'),
  ].join(':');

export const downgradePlan = (currentPlan: UserPlan, newPlan: UserPlan) => {
  const planLevel = Object.values(UserPlan).map((p) => String(p));
  const currentPlanLevel = planLevel.indexOf(currentPlan);
  const nextPlanLevel = planLevel.indexOf(newPlan);
  return currentPlanLevel > nextPlanLevel;
};

export const formatCreatedAt = (createdAt: number, t: TFunction) => {
  if (createdAt === 0) {
    return '';
  }
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - createdAt;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  const config = (key: string, other: string, variable?: { [key: string]: string | number }): string => {
    return t ? t(`common:${key}`, variable) : other;
  };

  if (minutes <= 1) {
    return config('1_minute_ago', `${minutes} minute ago`);
  } else if (minutes < 60) {
    return config('v_minute_ago', `${minutes} minutes ago`, { v: minutes });
  } else if (hours <= 1) {
    return config('1_hour_ago', `${months} hour ago`);
  } else if (hours < 24) {
    return config('v_hour_ago', `${hours} hours ago`, { v: hours });
  } else if (days <= 1) {
    return config('1_day_ago', `${days} day ago`);
  } else if (days < 30) {
    return config('v_day_ago', `${days} days ago`, { v: days });
  } else if (months <= 1) {
    return config('1_month_ago', `${months} month ago`);
  } else if (months < 12) {
    return config('v_month_ago', `${months} months ago`, { v: months });
  } else if (years <= 1) {
    return config('1_year_ago', `${years} year ago`);
  } else {
    return config('v_year_ago', `${years} years ago`, { v: years });
  }
};

export function validateEmail(email: string) {
  const regex = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/);
  const result = email.match(regex);
  if (result === null) return false;
  else return true;
}

export function uniq(a: Array<any>) {
  return Array.from(new Set(a));
}

export const isRedirectInvalid = (setting: IItemBlock) => {
  return (
    setting.type === Enum.ActionType.Redirect &&
    (!setting.linkRedirect || (!!setting.linkRedirect && !validateUrl(setting.linkRedirect)))
  );
};

export function removeFalsyValues<T extends Record<string, any>>(obj: T): Partial<T> {
  const result: Partial<T> = {};

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    const value = obj[key];
    if (Boolean(value) && (!Array.isArray(value) || value.length > 0)) {
      result[key] = value;
    }
  });

  return result;
}

export function convertArraysToStrings(obj: { [key: string]: any }): { [key: string]: any } {
  let newObj: { [key: string]: any } = { ...obj };
  for (let key in newObj) {
    if (Array.isArray(newObj[key])) {
      newObj[key] = newObj[key].join(', ');
    }
  }

  return newObj;
}
export const getOptionsSelectedRemove = (newValue: Array<string>, oldValue: Array<IOptions>) => {
  const optionSelected = oldValue.filter((option) => newValue.includes(option.value));
  return optionSelected;
};
export const getOptionsSelectedAdd = (newValue: Array<string>, oldValue: Array<IOptions>, options: Array<IOptions>) => {
  const selectedOptions = options.filter((option) => newValue.includes(option.value));
  return [...oldValue, ...selectedOptions].filter((item, index, self) => index === self.findIndex((t) => t.value === item.value));
};

export const daysSinceInstallation = (installationDateStr: string) => {
  const installationDate = dayjs(installationDateStr);
  const currentDate = dayjs();

  return currentDate.diff(installationDate, 'day');
};

export const findIPv4Range = (ip: string): string | null => {
  const octets = ip.split('.');
  if (octets.length !== 4) return null;

  const octet1 = octets[0];
  const octet2 = octets[1];
  const octet3 = octets[2];
  const octet4 = octets[3];

  if (octet4 === '0') {
    if (octet3 === '0') {
      if (octet2 === '0') {
        return `${octet1}.0.0.0/8`;
      } else {
        return `${octet1}.${octet2}.0.0/16`;
      }
    } else {
      return `${octet1}.${octet2}.${octet3}.0/24`;
    }
  } else {
    return `${octet1}.${octet2}.${octet3}.0/24`;
  }
};

export const findIPv6Range = (ip: string): string | null => {
  const groups = ip.split(':').map((group) => group.padStart(4, '0'));
  if (groups.length !== 8) return null;

  const group1 = groups[0];
  const group2 = groups[1];
  const group3 = groups[2];
  const group4 = groups[3];

  if (group4 === '0000') {
    if (group3 === '0000') {
      if (group2 === '0000') {
        return `${group1}::/16`;
      } else {
        return `${group1}:${group2}::/32`;
      }
    } else {
      return `${group1}:${group2}:${group3}::/48`;
    }
  } else {
    return `${group1}:${group2}:${group3}::/48`;
  }
};

export const findIPRange = (ip: string): string | null => {
  if (ip.includes('.')) {
    return findIPv4Range(ip);
  } else if (ip.includes(':')) {
    return findIPv6Range(ip);
  }
  return null;
};

export const shouldShowBannerReview = (): boolean => {
  const now = new Date();
  const options = { timeZone: 'Asia/Bangkok' };
  const localTime = new Date(now.toLocaleString('en-US', options));
  const dayOfWeek = localTime.getDay();
  const hour = localTime.getHours();

  // Kiểm tra nếu là ngày cuối tuần (thứ 6 hoặc chủ nhật) hoặc là thứ 2 trước 8h sáng
  // Hoặc nếu giờ là từ 0-4h sáng
  if (dayOfWeek === 6 || dayOfWeek === 0 || (dayOfWeek === 1 && hour < 8) || (hour >= 0 && hour < 4)) {
    return false;
  }
  return true;
};

export const extractWords = (inputString: string) => {
  // Use regex to match words containing only alphabet characters
  return inputString.match(/[a-zA-Z]+/g) || [];
};

/**
 * Chuyển đổi chuỗi từ định dạng snake_case thành chuỗi có dấu cách giữa các từ.
 *
 * @param {string} str - Chuỗi cần chuyển đổi (ví dụ: "something_happened").
 * @returns {string} - Chuỗi đã chuyển đổi (ví dụ: "something happened").
 */
export const convertSnakeToSentence = (str: string) => {
  if (!str) return '';

  return str?.split('_')?.join(' ');
};

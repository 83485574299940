import React, { useState, FC, useMemo, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, Collapsible, Link, List, Text } from '@shopify/polaris';
import RegularText from '@/components/RegularText';
import userPlans from '@/hooks/userPlans';

import { useDispatch, useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleIdScrollIntoView } from '@/redux/slice/home.slice';
import { IDScrollIntoView } from '@/constants/enum';
import { useTranslation } from 'react-i18next';
interface FAQItem {
  label: string;
  extend: boolean;
  content: React.ReactNode;
  mixpanelEvent: string;
}

const PricingFAQs: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pricing']);

  const dataSettings = useSelector(dataSettingsSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;

  const handleClickLink = useCallback(
    (scrollId: IDScrollIntoView) => () => {
      dispatch(handleIdScrollIntoView(scrollId));
    },
    [dispatch],
  );

  const { shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, newUser } = userPlans();

  const initialData: FAQItem[] = useMemo(
    () => [
      {
        label: `1. ${t('pricing:can_i_use_app_for_free')}`,
        extend: false,
        content: (
          <RegularText>
            {`${t('pricing:free_plan_with_limits', { maxLimitRules })} `}
            {(shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser ? (
              <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>{t('pricing:shopify_plus_plan')}</Link>
            ) : (
              <>
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_premium)}>{t('pricing:premium_plan')}</Link>
                {` ${t('pricing:and')} `}
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_enterprise)}>{t('pricing:enterprise_plan')}</Link>
                {!newUser && (
                  <>
                    {` ${t('pricing:and')}`}
                    <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>{t('pricing:shopify_plus_plan')}</Link>
                  </>
                )}
              </>
            )}
            .
          </RegularText>
        ),
        mixpanelEvent: 'Faq_1',
      },
      {
        label: `2. ${t('pricing:what_can_i_do_with_the_free_plan')}`,
        extend: false,
        content: (
          <>
            <RegularText>{`${t('pricing:free_plan_with_limited_features')}:`}</RegularText>
            <List type="bullet">
              <List.Item>{t('pricing:block_ip_address_location')}</List.Item>
              <List.Item>{t('pricing:redirect_ip_address_location')}</List.Item>
              <List.Item>{t('pricing:whitelist_ip_address_location')}</List.Item>
              <List.Item>{t('pricing:fraud_order_analytics')}</List.Item>
              <List.Item>{t('pricing:uses_blocking_template')}</List.Item>
            </List>
            <RegularText>
              {`${t('pricing:increase_limitation')} `}
              {(shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser ? (
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>{t('pricing:shopify_plus_plan')}</Link>
              ) : (
                <>
                  <Link onClick={handleClickLink(IDScrollIntoView.Pricing_premium)}>{t('pricing:premium_plan')}</Link>,{' '}
                  <Link onClick={handleClickLink(IDScrollIntoView.Pricing_enterprise)}>{t('pricing:enterprise_plan')}</Link>,
                  {` ${t('pricing:and')} `}
                  <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>
                    {t('pricing:plus_plan_shopify_plus_only')}
                  </Link>
                </>
              )}
            </RegularText>
          </>
        ),
        mixpanelEvent: 'Faq_2',
      },
      {
        label: `3. ${t('pricing:app_pricing_work')}`,
        extend: false,
        content: (
          <RegularText>
            {`${t('pricing:app_pricing_subscription')} `}
            <Link
              target="_blank"
              url="https://help.shopify.com/en/manual/your-account/manage-billing/your-invoice/apps#subscriptions"
            >
              {t('pricing:app_subscriptions_charges')}
            </Link>
            {` ${t('pricing:work_flow_shopify')}`}
          </RegularText>
        ),
        mixpanelEvent: 'Faq_3',
      },
      {
        label: `4. ${t('pricing:do_i_have_trials_for_the_paid_plan')}`,
        extend: false,
        content: (
          <RegularText>
            {`${t('pricing:blockify_3_day_free_trial')} `}
            {(shopifyPlanPlus || shopifyPlanPlusPartnerSandbox) && newUser ? (
              <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>{t('pricing:shopify_plus_plan')}</Link>
            ) : (
              <>
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_premium)}>{t('pricing:premium')}</Link>,{' '}
                <Link onClick={handleClickLink(IDScrollIntoView.Pricing_enterprise)}>{t('pricing:enterprise')}</Link>
                {!newUser && (
                  <>
                    {` ${t('pricing:and')} `}
                    <Link onClick={handleClickLink(IDScrollIntoView.Pricing_shopify_plus)}>{t('pricing:shopify_plus_plan')}</Link>
                  </>
                )}
              </>
            )}
            .
          </RegularText>
        ),
        mixpanelEvent: 'Faq_4',
      },
      {
        label: `5. ${t('pricing:switch_back_to_free_plan')}`,
        extend: false,
        content: <RegularText>{`${t('pricing:downgrade_to_free_plan')}`}</RegularText>,
        mixpanelEvent: 'Faq_5',
      },
    ],
    [maxLimitRules, shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, newUser, handleClickLink, t],
  );

  const [listData, setListData] = useState<FAQItem[]>([]);

  useEffect(() => {
    setListData(initialData);
  }, [initialData, shopifyPlanPlus, shopifyPlanPlusPartnerSandbox, newUser]);

  const handleToggle = (index: number) => {
    setListData((prevData) =>
      prevData.map((item, i) => {
        if (i === index) {
          return { ...item, extend: !item.extend };
        }
        return { ...item, extend: false };
      }),
    );
  };

  return (
    <div className="pricing-faqs-container">
      <Text variant="headingLg" as="h4">
        {t('pricing:pricing_faqs')}
      </Text>

      <Card padding={{ xs: '400', lg: '600' }}>
        <BlockStack gap="500">
          {listData?.map((question, index) => (
            <React.Fragment key={question.label}>
              <Button size="large" fullWidth textAlign="left" onClick={() => handleToggle(index)}>
                {question.label}
              </Button>

              <Collapsible
                open={question.extend}
                id="basic-collapsible"
                transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
              >
                <Box padding="200">
                  <RegularText>{question.content}</RegularText>
                </Box>
              </Collapsible>
            </React.Fragment>
          ))}
        </BlockStack>
      </Card>
    </div>
  );
};

export default PricingFAQs;

import { dashboardImage } from '@/asset/images/dashboard';
// import BannerReviewApp from '@/components/BannerReviewApp';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import CardForeverFree from '@/components/CardForeverFree';
import FlashSaleCard from '@/components/FlashSaleCard';
import GetStarted from '@/components/GetStarted';
import CustomLayout from '@/components/layout';
import PromotionsCard from '@/components/PromotionsCard';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import { Ga4Event } from '@/constants/enum';
import { link } from '@/constants/link';
import { useGa4 } from '@/hooks/useGa4';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import bannerSlice, { bannerSelector, handleVisibleBanner } from '@/redux/slice/banner.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { handleShowModalSearch } from '@/redux/slice/home.slice';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import { Banner, Button, Card, Collapsible, Icon, InlineGrid, InlineStack, Text } from '@shopify/polaris';
import { BookIcon, ChatIcon, GlobeAsiaFilledIcon, SearchIcon } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Overview from './overview';
import RecommendedApps from './recommendedApps';
import { HomePageStyled } from './styled';

function HomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleGa4 } = useGa4();
  const { t } = useTranslation('dashboard');
  const { userPlanEnterprise, shopifyPlanPlus, planAppliedList, userPlanFree } = UserPlans();

  const banner = useSelector(bannerSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const gaFour = useSelector(gaFourSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const recommendAppRef = useRef(null);

  const [showBannerFromToDate, setShowBannerFromToDate] = useState(true);

  const isShowBannerCampaign =
    !userPlanEnterprise && !shopifyPlanPlus && banner.showFlashSaleCard && showBannerFromToDate && !planAppliedList?.length;

  const handleCloseHolidayNotice = useCallback(() => {
    dispatch(
      bannerSlice.actions.handleVisibleBanner({
        key: 'showHungKingHoliday',
        value: false,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const currentDate = dayjs();
    const campaignStartDate = dayjs(1722445199000); //2024-07-31T23:59:59+07:00
    const campaignEndDate = dayjs(1726851599999); //2024-09-20T23:59:59+07:00
    const lastInstalledDate = dayjs(dataSettings?.settings.user.lastInstalledDate || dataSettings?.settings.user.installedDate);

    if (lastInstalledDate.isBefore(campaignStartDate) && currentDate.isBefore(campaignEndDate)) {
      setShowBannerFromToDate(true);
    } else {
      setShowBannerFromToDate(false);
    }
  }, [dataSettings?.settings.user.installedDate, dataSettings?.settings.user.lastInstalledDate]);

  const infoAndSupport = useMemo(() => {
    return [
      {
        title: t('contact_support_247'),
        description: t('get_in_touch_support'),
        onAction: () => {
          const sendText = 'I need assistance with using Blockify';
          try {
            $crisp.push(['set', 'session:event', ['ReviewStoreBlockify']]);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['do', 'message:send', ['text', sendText]]);
          } catch (err) {
            console.log(err);
          }
        },
        btnContent: t('chat_with_us'),
        icon: ChatIcon,
      },
      {
        title: t('read_user_guideline'),
        description: t('step_by_step_instruction_articles'),
        onAction: () => {
          window.open('https://blockifyapp.com/knowledge-base/blockify-fraud-country-blocker/');
        },
        btnContent: t('read_user_guideline'),
        icon: BookIcon,
      },
      {
        title: t('discover_our_website'),
        description: t('explore_website_updates'),
        onAction: () => {
          window.open(' https://blockifyapp.com/');
        },
        btnContent: t('visit_website'),
        icon: GlobeAsiaFilledIcon,
      },
    ];
  }, [t]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour.homePage) {
            dispatch(
              sessionSlice.actions.handleGaFour({
                key: 'homePage',
              }),
            );
            handleGa4(Ga4Event.ImpressPareto);
            handleGa4(Ga4Event.SynctrackBannerImpression);
            handleGa4(Ga4Event.ImpressCookies);
            handleGa4(Ga4Event.ImpressCheckout);
          }
        }
      });
    });

    if (recommendAppRef.current) {
      observer.observe(recommendAppRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (recommendAppRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(recommendAppRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour.homePage]);

  return (
    <HomePageStyled>
      <GetStarted />
      <CustomLayout
        layoutProps={{
          title: t('hello', { name: dataSettings?.settings?.user?.businessName || config.shop.split('.')[0] }),
          secondaryActions: (
            <>
              <InlineStack gap="150">
                <Button onClick={() => navigate(PATH.ANALYTICS)}>{t('visitor_dashboard')}</Button>
                <Button onClick={() => dispatch(handleShowModalSearch(true))} icon={SearchIcon}>
                  {t('search_settings')}
                </Button>
              </InlineStack>
            </>
          ),
        }}
        isVisibleHeader={!dataSettings?.url}
      >
        <div className="mt-16">
          <Text variant="bodyLg" as="span">
            {t('dashboard:welcome_to_blockify')}
          </Text>
        </div>

        {banner.showHungKingHoliday ?
          <div className='mt-16'>
            <Banner tone='info' title={t('out_of_office_notice')} onDismiss={handleCloseHolidayNotice}>
              <div className="d-flex flex-column">
                <p>
                  <Trans i18nKey={'offices_closed_public_holiday'} t={t}>
                    Our offices will be closed for a public holiday from <b>April 5</b> to <b>April 7, 2025</b>. During this time, response times may be slower than usual, and full support services will resume on <b>Tuesday, April 8</b>.
                  </Trans>
                </p>
                <p>
                  {t('team_away_customer_support_available_basic_assistance')}
                </p>
                <p>
                  {t('thank_you_support')} 😊
                </p>
              </div>
            </Banner>
          </div> : null}

        {checkEmbeddedStatus.data && (
          <Collapsible
            id="banner-check-embedded-app"
            open={banner.noticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="mt-16">
              <Banner
                onDismiss={() => {
                  dispatch(
                    handleVisibleBanner({
                      key: 'noticeEmbeddedApp',
                      value: false,
                    }),
                  );
                }}
                tone="warning"
                title={t('action_required_activate_app_embed')}
              >
                <div className="d-flex flex-column">
                  <RegularText>
                    <Trans t={t} i18nKey={'go_to_shopify_theme_editor_turn_on_blockify_app'}>
                      Go to <b>Shopify Theme Editor</b> &gt; Turn on <b>Blockify app</b> on the App Embeds section.
                    </Trans>
                  </RegularText>
                  <div className="mt-8">
                    <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                      {t('activate_app_now')}
                    </Button>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        )}

        {/* <BannerReviewApp /> */}

        <div className="mt-16">
          <CardForeverFree />
        </div>

        <div className="mt-16">
          <Overview />
        </div>

        <div className="mt-16">
          <Collapsible
            id="gift_box"
            open={isShowBannerCampaign}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <FlashSaleCard />
          </Collapsible>
        </div>

        <div className="card-info">
          <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
            {infoAndSupport.map((item, index) => {
              return (
                <Card key={index}>
                  <div className="card-container">
                    <div>
                      <div className="d-flex">
                        <Icon source={item.icon} />
                        <div className="ml-8">
                          <BoldText>{item.title}</BoldText>
                        </div>
                      </div>
                      <div className="card-text">
                        <RegularText>{item.description}</RegularText>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Button onClick={item.onAction}>{item.btnContent}</Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        </div>

        {/* Synctrack */}
        <div className="mt-16">
          <PromotionsCard />
        </div>

        {/* Checkout rules */}
        {userPlanFree && (
          <div className="mt-16">
            <BannerTemplate
              src={dashboardImage.pareto}
              isOpen={banner.promotePareto}
              onDismiss={() =>
                dispatch(
                  handleVisibleBanner({
                    key: 'promotePareto',
                    value: false,
                  }),
                )
              }
            >
              <BoldText>{t('dashboard:prevent_fraud_protect_revenue')}</BoldText>
              <div className="mt-8">
                <RegularText>{t('dashboard:order_limit_app_description')}</RegularText>
              </div>
              <div className="mt-16">
                <Button
                  onClick={() => {
                    if (!gaFour.pareto) {
                      handleGa4(Ga4Event.ParetoClick);
                    }
                    window.open(
                      'https://apps.shopify.com/pareto-limit-purchase?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
                      '_blank',
                    );
                  }}
                >
                  {t('free_app')}
                </Button>
              </div>
            </BannerTemplate>
          </div>
        )}
        <div className="app-recommended" ref={recommendAppRef}>
          <RecommendedApps />
        </div>
      </CustomLayout>
    </HomePageStyled>
  );
}

export default memo(HomePage);

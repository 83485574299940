import React, { useEffect, useRef, useState } from 'react';
import { ScrollInToViewStyled } from './styled';
import { IDScrollIntoView } from '@/constants/enum';
import { useDispatch, useSelector } from 'react-redux';
import { handleIdScrollIntoView, idScrollIntoViewSelector } from '@/redux/slice/home.slice';
import { scroller } from 'react-scroll';

type ScrollInToViewProp = {
  children: React.ReactNode;
  idScrollToBlock: IDScrollIntoView;
  className?: string;
};

const ScrollInToView: React.FC<ScrollInToViewProp> = ({ children, idScrollToBlock, className }) => {
  const dispatch = useDispatch();

  const idScrollSelector = useSelector(idScrollIntoViewSelector);
  const blockRef = useRef<HTMLDivElement | null>(null);

  const [isScaled, setIsScaled] = useState(false);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (idScrollSelector === idScrollToBlock && blockRef.current) {
      // Scroll to the element
      scroller.scrollTo(idScrollSelector, {
        duration: 500,
        smooth: true,
        offset: -50,
      });

      // Observe when the element is in view
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsInView(true); // Element is in view
            observer.disconnect(); // Stop observing once in view
          }
        },
        { threshold: 1 },
      );

      observer.observe(blockRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idScrollSelector, idScrollToBlock, blockRef.current]);

  useEffect(() => {
    if (isInView) {
      // Wait 0.4s before applying the scaling effect
      const addScaleTimeoutId = setTimeout(() => {
        setIsScaled(true);

        // Remove scaling effect after 0.8s
        const resetScaleTimeoutId = setTimeout(() => {
          setIsScaled(false);
          setIsInView(false);
          dispatch(handleIdScrollIntoView(IDScrollIntoView.None));
        }, 800);

        return () => clearTimeout(resetScaleTimeoutId);
      }, 400);

      return () => clearTimeout(addScaleTimeoutId);
    }
  }, [isInView, dispatch]);

  return (
    <ScrollInToViewStyled
      id={idScrollToBlock}
      ref={idScrollToBlock === IDScrollIntoView.None ? undefined : blockRef}
      isScaled={isScaled}
      className={className}
    >
      {children}
    </ScrollInToViewStyled>
  );
};

export default ScrollInToView;

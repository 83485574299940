import { IResponseApi } from '@/types/api/response.api';
import { InlineError, Link } from '@shopify/polaris';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  open: boolean | undefined;
  url?: string | undefined;
  verifyRuleStatus: Array<IResponseApi.IFailureRules> | Array<IResponseApi.IInvalidRulesBlockCheckout>;
  message?: string | undefined;
}

const CustomInlineError: React.FC<Props> = ({ open, verifyRuleStatus, url, message }) => {
  const { t } = useTranslation(['common']);
  return open ? (
    <InlineError
      message={
        <>
          {message ? (
            message
          ) : url ? (
            <p>
              {t('common:unable_to_add_some_new_entries_due_to_invalid_format_or_existing_values')}{' '}
              <Link url={url}>{t('common:here')}</Link>
            </p>
          ) : (
            <p className="d-flex flex-wrap">
              <span className="mr-4">
                {t('common:unable_to_add_some_new_entries_due_to_invalid_format_or_existing_values')}{' '}
                {verifyRuleStatus?.map((item) => item.errValue).join(', ')}
              </span>
            </p>
          )}
        </>
      }
      fieldID="verifyId"
    />
  ) : null;
};

export default memo(CustomInlineError);

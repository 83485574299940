import { CriteriaHidePayment } from '@/constants/enum';
import { weekOption } from '@/constants/options';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import hidePaymentSlice, { isErrorSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { InlineError, InlineGrid, Select, Text } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentMethod from '../../PaymentMethod';
import { useTranslation } from 'react-i18next';

function DayOfTheWeek({ ...props }) {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  const paymentDetail = useSelector(paymentDetailSelector);

  const dataSettings = useSelector(dataSettingsSelector);
  const isError = useSelector(isErrorSelector);

  const clearState = useCallback(() => {
    dispatch(
      hidePaymentSlice.actions.handleVerifyErrorHidePayment({
        status: false,
        data: undefined,
      }),
    );
  }, [dispatch]);

  const handleChangeStartDate = (value: string) => {
    if (isError) {
      clearState();
    }
    dispatch(
      hidePaymentSlice.actions.handleSettingHidePayment({
        ...paymentDetail,
        startDate: value,
      }),
    );
  };

  const handleChangeEndDate = (value: string) => {
    if (isError) {
      clearState();
    }
    dispatch(
      hidePaymentSlice.actions.handleSettingHidePayment({
        ...paymentDetail,
        endDate: value,
      }),
    );
  };

  return (
    <div>
      <PaymentMethod
        children={
          <>
            <InlineGrid gap="300" columns={2}>
              <Select
                label={`${t('common:from')}:`}
                options={weekOption}
                onChange={handleChangeStartDate}
                value={paymentDetail.startDate}
                requiredIndicator
              />
              <Select
                label={`${t('common:to')}:`}
                options={weekOption}
                onChange={handleChangeEndDate}
                value={paymentDetail.endDate}
                requiredIndicator
              />
            </InlineGrid>
            <Text as="span" variant="bodyMd" tone="subdued">
              Based on your store's timezone{' '}
              {dataSettings?.settings.shopTimezone.slice(0, dataSettings?.settings.shopTimezone.indexOf(' ') + 1)}
            </Text>

            {isError.status && (
              <InlineError
                message={
                  <>
                    <p className="d-flex flex-wrap">
                      <span className="mr-4">
                        Unable to add new entries due to invalid format or existing values:{' '}
                        {isError.data?.invalidRules
                          ?.map((item) => {
                            if (paymentDetail.criteria === CriteriaHidePayment.DayOfWeek) {
                              return item.errValue.replace(';', ' to ');
                            } else {
                              return item.errValue;
                            }
                          })
                          .join(', ')}
                      </span>
                    </p>
                  </>
                }
                fieldID="verifyId"
              />
            )}
          </>
        }
      />
    </div>
  );
}

export default memo(DayOfTheWeek);

import React, { memo } from 'react';
import iconCheck from '@/asset/images/icon-check-plan.png';
import userPlans from '@/hooks/userPlans';
import { BlockStack } from '@shopify/polaris';
import { upperCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type PlanValue = string | boolean | JSX.Element;
// type Features = Record<string, Record<string, Record<string, PlanValue>>>;

const renderCheckIcon = <img width={20} height={20} src={iconCheck} alt="iconCheck" />;

const RenderContent = ({ content }: { content: string }) => (
  <BlockStack gap="050" inlineAlign="center">
    {renderCheckIcon}
    <p>{content}</p>
  </BlockStack>
);

const featuresRegular = (t: TFunction) => ({
  [t('pricing:block')]: {
    [t('pricing:ip')]: {
      free: <RenderContent content={t('pricing:address_start_with')} />,
      premium: <RenderContent content={t('pricing:address_start_with')} />,
      enterprise: renderCheckIcon,
    },
    [t('pricing:location')]: {
      free: <RenderContent content={t('pricing:country')} />,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:products_collections')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:user_agent')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:isp')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:referrer_url')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
  [t('pricing:redirect')]: {
    [t('pricing:ip')]: {
      free: <RenderContent content={t('pricing:address_start_with')} />,
      premium: <RenderContent content={t('pricing:address_start_with')} />,
      enterprise: renderCheckIcon,
    },
    [t('pricing:location')]: {
      free: <RenderContent content={t('pricing:country')} />,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:products_collections')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:user_agent')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:isp')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:referrer_url')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
  [t('pricing:whitelist')]: {
    [t('pricing:ip')]: {
      free: <RenderContent content={t('pricing:address_start_with')} />,
      premium: <RenderContent content={t('pricing:address_start_with')} />,
      enterprise: renderCheckIcon,
    },
    [t('pricing:location')]: {
      free: <RenderContent content={t('pricing:country')} />,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:products_collections')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:user_agent')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:isp')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:referrer_url')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
  [t('pricing:fraud_order')]: {
    [t('pricing:fraud_order_analytics')]: {
      free: renderCheckIcon,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:auto_block_visitors_place_fraud_order')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:auto_cancel_high_risk_order')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
  [t('pricing:block_checkout')]: {
    [t('pricing:auto_block_order_based_on_subtotal_conditions')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:block_email_address')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:block_customer_name')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:block_address_zipcode')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:block_phone_number')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
  // 'Hide payment': {
  //   'Hide payment method': { free: renderImage,premium: renderImage,enterprise: renderImage },
  //   'Hide express checkout': { free: renderImage,premium: renderImage,enterprise: renderImage },
  // },
  [t('pricing:auto_block')]: {
    [t('pricing:auto_block_visitors_uses_proxy_vpn')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:auto_block_tor')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:auto_detect_and_block_visitor_change_ip_address')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
  },
  [t('pricing:content_protection')]: {
    [t('pricing:prevent_copy_paste_content')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:prevent_right_click_inspect')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
    [t('pricing:prevent_shortcut')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
  [t('pricing:others')]: {
    [t('pricing:support')]: {
      free: t('pricing:twenty_four_seven'),
      premium: t('pricing:vip_priority'),
      enterprise: t('pricing:vip_priority'),
    },
    [t('pricing:styling_css_blocking_template')]: {
      free: false,
      premium: renderCheckIcon,
      enterprise: renderCheckIcon,
    },
    [t('pricing:expert_review_set_up')]: {
      free: false,
      premium: false,
      enterprise: renderCheckIcon,
    },
  },
});

const featuresShopifyPlus = (t: TFunction) => ({
  [t('pricing:block')]: {
    [t('pricing:ip')]: {
      free: <RenderContent content={t('pricing:address_start_with')} />,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:location')]: {
      free: <RenderContent content={t('pricing:country')} />,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:products_collections')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:user_agent')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:isp')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:referrer_url')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  [t('pricing:redirect')]: {
    [t('pricing:ip')]: {
      free: <RenderContent content={t('pricing:address_start_with')} />,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:location')]: {
      free: <RenderContent content={t('pricing:country')} />,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:products_collections')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:user_agent')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:isp')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:referrer_url')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  [t('pricing:whitelist')]: {
    [t('pricing:ip')]: {
      free: <RenderContent content={t('pricing:address_start_with')} />,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:location')]: {
      free: <RenderContent content={t('pricing:country')} />,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:products_collections')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:user_agent')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:isp')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:referrer_url')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  [t('pricing:fraud_order')]: {
    [t('pricing:fraud_order_analytics')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:auto_block_visitors_place_fraud_order')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:auto_cancel_high_risk_order')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  [t('pricing:block_checkout')]: {
    [t('pricing:auto_block_order_based_on_subtotal_conditions')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:block_email_address')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:block_customer_name')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:block_address_zipcode')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:block_phone_number')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  // 'Hide payment': {
  //   'Hide payment method': { free: renderImage, ['shopify plus']: renderImage },
  //   'Hide express checkout': { free: renderImage, ['shopify plus']: renderImage },
  // },
  [t('pricing:auto_block')]: {
    [t('pricing:auto_block_visitors_uses_proxy_vpn')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:auto_block_tor')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:auto_detect_and_block_visitor_change_ip_address')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  [t('pricing:content_protection')]: {
    [t('pricing:prevent_copy_paste_content')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:prevent_right_click_inspect')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:prevent_shortcut')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
  [t('pricing:others')]: {
    [t('pricing:support')]: {
      free: t('pricing:twenty_four_seven'),
      'shopify plus': t('pricing:vip_priority'),
    },
    [t('pricing:styling_css_blocking_template')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
    [t('pricing:expert_review_set_up')]: {
      free: false,
      'shopify plus': renderCheckIcon,
    },
  },
});

const ComparePlan: React.FC = () => {
  const { t } = useTranslation(['pricing']);
  const { shopifyPlanPlus, shopifyPlanPlusPartnerSandbox } = userPlans();

  const plansRegular = ['free', 'premium', 'enterprise'];
  const plansShopifyPlus = ['free', 'shopify plus'];

  const features = shopifyPlanPlus || shopifyPlanPlusPartnerSandbox ? featuresShopifyPlus(t) : featuresRegular(t);
  const plans = shopifyPlanPlus || shopifyPlanPlusPartnerSandbox ? plansShopifyPlus : plansRegular;

  return (
    <div className="compare-plan-container">
      <table className="compare-table">
        <thead>
          <tr className="feature-group">
            <th className="sticky-col"></th>
            {plans.map((plan) => (
              <th className="check sticky-col" key={plan}>
                {upperCase(plan)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(features).map(([group, groupFeatures]) => (
            <React.Fragment key={group}>
              <tr className="feature-group">
                <td className="sticky-col">{group}</td>
                {plans.map((plan) => (
                  <td key={plan}></td>
                ))}
              </tr>
              {Object.entries(groupFeatures).map(([feature, availablePlans]) => (
                <tr key={feature}>
                  <td className="sticky-col">{feature}</td>
                  {plans.map((plan: string) => (
                    <td key={plan} className="check">
                      <span>{(availablePlans as Record<string, PlanValue>)[plan.toLowerCase()]}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(ComparePlan);

import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import RegularText from '@/components/RegularText';
import { CriteriaType } from '@/constants/enum';
import { openCrisp } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import UserPlans from '@/hooks/userPlans';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import blockListSlice, { inputCitySelector, settingSelector } from '@/redux/slice/blockList.slice';
import { Link } from '@shopify/polaris';
import _debounce from 'lodash/debounce';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Location = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const useError = useErrorRule();
  const blockRuleSelected = useSelector(settingSelector);
  const inputSearch = useSelector(inputCitySelector);
  const { userPlanFree } = UserPlans();
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
    search: inputSearch,
  });
  // eslint-disable-next-line
  const debounceHandleSearch = useCallback(
    _debounce((value: string) => {
      dispatch(blockListSlice.actions.handleInputCity(value));
    }, 1000),
    [],
  );

  return (
    <div>
      <AutoCompleteWithTag
        helpText={
          blockRuleSelected.criteria === CriteriaType.Country && !blockRuleSelected?.id
            ? t('common:select_multiple_countries')
            : undefined
        }
        options={
          blockRuleSelected.criteria === CriteriaType.Country
            ? [...handleCountry.otherCountry, ...handleCountry.listCountry]
            : [...handleCountry.listCountry]
        }
        label={t('common:select_country')}
        selectedOptions={blockRuleSelected.country || []}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.getErrorRuleByCriteria(CriteriaType.Province)?.error) {
            useError.removeErrorRule(CriteriaType.Province);
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              country: value,
              state: [],
              city: [],
            }),
          );
        }}
        allowMultiple={blockRuleSelected.criteria === CriteriaType.Country && !blockRuleSelected.id}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule(t('common:country_must_be_added'), CriteriaType.Country);
          }
        }}
        disable={userPlanFree && blockRuleSelected.criteria === CriteriaType.Province}
      />

      {blockRuleSelected.criteria === CriteriaType.Province ? (
        <div>
          <AutoCompleteWithTag
            helpText={!blockRuleSelected?.id ? t('common:select_multiple_countries') : undefined}
            disable={handleCountry.listStates.length === 0 || blockRuleSelected.country?.length === 0}
            options={handleCountry.listStates}
            label={t('common:select_state_province')}
            selectedOptions={blockRuleSelected.state || []}
            setSelectedOptions={(selected: string[]) => {
              if (selected.length === 0) {
                useError.handleErrorRule(t('common:province_must_be_added'));
              } else if (blockRuleSelected.criteria === CriteriaType.Province && useError.errorSelected?.error) {
                useError.removeErrorRule(CriteriaType.Province);
              }
              dispatch(
                blockListSlice.actions.handleSetting({
                  ...blockRuleSelected,
                  state: selected,
                  city: [],
                }),
              );
            }}
            allowMultiple={!blockRuleSelected.id}
            requiredIndicator
            error={
              blockRuleSelected.criteria === CriteriaType.Province && blockRuleSelected.city?.length === 0
                ? useError.errorSelected?.error
                : undefined
            }
            onBlur={() => {
              if (blockRuleSelected.criteria === CriteriaType.Province && blockRuleSelected.state?.length === 0) {
                useError.handleErrorRule(t('common:province_must_be_added'));
              }
            }}
          />

          <AutoCompleteWithTag
            helpText={
              <RegularText>
                {`${t('common:blocking_cities_accuracy_issues')} `}
                <Link removeUnderline onClick={openCrisp}>
                  {t('common:contact_us')}.
                </Link>
              </RegularText>
            }
            options={handleCountry.listCities.value as [{ label: string; value: string }]}
            handleSearch={debounceHandleSearch}
            label={t('common:select_city_optional')}
            selectedOptions={blockRuleSelected.city || []}
            setSelectedOptions={(selected: string[]) => {
              if (blockRuleSelected.criteria === CriteriaType.Province && useError.errorSelected?.error) {
                useError.removeErrorRule(CriteriaType.Province);
              }
              dispatch(
                blockListSlice.actions.handleSetting({
                  ...blockRuleSelected,
                  city: selected,
                }),
              );
            }}
            allowMultiple={!blockRuleSelected.id}
            disable={
              (blockRuleSelected.state && blockRuleSelected.state.length > 1) ||
              handleCountry.listCities.isFetching ||
              (handleCountry.listCities.value || '').length === 0 ||
              (blockRuleSelected.country && blockRuleSelected.country.length === 0)
            }
            error={
              blockRuleSelected.criteria === CriteriaType.Province && blockRuleSelected.city && blockRuleSelected.city?.length > 0
                ? useError.errorSelected?.error
                : undefined
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default memo(Location);

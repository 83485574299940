import { uniq } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { InlineStack, TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useErrorRule from '../../hooks/useErrorRule';
import blockCheckoutAccessSlice, { settingSelector } from '@/redux/slice/blockCheckoutAccess';
import { useTranslation } from 'react-i18next';
interface Props {
  label: string;
  error?: string;
  placeholder?: string;
}

const IpAddress = ({ label, placeholder }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { data }] = apiCaller.useUpsertRuleMutation();

  const useError = useErrorRule();
  const dispatch = useDispatch();
  const blockRuleSelected = useSelector(settingSelector);
  const { t } = useTranslation(['common']);
  const handleChange = (ipAddress: string) => {
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }
    dispatch(
      blockCheckoutAccessSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipAddress,
      }),
    );
  };

  const onRemove = (value: string) => {
    if (blockRuleSelected.ipAddress) {
      useError.removeErrorRule();
    } else {
      useError.handleErrorRule(t('common:ip_address_required'));
    }
    const listIp = blockRuleSelected.ipAddress ? [...blockRuleSelected.ipAddress?.split(',')] : [];
    const newListip = listIp.filter((ip) => {
      return ip.trim() !== value;
    });
    dispatch(
      blockCheckoutAccessSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipAddress: newListip.join(','),
      }),
    );
  };
  return (
    <div className="block-ip-address">
      <TextField
        requiredIndicator
        autoComplete="off"
        label={label}
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.id && blockRuleSelected.ipAddress?.includes(',')) {
            useError.handleErrorRule(t('common:cant_edit_multiple_ip_addresses'));
          }
          if (!blockRuleSelected.ipAddress) {
            useError.handleErrorRule(t('common:ip_address_required'));
          }
        }}
        value={blockRuleSelected.ipAddress}
        placeholder={placeholder}
        onChange={handleChange}
        helpText={t('common:enter_multiple_ips')}
      />
      <InlineStack gap={'100'} wrap={true}>
        {uniq(blockRuleSelected.ipAddress?.split(',').map((item) => item.trim()) || [])
          .filter((item) => item !== '')
          .splice(0, 500)
          .map((ip) => {
            return (
              <div
                className={
                  !!data?.failureRules.find((item) => item.errRule.ipAddress === ip)
                    ? 'mt-8 mr-8 Polaris-Tag background-red'
                    : 'mt-8 mr-8 Polaris-Tag'
                }
                key={ip}
              >
                <h1 title={ip} className={'Polaris-Tag__TagText'}>
                  {ip}
                </h1>
                <button className="Polaris-Tag__Button" onClick={() => onRemove(ip)}>
                  <div>X</div>
                </button>
              </div>
            );
          })}
      </InlineStack>
    </div>
  );
};

export default memo(IpAddress);
